import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../FieldCheckbox.module.css';

const IconNutrition = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (

    <svg className={classes} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.10472 5.14746C9.92181 5.14746 10.3083 6.7013 10.7109 8.31743C11.1013 9.88411 13.3371 10.3265 13.3371 12.9507C13.3371 15.5749 10.7296 16.3839 8.10472 16.3839C5.47988 16.3839 2.87109 15.5755 2.87109 12.9507C2.87109 10.3259 5.10683 9.88411 5.49721 8.31743C5.90044 6.7013 6.28762 5.14746 8.10472 5.14746Z" fill="#DFFF60"/>
      <path d="M10.7108 8.31743C10.3082 6.7013 9.92165 5.14746 8.10455 5.14746C6.28745 5.14746 5.90028 6.7013 5.49833 8.31743C5.20619 9.48987 3.88157 10.0324 3.23242 11.3314C4.03182 12.7883 6.05759 13.2833 8.10455 13.2833C10.1515 13.2833 12.1766 12.7883 12.9767 11.3314C12.3275 10.0331 11.0029 9.48987 10.7108 8.31743Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M8.10472 5.14746C9.92181 5.14746 10.3083 6.7013 10.7109 8.31743C11.1013 9.88411 13.3371 10.3265 13.3371 12.9507C13.3371 15.5749 10.7296 16.3839 8.10472 16.3839C5.47988 16.3839 2.87109 15.5755 2.87109 12.9507C2.87109 10.3259 5.10683 9.88411 5.49721 8.31743C5.90044 6.7013 6.28762 5.14746 8.10472 5.14746Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.10447 5.1472V4.82616C8.10447 4.48558 7.96917 4.15894 7.72834 3.91812C7.48751 3.67729 7.16088 3.54199 6.8203 3.54199H5.53613" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.0886 4.45321C10.7095 4.3657 11.2851 4.07859 11.7285 3.6352C12.1719 3.19181 12.459 2.61622 12.5465 1.99531C12.554 1.9443 12.5495 1.89222 12.5334 1.84325C12.5172 1.79428 12.4898 1.74976 12.4534 1.71326C12.4169 1.67677 12.3725 1.6493 12.3235 1.63305C12.2746 1.6168 12.2225 1.61223 12.1715 1.61969C11.5504 1.7071 10.9746 1.99425 10.531 2.43778C10.0875 2.8813 9.80036 3.45711 9.71296 4.07823C9.70549 4.12926 9.71006 4.18133 9.72631 4.23027C9.74256 4.27922 9.77003 4.32369 9.80652 4.36012C9.84302 4.39656 9.88754 4.42395 9.93651 4.44011C9.98548 4.45628 10.0376 4.46076 10.0886 4.45321Z" fill="#DFFF60" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


  );
};

const { string } = PropTypes;

IconNutrition.defaultProps = {
  className: null,
  rootClassName: null,
};

IconNutrition.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconNutrition;
