import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../FieldCheckbox.module.css';

const IconEmpowerment = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2746_11940)">
        <path d="M2.02026 11.248C2.02026 12.2698 2.42615 13.2497 3.14863 13.9721C3.87112 14.6946 4.85102 15.1005 5.87276 15.1005C6.89451 15.1005 7.87441 14.6946 8.59689 13.9721C9.31938 13.2497 9.72526 12.2698 9.72526 11.248C9.72526 10.2263 9.31938 9.24636 8.59689 8.52388C7.87441 7.8014 6.89451 7.39551 5.87276 7.39551C4.85102 7.39551 3.87112 7.8014 3.14863 8.52388C2.42615 9.24636 2.02026 10.2263 2.02026 11.248Z" fill="#F7FFD6"/>
        <path d="M6.51489 6.75289C6.51489 7.25881 6.61454 7.75977 6.80815 8.22718C7.00175 8.69459 7.28553 9.11928 7.64326 9.47702C8.001 9.83476 8.4257 10.1185 8.8931 10.3121C9.36051 10.5057 9.86147 10.6054 10.3674 10.6054C10.8733 10.6054 11.3743 10.5057 11.8417 10.3121C12.3091 10.1185 12.7338 9.83476 13.0915 9.47702C13.4493 9.11928 13.733 8.69459 13.9266 8.22718C14.1202 7.75977 14.2199 7.25881 14.2199 6.75289C14.2199 6.24697 14.1202 5.74601 13.9266 5.2786C13.733 4.8112 13.4493 4.3865 13.0915 4.02876C12.7338 3.67102 12.3091 3.38725 11.8417 3.19364C11.3743 3.00004 10.8733 2.90039 10.3674 2.90039C9.86147 2.90039 9.36051 3.00004 8.8931 3.19364C8.4257 3.38725 8.001 3.67102 7.64326 4.02876C7.28553 4.3865 7.00175 4.8112 6.80815 5.2786C6.61454 5.74601 6.51489 6.24697 6.51489 6.75289Z" fill="#F7FFD6"/>
        <path fill='#DFFF60' d="M10.1201 14.1367C9.46741 15.0954 8.50951 15.805 7.40225 16.1501C6.29499 16.4952 5.10354 16.4554 4.02178 16.0372C2.94002 15.619 2.03162 14.847 1.4444 13.8468C0.857178 12.8467 0.625698 11.6773 0.787625 10.5289C0.949553 9.38045 1.49536 8.32062 2.33622 7.52185C3.17709 6.72308 4.26353 6.23238 5.41875 6.12959C6.57396 6.02681 7.72997 6.31799 8.69865 6.95576C9.66733 7.59353 10.3917 8.54034 10.7538 9.64213" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill='#DFFF60' d="M6.12 3.86348C6.77239 2.90457 7.73009 2.19466 8.83725 1.84928C9.94442 1.50389 11.1359 1.54335 12.2178 1.96124C13.2997 2.37912 14.2083 3.15084 14.7958 4.15081C15.3833 5.15079 15.6151 6.32015 15.4535 7.46863C15.2919 8.61711 14.7464 9.67709 13.9057 10.4761C13.0651 11.2751 11.9788 11.7661 10.8236 11.8692C9.66839 11.9723 8.5123 11.6814 7.54344 11.0439C6.57458 10.4064 5.84998 9.45977 5.48755 8.35806" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2746_11940">
          <rect width="16.08" height="16.08" fill="white" transform="translate(0.0800781 0.959961)"/>
        </clipPath>
      </defs>
    </svg>



  );
};

const { string } = PropTypes;

IconEmpowerment.defaultProps = {
  className: null,
  rootClassName: null,
};

IconEmpowerment.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEmpowerment;
