import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCategories.module.css';

const IconCategories = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (type === 'empowerment') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#DFFF60"
          d="M3.545 8.683a4.495 4.495 0 108.99 0 4.495 4.495 0 00-8.99 0z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M8.04 6.454a4.495 4.495 0 014.344 3.358A4.496 4.496 0 004.477 5.92a4.495 4.495 0 00-.78 3.893A4.495 4.495 0 018.04 6.454z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M5.472 11.89L8.04 9.32l2.568 2.568M8.04 9.321V15.1M8.04 2.9V1.616M12.126 4.593l.908-.908M3.954 4.593l-.908-.908M12.126 12.765l.908.908M3.954 12.765l-.908.908M2.261 8.679H.977M15.103 8.679h-1.284M.977 16.384h14.126M11.915 10.949a4.495 4.495 0 10-7.75 0"
        ></path>
      </svg>
    )
  }
  else if (type === 'emotional-support') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <g clipPath="url(#clip0_334_23767)">
          <path
            fill="#DFFF60"
            d="M8 15.346a.311.311 0 01-.183-.06c-.726-.521-3.417-2.537-4.638-4.651-1.439-3.01-.145-4.864 1.984-5.139A2.781 2.781 0 018 7.43a2.781 2.781 0 012.837-1.934c2.13.275 3.423 2.13 1.983 5.139-1.22 2.114-3.911 4.13-4.637 4.65a.311.311 0 01-.183.06z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M10.837 5.496A2.781 2.781 0 008 7.43a2.781 2.781 0 00-2.837-1.934c-1.894.245-3.121 1.74-2.358 4.185A9.016 9.016 0 008 11.283a9.016 9.016 0 005.195-1.602c.763-2.444-.464-3.94-2.358-4.185z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.67"
            d="M10.837 5.496A2.781 2.781 0 008 7.43a2.781 2.781 0 00-2.837-1.934"
          ></path>
          <path
            fill="#DFFF60"
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.67"
            d="M2.251.654h3.194s.32 0 .32.32v.639s0 .319-.32.319H2.251s-.32 0-.32-.32V.975s0-.32.32-.32zM13.749 1.932h-3.194s-.32 0-.32-.32V.975s0-.32.32-.32h3.194s.32 0 .32.32v.639s0 .319-.32.319z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M3.529 4.647l-.864 1.437a.894.894 0 001.566.86l.73-1.094a2.87 2.87 0 00.484-1.596V1.932H2.826L1.284 3.859a2.874 2.874 0 00-.63 1.796v3.303a.958.958 0 001.917 0V6.483"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.67"
            d="M3.529 4.647l-.864 1.437a.894.894 0 001.566.86l.73-1.094a2.87 2.87 0 00.484-1.596V1.932H2.826L1.284 3.859a2.874 2.874 0 00-.63 1.796v3.303a.958.958 0 001.917 0V6.483"
          ></path>
          <path
            fill="#F7FFD6"
            d="M12.471 4.647l.864 1.437a.894.894 0 01-1.567.86l-.73-1.094a2.87 2.87 0 01-.483-1.596V1.932h2.619l1.542 1.927c.408.51.63 1.143.63 1.796v3.303a.958.958 0 01-1.917 0V6.483"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.67"
            d="M12.471 4.647l.864 1.437a.894.894 0 01-1.567.86l-.73-1.094a2.87 2.87 0 01-.483-1.596V1.932h2.619l1.542 1.927c.408.51.63 1.143.63 1.796v3.303a.958.958 0 01-1.917 0V6.483M12.82 10.635c-1.22 2.114-3.911 4.13-4.637 4.65a.31.31 0 01-.366 0c-.726-.52-3.417-2.536-4.638-4.65"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_334_23767">
            <path fill="#fff" d="M0 0H16V16H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }
  else if (type === 'biohacking') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#DFFF60"
          d="M.644 8.032a6.42 6.42 0 1012.841 0 6.42 6.42 0 00-12.841 0z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M7.065 1.612a6.42 6.42 0 00-4.5 10.997l9.076-9.077a6.397 6.397 0 00-4.576-1.92z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M.644 8.032a6.42 6.42 0 1012.841 0 6.42 6.42 0 00-12.841 0zM11.612 12.565l3.824 3.824"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M5.306 6.332c-.695.82-1.062 1.894.595 2.617.758 1.693 1.912 1.067 2.679.162.693-.817 1-2.869.555-4.2a.321.321 0 00-.482-.165c-1.184.787-2.55.647-3.347 1.586z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M4.842 10.93a11.648 11.648 0 013.032-4.507"
        ></path>
      </svg>
    )
  }
  else if (type === 'stress-management') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M13.498 11.95l-2.77.878c.121-.38-.025-.919-.44-.919H8.04c-.321 0-1.379-1.284-2.89-1.284H3.225v3.572c6.742 2.247 4.495 2.247 12.2-1.605a1.77 1.77 0 00-1.926-.642z"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M.977 9.983h1.926s.321 0 .321.321V14.8s0 .32-.32.32H.976s-.321 0-.321-.32v-4.495s0-.321.321-.321z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M3.224 14.197c6.742 2.247 4.495 2.247 12.2-1.605a1.77 1.77 0 00-1.926-.642l-2.771.878"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M3.224 10.625h1.927c1.51 0 2.568 1.284 2.889 1.284h2.247c.642 0 .642 1.284 0 1.284H6.756"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M14.161 4.86a1.46 1.46 0 00-2.123-.813 1.462 1.462 0 10-2.859 0 1.461 1.461 0 10-.882 2.718 1.461 1.461 0 102.311 1.679 1.463 1.463 0 002.622.106 1.46 1.46 0 00-.31-1.785 1.462 1.462 0 001.241-1.906z"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M9.471 6.014a1.137 1.137 0 102.274 0 1.137 1.137 0 00-2.274 0z"
        ></path>
      </svg>
    )
  }
  else if (type === 'mindfulness') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M1.62 12.847c0 .81.642 1.588 1.786 2.16 1.144.574 2.695.895 4.313.895 1.618 0 3.17-.321 4.313-.894 1.144-.573 1.787-1.35 1.787-2.16s-.643-1.588-1.787-2.16c-1.144-.574-2.695-.896-4.313-.896-1.618 0-3.17.322-4.313.895-1.144.573-1.787 1.35-1.787 2.16z"
        ></path>
        <path
          fill="#DFFF60"
          d="M4.24 10.338c.84 1.304 3.167 2.243 5.908 2.243 1.173.01 2.339-.177 3.45-.553-.716-1.29-3.076-2.236-5.878-2.236a10.727 10.727 0 00-3.48.546z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M4.188 8.678c0 .724.54 1.418 1.504 1.93.963.512 2.27.8 3.632.8 1.363 0 2.669-.288 3.632-.8.964-.512 1.505-1.206 1.505-1.93s-.541-1.419-1.505-1.93c-.963-.513-2.27-.8-3.632-.8s-2.669.287-3.632.8c-.963.511-1.505 1.206-1.505 1.93z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702z"
        ></path>
        <path
          fill="#DFFF60"
          d="M10.073 7.903a7.63 7.63 0 003.468-.782 7.184 7.184 0 00-4.217-1.173 8.09 8.09 0 00-3.457.711 7.058 7.058 0 004.206 1.244z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702z"
        ></path>
        <path
          fill="#fff"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702z"
        ></path>
        <path
          fill="#fff"
          d="M5.472 4.505c0 .137.018.272.054.404a5.367 5.367 0 002.514.583c2.127 0 3.853-1.077 3.853-2.408 0-.136-.019-.272-.054-.403a5.358 5.358 0 00-2.515-.583c-2.127 0-3.852 1.078-3.852 2.407z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M13.218 6.9a2.215 2.215 0 011.243 1.779c0 1.508-2.3 2.729-5.137 2.729-2.837 0-5.136-1.22-5.136-2.729 0-1.01 1.032-1.892 2.568-2.364"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702zM13.542 11.941c.177.272.273.588.277.911 0 1.685-2.731 3.05-6.1 3.05-3.37 0-6.1-1.365-6.1-3.05 0-1.185 1.352-2.213 3.329-2.717"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702z"
        ></path>
      </svg>
    )
  }
  else if (type === 'fitness') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M8.04 15.377l-6.292-6.48a3.64 3.64 0 01-.699-4.242A3.75 3.75 0 017.013 3.7l1.027.937L9.067 3.7a3.75 3.75 0 015.963.955 3.64 3.64 0 01-.697 4.242l-6.293 6.48z"
        ></path>
        <path
          fill="#DFFF60"
          d="M10.817 8.402a.321.321 0 00-.293.192L9.61 10.65a.322.322 0 01-.584.005l-1.708-3.66a.321.321 0 00-.558-.042l-.872 1.307a.321.321 0 01-.267.143H1.326c.125.177.266.343.42.495l6.294 6.48 6.292-6.48c.155-.152.296-.318.42-.495h-3.935z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M8.04 15.377l-6.292-6.48a3.64 3.64 0 01-.699-4.242A3.75 3.75 0 017.013 3.7l1.027.937L9.067 3.7a3.75 3.75 0 015.963.955 3.64 3.64 0 01-.697 4.242l-6.293 6.48z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M1.326 8.402h4.295a.321.321 0 00.267-.143L6.76 6.95a.321.321 0 01.558.043l1.708 3.66a.321.321 0 00.585-.005l.913-2.055a.321.321 0 01.293-.193h3.937"
        ></path>
      </svg>
    )
  }
  else if (type === 'relationship-support') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M1.94 11.248a3.852 3.852 0 1 0 7.705 0 3.852 3.852 0 0 0-7.705 0"
        ></path>
        <path
          fill="#F7FFD6"
          d="M6.435 6.753a3.852 3.852 0 1 0 7.705 0 3.852 3.852 0 0 0-7.705 0"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M10.04 14.137a5.136 5.136 0 1 1 .634-4.495"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M6.04 3.863a5.137 5.137 0 1 1-.633 4.495"
        ></path>
      </svg>
    )
  }
  else if (type === 'nutrition') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#DFFF60"
          d="M7.944 5.147c1.817 0 2.204 1.554 2.606 3.17.39 1.567 2.627 2.01 2.627 4.634s-2.608 3.433-5.233 3.433-5.233-.808-5.233-3.433 2.235-3.067 2.626-4.634c.403-1.616.79-3.17 2.607-3.17"
        ></path>
        <path
          fill="#F7FFD6"
          d="M10.55 8.317c-.402-1.616-.789-3.17-2.606-3.17S5.74 6.701 5.338 8.317c-.292 1.173-1.617 1.716-2.266 3.014.8 1.457 2.825 1.952 4.872 1.952s4.073-.495 4.873-1.952c-.65-1.298-1.974-1.841-2.266-3.014"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M7.944 5.147c1.817 0 2.204 1.554 2.606 3.17.39 1.567 2.627 2.01 2.627 4.634s-2.608 3.433-5.233 3.433-5.233-.808-5.233-3.433 2.235-3.067 2.626-4.634c.403-1.616.79-3.17 2.607-3.17"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M7.944 5.148v-.322A1.284 1.284 0 0 0 6.66 3.542H5.376"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M9.928 4.453a2.89 2.89 0 0 0 2.458-2.458.33.33 0 0 0-.375-.376 2.89 2.89 0 0 0-2.458 2.459.33.33 0 0 0 .375.375"
        ></path>
      </svg>
    )
  }
  else if (type === 'energy-healing') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M5.746 1.77a.32.32 0 0 0-.549 0C4.177 3.44.656 9.342.656 11.601a4.816 4.816 0 1 0 9.631 0c0-2.26-3.52-8.161-4.541-9.833"
        ></path>
        <path
          fill="#DFFF60"
          d="M5.472 14.471a4.81 4.81 0 0 1-4.694-3.724q-.108.42-.122.855a4.816 4.816 0 1 0 9.631 0 4 4 0 0 0-.122-.856 4.81 4.81 0 0 1-4.693 3.725"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M5.472 14.471a2.9 2.9 0 0 1-2.04-.835"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M9.365 8.486a63 63 0 0 0-3.62-6.716.32.32 0 0 0-.548 0C4.177 3.442.656 9.343.656 11.602a4.823 4.823 0 0 0 7.827 3.732"
        ></path>
        <path
          fill="#DFFF60"
          d="M8.361 12.229a2.908 2.908 0 1 0 5.816 0 2.908 2.908 0 0 0-5.816 0"
        ></path>
        <path
          fill="#F7FFD6"
          d="M11.27 9.321a2.906 2.906 0 0 0-2.075 4.944l4.11-4.11a2.9 2.9 0 0 0-2.036-.834"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M8.361 12.229a2.908 2.908 0 1 0 5.816 0 2.908 2.908 0 0 0-5.816 0M15.424 16.384l-2.098-2.098"
        ></path>
      </svg>
    )
  }
  else if (type === 'personal-development') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M14.942 3.19a.321.321 0 0 1 .454.321c-.101 1.232-.567 4.062-2.704 4.062a1.891 1.891 0 0 1 0-3.782 5.9 5.9 0 0 0 2.25-.6M6.266 9.15a.32.32 0 0 0-.205.513c.642.819 2.112 2.376 3.5 1.487A1.513 1.513 0 1 0 7.936 8.6a4.5 4.5 0 0 1-1.67.55"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M8.997 1.616C8.828 6.077 5.158 9.321.656 9.321"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="m7.719 2.9 1.284-1.284L10.287 2.9"
        ></path>
        <path
          fill="#DFFF60"
          d="M15.26 3.221a.3.3 0 0 0-.128-.047c-.257 1.34-.906 3.04-2.564 3.04a1.89 1.89 0 0 1-1.687-1.052 1.9 1.9 0 0 0-.08.52 1.89 1.89 0 0 0 1.89 1.89c2.138 0 2.603-2.83 2.704-4.061a.32.32 0 0 0-.135-.29"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M13.483 5.259a3.89 3.89 0 0 0-2.27 2.365 2.2 2.2 0 0 0-.123.734v8.026M.656 16.384h14.768M.817 14.779s1.284 0 1.284 1.605c0 0 0-1.605 1.284-1.605M6.595 15.1a1.43 1.43 0 0 1 1.284-.963"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M6.274 12.187a.963.963 0 1 1-1.362 1.363 6 6 0 0 1-.733-1.692.321.321 0 0 1 .403-.403 6 6 0 0 1 1.692.732"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M6.242 13.583c.231.356.354.771.353 1.196v1.605"
        ></path>
        <path
          fill="#DFFF60"
          d="M10.025 9.064a1.5 1.5 0 0 0-.402-.42A1.5 1.5 0 0 1 8.93 9.96c-1.017.648-2.076-.01-2.825-.732a.31.31 0 0 0-.045.439c.642.819 2.112 2.376 3.5 1.487a1.513 1.513 0 0 0 .466-2.089"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M14.942 3.19a.321.321 0 0 1 .454.321c-.101 1.232-.567 4.062-2.704 4.062a1.891 1.891 0 0 1 0-3.782 5.9 5.9 0 0 0 2.25-.6M6.266 9.15a.32.32 0 0 0-.205.513c.642.819 2.112 2.376 3.5 1.487A1.513 1.513 0 1 0 7.936 8.6a4.5 4.5 0 0 1-1.67.55"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M8.143 9.864a3.11 3.11 0 0 1 2.549.62q.235.188.398.442"
        ></path>
      </svg>
    )
  }
  else if (type === 'personal-development') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M14.942 3.19a.321.321 0 0 1 .454.321c-.101 1.232-.567 4.062-2.704 4.062a1.891 1.891 0 0 1 0-3.782 5.9 5.9 0 0 0 2.25-.6M6.266 9.15a.32.32 0 0 0-.205.513c.642.819 2.112 2.376 3.5 1.487A1.513 1.513 0 1 0 7.936 8.6a4.5 4.5 0 0 1-1.67.55"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M8.997 1.616C8.828 6.077 5.158 9.321.656 9.321"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="m7.719 2.9 1.284-1.284L10.287 2.9"
        ></path>
        <path
          fill="#DFFF60"
          d="M15.26 3.221a.3.3 0 0 0-.128-.047c-.257 1.34-.906 3.04-2.564 3.04a1.89 1.89 0 0 1-1.687-1.052 1.9 1.9 0 0 0-.08.52 1.89 1.89 0 0 0 1.89 1.89c2.138 0 2.603-2.83 2.704-4.061a.32.32 0 0 0-.135-.29"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M13.483 5.259a3.89 3.89 0 0 0-2.27 2.365 2.2 2.2 0 0 0-.123.734v8.026M.656 16.384h14.768M.817 14.779s1.284 0 1.284 1.605c0 0 0-1.605 1.284-1.605M6.595 15.1a1.43 1.43 0 0 1 1.284-.963"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M6.274 12.187a.963.963 0 1 1-1.362 1.363 6 6 0 0 1-.733-1.692.321.321 0 0 1 .403-.403 6 6 0 0 1 1.692.732"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M6.242 13.583c.231.356.354.771.353 1.196v1.605"
        ></path>
        <path
          fill="#DFFF60"
          d="M10.025 9.064a1.5 1.5 0 0 0-.402-.42A1.5 1.5 0 0 1 8.93 9.96c-1.017.648-2.076-.01-2.825-.732a.31.31 0 0 0-.045.439c.642.819 2.112 2.376 3.5 1.487a1.513 1.513 0 0 0 .466-2.089"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M14.942 3.19a.321.321 0 0 1 .454.321c-.101 1.232-.567 4.062-2.704 4.062a1.891 1.891 0 0 1 0-3.782 5.9 5.9 0 0 0 2.25-.6M6.266 9.15a.32.32 0 0 0-.205.513c.642.819 2.112 2.376 3.5 1.487A1.513 1.513 0 1 0 7.936 8.6a4.5 4.5 0 0 1-1.67.55"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M8.143 9.864a3.11 3.11 0 0 1 2.549.62q.235.188.398.442"
        ></path>
      </svg>
    )
  }
  else if (type === 'relaxation-methods') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M12.92 10.04c1.534.585 2.504 1.446 2.504 2.409 0 .845-.751 1.611-1.975 2.183a.32.32 0 0 1-.25.01l-2.504-.954a.322.322 0 0 0-.406.432l.412.912a.322.322 0 0 1-.245.45q-1.202.177-2.416.177c-4.077 0-7.384-1.438-7.384-3.21 0-.963.97-1.824 2.504-2.408z"
        ></path>
        <path
          fill="#DFFF60"
          d="M14.41 10.825a6.3 6.3 0 0 0-1.49-.784H3.16a6.3 6.3 0 0 0-1.49.784c.947 1.343 3.438 2.304 6.37 2.304s5.423-.961 6.37-2.304"
        ></path>
        <path
          fill="#DFFF60"
          d="M14.239 6.077a6.4 6.4 0 0 0-2.149-.06 9.5 9.5 0 0 0-.277-2.448.322.322 0 0 0-.427-.223 7 7 0 0 0-1.862 1.076l-.008.037A8.4 8.4 0 0 0 8.29 2.458a.32.32 0 0 0-.495 0 8.4 8.4 0 0 0-1.22 1.978 7 7 0 0 0-1.882-1.091.32.32 0 0 0-.427.222c-.198.8-.291 1.624-.278 2.448a6.4 6.4 0 0 0-2.148.064.32.32 0 0 0-.253.373c.145.793.658 2.962 2.106 4.089 1.798 1.393 4.347.654 4.347.654s2.55.739 4.346-.654c1.448-1.124 1.961-3.293 2.106-4.088a.32.32 0 0 0-.253-.376"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M10 6.683c0 2.49-1.96 4.508-1.96 4.508S6.08 9.173 6.08 6.683a7.1 7.1 0 0 1 1.712-4.225.32.32 0 0 1 .495 0A7.1 7.1 0 0 1 10 6.683"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M9.521 4.422a7 7 0 0 1 1.862-1.076.32.32 0 0 1 .427.223c.203.817.603 2.96-.201 4.699-1.014 2.2-3.569 2.923-3.569 2.923"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M12.085 6.017a6.3 6.3 0 0 1 2.151.06.32.32 0 0 1 .253.373c-.144.795-.657 2.963-2.105 4.088-1.798 1.393-4.347.653-4.347.653"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M6.576 4.436a6.5 6.5 0 0 0-1.882-1.091.32.32 0 0 0-.427.222c-.203.818-.603 2.96.201 4.7 1.017 2.2 3.572 2.924 3.572 2.924"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M3.99 6.016a6.2 6.2 0 0 0-2.15.064.32.32 0 0 0-.252.373c.144.792.657 2.96 2.105 4.088 1.795 1.39 4.347.65 4.347.65q.002 0-.006-.049"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M12.92 10.04c1.534.585 2.504 1.446 2.504 2.409 0 .845-.751 1.611-1.975 2.183a.32.32 0 0 1-.25.01l-2.504-.954a.322.322 0 0 0-.406.432l.412.912a.322.322 0 0 1-.245.45q-1.202.177-2.416.177c-4.077 0-7.384-1.438-7.384-3.21 0-.963.97-1.824 2.504-2.408"
        ></path>
      </svg>
    )
  }
  else if (type === 'transition-support') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <g clipPath="url(#clip0_334_23796)">
          <path
            fill="#DFFF60"
            d="M8.04.746a.32.32 0 0 1 .256.127 9.33 9.33 0 0 1 1.98 5.117 5.55 5.55 0 0 1 4.513-1.62.32.32 0 0 1 .301.309c.05 1.085-.06 5.01-4.173 6.107l1.582.903a.32.32 0 0 1-.093.59 6.13 6.13 0 0 1-4.364-.702 6.13 6.13 0 0 1-4.363.703.32.32 0 0 1-.093-.59l1.582-.904C1.052 9.686.942 5.764.993 4.679a.32.32 0 0 1 .3-.306 5.55 5.55 0 0 1 4.512 1.62A9.34 9.34 0 0 1 7.785.875a.32.32 0 0 1 .255-.13"
          ></path>
          <path
            fill="#F7FFD6"
            d="M15.09 4.679a.32.32 0 0 0-.299-.304 5.55 5.55 0 0 0-4.513 1.62A9.33 9.33 0 0 0 8.298.879a.32.32 0 0 0-.51 0 9.34 9.34 0 0 0-1.98 5.116 5.55 5.55 0 0 0-4.515-1.622.32.32 0 0 0-.3.305c-.043.915.038 3.827 2.506 5.385a5.04 5.04 0 0 1-.521-2.491.23.23 0 0 1 .215-.218 3.99 3.99 0 0 1 3.242 1.163c.095-1.34.59-2.62 1.42-3.675a.23.23 0 0 1 .37 0 6.7 6.7 0 0 1 1.422 3.675 3.98 3.98 0 0 1 3.241-1.163.23.23 0 0 1 .216.218 5.06 5.06 0 0 1-.522 2.491c2.468-1.56 2.55-4.473 2.508-5.385"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.67"
            d="M14.65 7.508a7.3 7.3 0 0 0 .44-2.83.32.32 0 0 0-.3-.303 5.55 5.55 0 0 0-4.512 1.62A9.33 9.33 0 0 0 8.298.879a.32.32 0 0 0-.511 0 9.34 9.34 0 0 0-1.98 5.116 5.55 5.55 0 0 0-4.514-1.622.32.32 0 0 0-.3.305c-.051 1.086.06 5.01 4.173 6.108l-1.582.9a.32.32 0 0 0 .093.591 6.13 6.13 0 0 0 4.363-.703M8.04 15.254v-3.678"
          ></path>
          <path
            fill="#F7FFD6"
            d="M14.557 8.968a.32.32 0 0 1 .457.31c-.102 1.442-.63 5.19-3.373 5.19a2.35 2.35 0 0 1-1.688-4.028 2.35 2.35 0 0 1 1.688-.674 7.45 7.45 0 0 0 2.916-.798"
          ></path>
          <path
            fill="#DFFF60"
            d="M14.875 8.992a.3.3 0 0 0-.137-.049c-.274 1.7-1.049 4.105-3.247 4.105a2.35 2.35 0 0 1-2.15-1.407q-.049.238-.049.48a2.35 2.35 0 0 0 2.349 2.349c2.743 0 3.271-3.748 3.373-5.191a.32.32 0 0 0-.14-.287"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.67"
            d="M14.557 8.968a.32.32 0 0 1 .457.31c-.102 1.442-.63 5.19-3.373 5.19a2.35 2.35 0 0 1-1.688-4.028 2.35 2.35 0 0 1 1.688-.674 7.45 7.45 0 0 0 2.916-.798"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.67"
            d="M9.29 15.254a5.37 5.37 0 0 1 3.135-3.658"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_334_23796">
            <path fill="#fff" d="M0 0h16v16H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }
  else if (type === 'spiritual-growth') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M8.04 12.853v-1.615"
        ></path>
        <path
          fill="#DFFF60"
          d="M15.083 16.384a2.557 2.557 0 0 0-4.51-1.318 2.556 2.556 0 0 0-5.065 0 2.557 2.557 0 0 0-4.51 1.318"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M15.083 16.384a2.557 2.557 0 0 0-4.51-1.318 2.556 2.556 0 0 0-5.065 0 2.557 2.557 0 0 0-4.51 1.318"
        ></path>
        <path
          fill="#F7FFD6"
          d="M13.504 6.104a5.46 5.46 0 0 1-5.458 5.458 5.47 5.47 0 0 1 5.458-5.458"
        ></path>
        <path
          fill="#DFFF60"
          d="M9.485 7.876a5.42 5.42 0 0 0-1.439 3.686A5.43 5.43 0 0 0 6.64 7.908a6.02 6.02 0 0 1 1.413-6.292 6.06 6.06 0 0 1 1.432 6.26"
        ></path>
        <path
          fill="#F7FFD6"
          d="M8.046 11.562A5.46 5.46 0 0 1 2.59 6.104a5.463 5.463 0 0 1 5.457 5.458M8.053 3.863a6 6 0 0 1 1.656 3.17 6.04 6.04 0 0 0-1.656-5.417 6.01 6.01 0 0 0-1.654 5.389 6 6 0 0 1 1.654-3.142"
        ></path>
        <path
          fill="#DFFF60"
          d="M8.326 9.852a5.4 5.4 0 0 0-.28 1.71 5.46 5.46 0 0 0 5.458-5.458c-.094 0-.185.01-.277.015a5.45 5.45 0 0 1-4.9 3.733M2.867 6.119c-.092-.005-.184-.015-.278-.015a5.46 5.46 0 0 0 5.457 5.458 5.5 5.5 0 0 0-.279-1.71 5.46 5.46 0 0 1-4.9-3.733"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M13.504 6.104a5.46 5.46 0 0 1-5.458 5.458 5.47 5.47 0 0 1 5.458-5.458"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M9.485 7.876a5.42 5.42 0 0 0-1.439 3.686A5.43 5.43 0 0 0 6.64 7.908a6.02 6.02 0 0 1 1.413-6.292 6.06 6.06 0 0 1 1.432 6.26"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M8.046 11.562A5.46 5.46 0 0 1 2.59 6.104a5.463 5.463 0 0 1 5.457 5.458"
        ></path>
      </svg>
    )
  }
  else if (type === 'lifestyle') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#DFFF60"
          d="M13.462 7.706c-1.576.102-1.48-.632-2.854-.632-1.284 0-1.284.642-2.568.642s-1.284-.642-2.568-.642-1.284.639-2.558.642a.32.32 0 0 1-.321-.302 5.458 5.458 0 1 1 10.87.29"
        ></path>
        <path
          fill="#F7FFD6"
          d="M8.04 3.957a6.17 6.17 0 0 1 5.45 3.284 5.452 5.452 0 1 0-10.899 0A6.17 6.17 0 0 1 8.04 3.957"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M13.462 7.706c-1.576.102-1.48-.632-2.854-.632-1.284 0-1.284.642-2.568.642s-1.284-.642-2.568-.642-1.284.639-2.558.642a.32.32 0 0 1-.321-.302 5.458 5.458 0 1 1 10.87.29"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M4.369 5.756a5.5 5.5 0 0 1 1.745-2.123M8.06 16.384a5.6 5.6 0 0 0-.421-2.568 5.1 5.1 0 0 1-.562-2.248c.013-.782.205-1.55.562-2.247a5.4 5.4 0 0 0 .407-1.605M5.492 16.384a5.6 5.6 0 0 0-.422-2.568 5.1 5.1 0 0 1-.561-2.248c.013-.782.205-1.55.561-2.247a5.6 5.6 0 0 0 .437-2.247M10.628 16.384a5.6 5.6 0 0 0-.42-2.568 5.1 5.1 0 0 1-.563-2.248c.014-.782.206-1.55.562-2.247a5.6 5.6 0 0 0 .437-2.247"
        ></path>
      </svg>
    )
  }
  else if (type === 'ceremony') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M3.224 6.51H8.36s.642 0 .642.641V15.5s0 .642-.642.642H3.224s-.642 0-.642-.642V7.15s0-.642.642-.642"
        ></path>
        <path
          fill="#fff"
          d="M5.793 6.51H3.224a.64.64 0 0 0-.642.641V15.5a.64.64 0 0 0 .642.642h2.569z"
        ></path>
        <path
          fill="#fff"
          d="M3.866 8.115a.642.642 0 0 0 1.285 0v-.321h1.284v1.284a.642.642 0 1 0 1.284 0V7.794h1.284V7.15a.64.64 0 0 0-.642-.642H3.224a.64.64 0 0 0-.642.642v.65h1.284z"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M13.36 12.448a1.165 1.165 0 0 0-1.765-.138 1.173 1.173 0 0 0-.996-1.57 1.17 1.17 0 0 0-1 1.553 1.158 1.158 0 0 0-1.744.155 1.203 1.203 0 0 0 1.095 1.71 1.15 1.15 0 0 0-.242 1.708 1.194 1.194 0 0 0 1.9-.595 1.195 1.195 0 0 0 1.9.595 1.147 1.147 0 0 0-.242-1.709 1.204 1.204 0 0 0 1.094-1.709"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M9.652 13.593a.955.955 0 1 0 1.911 0 .955.955 0 0 0-1.91 0"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M9.003 10.04V7.15a.64.64 0 0 0-.642-.642H3.224a.64.64 0 0 0-.642.642v8.347a.64.64 0 0 0 .642.642h4.174"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M6.181 1.909a.321.321 0 0 0-.49.36 1.86 1.86 0 0 1-.13 1.466 1.5 1.5 0 0 1-.367-.4.32.32 0 0 0-.45-.116 1.64 1.64 0 0 0-.8 1.442 1.849 1.849 0 0 0 3.697 0A3.41 3.41 0 0 0 6.18 1.91"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M2.582 7.801h1.284v.314a.642.642 0 0 0 1.285 0v-.321h1.284v1.284a.642.642 0 1 0 1.284 0V7.794h1.284"
        ></path>
      </svg>
    )
  }

  else return null
};

IconCategories.defaultProps = { className: null };

IconCategories.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCategories;
