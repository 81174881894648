/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

import { treatmentsData } from "../components/TreatmentCard/treatments";

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - listingTypeConfig (optional):  Relationship configuration against listing types.
 *   - limitToListingTypeIds:         Indicator whether this listing field is relevant to a limited set of listing types.
 *   - listingTypeIds:                An array of listing types, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToListingTypeIds is true.
 * - categoryConfig (optional):     Relationship configuration against categories.
 *   - limitToCategoryIds:            Indicator whether this listing field is relevant to a limited set of categories.
 *   - categoryIds:                   An array of categories, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToCategoryIds is true.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */

const optionsAndLabels = treatmentsData.map(treatment => ({
    option: treatment.option,
    label: treatment.label,
    filterId: treatment.filterId
}));



export const listingFields = [
  // {
  //   "scope": "public",
  //   "label": "Gears",
  //   "key": "gears",
  //   "schemaType": "long",
  //   "numberConfig": {
  //     "minimum": 1,
  //     "maximum": 24
  //   },
  //   "filterConfig": {
  //     "indexForSearch": true,
  //     "group": "primary",
  //     "label": "Gears"
  //   }
  // }
  {
    key: 'clientCategories',
    scope: 'public',
    schemaType: 'multi-enum',
    queryParamNames: ['pub_clientCategories'],
    enumOptions: [
      { option: 'children', label: 'Children', filterId: 'clientCategories' },
      { option: 'teenagers', label: 'Teenagers', filterId: 'clientCategories' },
      { option: 'women', label: 'Women', filterId: 'clientCategories' },
      { option: 'men', label: "Men", filterId: 'clientCategories' },
      { option: 'couples', label: "Couples", filterId: 'clientCategories' },
      { option: 'same-sex-couples', label: "Same-Sex Couples", filterId: 'clientCategories' },
      { option: 'LGBTQ+', label: "LGBTQ+", filterId: 'clientCategories' },
      { option: 'golden-age', label: "Golden Age", filterId: 'clientCategories' },
      { option: 'individuals-with-disabilities ', label: "Individuals with disabilities", filterId: 'clientCategories' },
      { option: 'all-clients', label: "All Clients (No specification)", filterId: 'clientCategories' },
    ],
    categoryConfig: {
      limitToCategoryIds: false,
      categoryIds: ['clientCategories'],
    },
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter', //'SelectSingleFilter',
      label: 'Best suited for',
      group: 'primary',
    },
    showConfig: {
      label: 'Client Categories',
      isDetail: true,
    },
    saveConfig: {
      label: 'Client Categories',
      placeholderMessage: 'Select Client Categories',
      isRequired: true,
      requiredMessage: 'Please select at least one Client Categorie.',
    },
  },
  {
    key: 'sessionDynamics',
    scope: 'public',
    schemaType: 'multi-enum',
    queryParamNames: ['pub_sessionDynamics'],
    enumOptions: [
      { option: 'physical-contact', label: 'Physical Contact', filterId: 'sessionDynamics' },
      { option: 'no-physical-contact', label: 'No Physical Contact', filterId: 'sessionDynamics' },
      { option: 'active-movement', label: 'Active Movement', filterId: 'sessionDynamics' },
      { option: 'breathwork', label: "Breathwork", filterId: 'sessionDynamics' },
      { option: 'in-depth-conversation', label: "In-Depth Conversation", filterId: 'sessionDynamics' },
      { option: 'creative-activity', label: "Creative activity", filterId: 'sessionDynamics' },
      { option: 'brief-intro-conversation-only', label: "Brief Intro Conversation Only", filterId: 'sessionDynamics' },
      { option: 'laying-down', label: "Laying Down", filterId: 'sessionDynamics' },
      { option: 'sitting-down-only', label: "Sitting Down Only", filterId: 'sessionDynamics' },
      { option: 'physical-assessments-included', label: "Physical Assessments included", filterId: 'sessionDynamics' },
      { option: 'natural-remedies-included', label: "Natural Remedies included", filterId: 'sessionDynamics' },
      
    ],
    categoryConfig: {
      limitToCategoryIds: true,
      categoryIds: ['sessionDynamics'],
    },
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter', //'SelectSingleFilter',
      label: 'Session Dynamics',
      group: 'primary',
    },
    showConfig: {
      label: 'Session Dynamics',
      isDetail: true,
    },
    saveConfig: {
      label: 'Session Dynamics',
      placeholderMessage: 'Choose your Session Dynamics',
      isRequired: true,
      requiredMessage: 'Please select at least one Session Dynamic for your offer.',
    },
  },

  {
    key: 'techniqueTypes',
    scope: 'public',
    schemaType: 'multi-enum',
    queryParamNames: ['pub_techniqueTypes'],
    enumOptions: [
      ...optionsAndLabels
      // { option: 'acupressure', label: 'Acupressure' },
      // { option: 'active-meditation', label: 'Active Meditation' },
      // { option: 'akasha-reading', label: 'Akasha reading' },
      // { option: 'alexander-technique', label: "Alexander Technique" },
      // { option: 'apitherapy', label: "Apitherapy" },
      // { option: 'art-therapy', label: "Art Therapy" },
    ],
    categoryConfig: {
      limitToCategoryIds: false,
      categoryIds: ['techniqueTypes'],
    },
    filterConfig: {
      indexForSearch: true,
      // searchMode: 'has_any',
      filterType: 'SelectMultipleWithInputFilter', //'SelectSingleFilter',
      label: 'Holistic technic',
      group: 'primary',
    },
    showConfig: {
      label: 'Techniques',
      isDetail: true,
    },
    saveConfig: {
      label: 'Techniques',
      placeholderMessage: 'Select your techniques',
      isRequired: true,
      requiredMessage: 'Please select at least one technique to list on your practitioner profile.',
    },
  },
  {
    key: 'languages',
    scope: 'public',
    schemaType: 'multi-enum',
    queryParamNames: ['pub_languages'],
    enumOptions: [
      { option: 'english', label: 'English', filterId: 'languages' },
      { option: 'german', label: 'German', filterId: 'languages' },
      { option: 'albanian', label: 'Albanian', filterId: 'languages' },
      { option: 'amharic', label: 'Amharic', filterId: 'languages' },
      { option: 'arabic', label: 'Arabic', filterId: 'languages' },
      { option: 'armenian', label: 'Armenian', filterId: 'languages' },
      { option: 'azerbaijani', label: 'Azerbaijani', filterId: 'languages' },
      { option: 'bosnian', label: 'Bosnian', filterId: 'languages' },
      { option: 'bulgarian', label: 'Bulgarian', filterId: 'languages' },
      { option: 'chinese (cantonese)', label: 'Chinese (Cantonese)', filterId: 'languages' },
      { option: 'chinese (mandarin)', label: 'Chinese (Mandarin)', filterId: 'languages' },
      { option: 'croatian', label: 'Croatian', filterId: 'languages' },
      { option: 'czech', label: 'Czech', filterId: 'languages' },
      { option: 'danish', label: 'Danish', filterId: 'languages' },
      { option: 'dutch', label: 'Dutch', filterId: 'languages' },
      { option: 'estonian', label: 'Estonian', filterId: 'languages' },
      { option: 'finnish', label: 'Finnish', filterId: 'languages' },
      { option: 'french', label: 'French', filterId: 'languages' },
      { option: 'georgian', label: 'Georgian', filterId: 'languages' },
      { option: 'greek', label: 'Greek', filterId: 'languages' },
      { option: 'hebrew', label: 'Hebrew', filterId: 'languages' },
      { option: 'hindi', label: 'Hindi', filterId: 'languages' },
      { option: 'hungarian', label: 'Hungarian', filterId: 'languages' },
      { option: 'italian', label: 'Italian', filterId: 'languages' },
      { option: 'japanese', label: 'Japanese', filterId: 'languages' },
      { option: 'korean', label: 'Korean', filterId: 'languages' },
      { option: 'latvian', label: 'Latvian', filterId: 'languages' },
      { option: 'lithuanian', label: 'Lithuanian', filterId: 'languages' },
      { option: 'macedonian', label: 'Macedonian', filterId: 'languages' },
      { option: 'malay', label: 'Malay', filterId: 'languages' },
      { option: 'maltese', label: 'Maltese', filterId: 'languages' },
      { option: 'norwegian', label: 'Norwegian', filterId: 'languages' },
      { option: 'pashto', label: 'Pashto', filterId: 'languages' },
      { option: 'polish', label: 'Polish', filterId: 'languages' },
      { option: 'portuguese', label: 'Portuguese', filterId: 'languages' },
      { option: 'romanian', label: 'Romanian', filterId: 'languages' },
      { option: 'russian', label: 'Russian', filterId: 'languages' },
      { option: 'serbian', label: 'Serbian', filterId: 'languages' },
      { option: 'slovak', label: 'Slovak', filterId: 'languages' },
      { option: 'slovanian', label: 'Slovanian' , filterId: 'languages'},
      { option: 'spanish', label: 'Spanish', filterId: 'languages' },
      { option: 'swedish', label: 'Swedish', filterId: 'languages' },
      { option: 'swiss german', label: 'Swiss German', filterId: 'languages' },
      { option: 'thai', label: 'Thai', filterId: 'languages' },
      { option: 'turkish', label: 'Turkish', filterId: 'languages' },
      { option: 'ukrainian', label: 'Ukrainian', filterId: 'languages' },
      { option: 'vietnamese', label: 'Vietnamese', filterId: 'languages' },
    ],
    categoryConfig: {
      limitToCategoryIds: false,
      categoryIds: ['languages'],
    },
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter', //'SelectSingleFilter',
      label: 'Language',
      group: 'primary',
    },
    showConfig: {
      label: 'Languages',
      isDetail: true,
    },
    saveConfig: {
      label: 'Languages',
      placeholderMessage: 'Select languages',
      isRequired: true,
      requiredMessage: 'Please select at least one language you offer to complete your practitioner profile.',
    },
  },
  {
    key: 'holisticHealthFocus',
    scope: 'public',
    schemaType: 'multi-enum',
    queryParamNames: ['pub_holisticHealthFocus'],
    enumOptions: [
      { option: 'empowerment', label: 'Empowerment', filterId: 'holisticHealthFocus' },
      { option: 'relationship-support', label: 'Relationship Support', filterId: 'holisticHealthFocus' },
      { option: 'emotional-support', label: 'Emotional Support', filterId: 'holisticHealthFocus' },
      { option: 'nutrition', label: "Nutrition", filterId: 'holisticHealthFocus' },
      { option: 'energy-healing', label: 'Energy Healing', filterId: 'holisticHealthFocus' },
      { option: 'personal-development', label: "Personal Development", filterId: 'holisticHealthFocus' },
      { option: 'relaxation-methods', label: "Relaxation Methods", filterId: 'holisticHealthFocus' },
      { option: 'transition-support', label: "Transition Support", filterId: 'holisticHealthFocus' },
      { option: 'biohacking', label: "Biohacking", filterId: 'holisticHealthFocus' },
      { option: 'stress-management', label: "Stress Management", filterId: 'holisticHealthFocus' },
      { option: 'mindfulness', label: "Mindfulness", filterId: 'holisticHealthFocus' },
      { option: 'fitness', label: "Fitness", filterId: 'holisticHealthFocus' },
      { option: 'spiritual-growth', label: "Spiritual Growth", filterId: 'holisticHealthFocus' },
      { option: 'lifestyle', label: "Lifestyle", filterId: 'holisticHealthFocus' },
      { option: 'ceremony', label: "Ceremony", filterId: 'holisticHealthFocus' },
    ],
    categoryConfig: {
      limitToCategoryIds: false,
      categoryIds: ['holisticHealthFocus'],
    },
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectCustomMultipleFilter', //'SelectSingleFilter',
      label: 'Holistic Health Focus',
      group: 'primary',
    },
    showConfig: {
      label: 'Holistic Health Focus',
      isDetail: true,
    },
    saveConfig: {
      label: 'Holistic Health Focus',
      placeholderMessage: 'Choose your Holistic Health Focus',
      isRequired: true,
      requiredMessage: 'Please select at least one Holistic Health Focus for your offer.',
    },
  },
  // {
  //   key: 'holisticHealthFocus',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   queryParamNames: ['pub_holisticHealthFocus'],
  //   enumOptions: [
  //     { option: 'empowerment', label: 'Empowerment', filterId: 'holisticHealthFocus' },
  //     { option: 'emotional-support', label: 'Emotional Support', filterId: 'holisticHealthFocus' },
  //     { option: 'biohacking', label: 'Biohacking', filterId: 'holisticHealthFocus' },
  //     { option: 'stress-management', label: "Stress Management", filterId: 'holisticHealthFocus' },
  //     { option: 'mindfulness', label: "Mindfulness", filterId: 'holisticHealthFocus' },
  //     { option: 'fitness', label: "Fitness", filterId: 'holisticHealthFocus' },
  //     { option: 'nutrition', label: "Nutrition", filterId: 'holisticHealthFocus' },
  //   ],
  //   categoryConfig: {
  //     limitToCategoryIds: false,
  //     categoryIds: ['holisticHealthFocus'],
  //   },
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectCustomMultipleFilter', //'SelectSingleFilter',
  //     label: 'Holistic Health Focus розтянуте наврху',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Holistic Health Focus',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Holistic Health Focus',
  //     placeholderMessage: 'Choose your Holistic Health Focus',
  //     isRequired: true,
  //     requiredMessage: 'Please select at least one Holistic Health Focus for your offer.',
  //   },
  // },
  {
    key: 'offerLocation',
    scope: 'public',
    schemaType: 'enum',
    queryParamNames: ['pub_offerLocation'],
    enumOptions: [
      { option: 'in-person', label: 'In person', filterId: 'offerLocation' },
      { option: 'online', label: 'Online', filterId: 'offerLocation' },
      { option: 'client-location', label: 'In your location', filterId: 'offerLocation' },
    ],
    categoryConfig: {
      limitToCategoryIds: false,
      categoryIds: ['offerLocation'],
    },
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter', //'SelectSingleFilter',
      label: 'Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Offer Location',
      isDetail: true,
    },
    saveConfig: {
      label: 'Offer Location',
      placeholderMessage: 'Choose your Offers Location',
      isRequired: true,
      requiredMessage: 'Please select Offers Location.',
    },
  },
  {
    key: 'insuranceCoverage',
    scope: 'public',
    schemaType: 'enum',
    queryParamNames: ['pub_insuranceCoverage'],
    enumOptions: [
      { option: 'fully-covered', label: 'Fully covered', filterId: 'insuranceCoverage' },
      { option: 'partially-covered', label: 'Partially covered', filterId: 'insuranceCoverage'  },
      { option: 'not-covered', label: 'Not covered', filterId: 'insuranceCoverage'  },
    ],
    categoryConfig: {
      limitToCategoryIds: true,
      categoryIds: ['insuranceCoverage'],
    },
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter', //'SelectSingleFilter',
      label: 'Insurance Coverage',
      group: 'primary',
    },
    showConfig: {
      label: 'Insurance Coverage',
      isDetail: true,
    },
    saveConfig: {
      label: 'Insurance Coverage',
      placeholderMessage: 'Choose your Insurance Coverage',
      isRequired: true,
      requiredMessage: 'Please select Insurance Coverage.',
    },
  },
  // {
  //   key: 'tire',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '29', label: '29' },
  //     { option: '28', label: '28' },
  //     { option: '27', label: '27' },
  //     { option: '26', label: '26' },
  //     { option: '24', label: '24' },
  //     { option: '20', label: '20' },
  //     { option: '18', label: '18' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Tire size',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Tire size',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Tire size',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a tire size.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },
  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   schemaType: 'text',
  //   listingTypeConfig: {
  //     limitToListingTypeIds: true,
  //     listingTypeIds: ['product-selling'],
  //   },
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   schemaType: 'text',
  //   listingTypeConfig: {
  //     limitToListingTypeIds: true,
  //     listingTypeIds: ['daily-booking'],
  //   },
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  {
    listingType: 'profile-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'hour',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
  {
    listingType: 'treatment-booking',
    label: 'Hourly booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'hour',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  //   defaultListingFields: {
  //     shipping: true,
  //     pickup: true,
  //     payoutDetails: true,
  //   },
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
