import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from '../FieldCheckbox.module.css';

const IconPersonalDev = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2746_11753)">
        <path d="M15.2619 3.19056C15.313 3.16699 15.3695 3.15731 15.4256 3.16249C15.4817 3.16767 15.5355 3.18752 15.5815 3.22006C15.6275 3.25259 15.6641 3.29666 15.6877 3.34784C15.7113 3.39901 15.721 3.45549 15.7158 3.5116C15.615 4.74311 15.1495 7.57342 13.012 7.57342C12.5164 7.56429 12.0443 7.36101 11.697 7.00733C11.3498 6.65364 11.1553 6.1778 11.1553 5.68216C11.1553 5.18652 11.3498 4.71068 11.697 4.35699C12.0443 4.00331 12.5164 3.80004 13.012 3.7909C13.7946 3.7434 14.5597 3.53925 15.2619 3.19056Z" fill="#F7FFD6"/>
        <path d="M6.58637 9.14936C6.53118 9.15791 6.47919 9.1807 6.4355 9.21548C6.39182 9.25027 6.35797 9.29584 6.33729 9.34771C6.3166 9.39958 6.30981 9.45595 6.31757 9.51124C6.32533 9.56654 6.34738 9.61886 6.38154 9.66303C7.02362 10.4817 8.49335 12.0387 9.88218 11.1501C10.0497 11.0433 10.1946 10.9046 10.3085 10.7418C10.4224 10.579 10.5032 10.3954 10.5461 10.2014C10.5891 10.0074 10.5934 9.80687 10.5589 9.61121C10.5243 9.41556 10.4516 9.22862 10.3448 9.06108C10.238 8.89353 10.0993 8.74866 9.93649 8.63474C9.77371 8.52082 9.59009 8.44007 9.3961 8.39712C9.20212 8.35416 9.00158 8.34983 8.80592 8.38437C8.61026 8.41892 8.42333 8.49166 8.25578 8.59846C7.74071 8.88953 7.17352 9.0767 6.58637 9.14936Z" fill="#F7FFD6"/>
        <path d="M9.31787 1.61621C9.149 6.07741 5.47885 9.32121 0.976562 9.32121" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.03906 2.90038L9.32323 1.61621L10.6074 2.90038" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.5805 3.22168C15.5416 3.1969 15.4978 3.18091 15.4521 3.1748C15.1953 4.51355 14.5461 6.21443 12.8883 6.21443C12.537 6.21365 12.193 6.11477 11.895 5.92894C11.597 5.74312 11.3568 5.47773 11.2015 5.16269C11.1503 5.33097 11.1231 5.50562 11.1206 5.6815C11.1206 6.18306 11.3198 6.6641 11.6744 7.01882C12.029 7.37354 12.51 7.57291 13.0115 7.57308C15.149 7.57308 15.6145 4.74277 15.7154 3.51126C15.7201 3.45542 15.7102 3.39931 15.6865 3.34851C15.6629 3.2977 15.6263 3.25398 15.5805 3.22168Z" fill="#DFFF60"/>
        <path d="M13.8032 5.25879C13.2753 5.46387 12.7986 5.78183 12.4064 6.19042C12.0142 6.59901 11.7161 7.08835 11.5328 7.62422C11.4511 7.86026 11.4096 8.10835 11.4102 8.35813V16.3842" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M0.976562 16.3838H15.7445" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.13672 14.7783C1.13672 14.7783 2.42089 14.7783 2.42089 16.3835C2.42089 16.3835 2.42089 14.7783 3.70505 14.7783" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.91553 15.0998C7.00878 14.8293 7.18097 14.593 7.40987 14.4213C7.63878 14.2496 7.91389 14.1505 8.19969 14.1367" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.59459 12.1872C6.68405 12.2766 6.75502 12.3828 6.80346 12.4996C6.85189 12.6165 6.87683 12.7417 6.87686 12.8682C6.87689 12.9947 6.85201 13.1199 6.80363 13.2368C6.75525 13.3537 6.68433 13.4599 6.59491 13.5493C6.50549 13.6388 6.39932 13.7098 6.28247 13.7582C6.16562 13.8066 6.04037 13.8316 5.91388 13.8316C5.78739 13.8317 5.66214 13.8068 5.54526 13.7584C5.42839 13.71 5.32219 13.6391 5.23273 13.5497C4.90259 13.0269 4.65525 12.4562 4.49947 11.8578C4.48163 11.8017 4.47956 11.7418 4.49348 11.6846C4.50741 11.6274 4.53678 11.5751 4.57841 11.5335C4.62003 11.4919 4.67229 11.4625 4.72949 11.4486C4.78668 11.4346 4.8466 11.4367 4.9027 11.4545C5.50093 11.6104 6.07158 11.8575 6.59459 12.1872Z" fill="#DFFF60" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill='#DFFF60' d="M6.5625 13.582C6.79356 13.9382 6.91624 14.3537 6.91565 14.7782V16.3834" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill='#DFFF60' d="M10.3448 9.06445C10.2395 8.89984 10.1032 8.75723 9.94353 8.64453C9.95155 8.90505 9.8918 9.16318 9.77014 9.39368C9.64847 9.62418 9.46906 9.81915 9.24944 9.95952C8.23238 10.6074 7.17294 9.9486 6.42428 9.22754C6.38451 9.26013 6.35341 9.30204 6.33374 9.34954C6.31301 9.40168 6.30631 9.45835 6.31431 9.51389C6.32231 9.56943 6.34473 9.62191 6.37933 9.66609C7.02141 10.4847 8.49114 12.0418 9.87997 11.1532C10.2185 10.9378 10.4577 10.5967 10.5449 10.2051C10.632 9.81337 10.5601 9.4031 10.3448 9.06445Z" />
        <path fill='#DFFF60' d="M15.2619 3.19056C15.313 3.16699 15.3695 3.15731 15.4256 3.16249C15.4817 3.16767 15.5355 3.18752 15.5815 3.22006C15.6275 3.25259 15.6641 3.29666 15.6877 3.34784C15.7113 3.39901 15.721 3.45549 15.7158 3.5116C15.615 4.74311 15.1495 7.57342 13.012 7.57342C12.5164 7.56429 12.0443 7.36101 11.697 7.00733C11.3498 6.65364 11.1553 6.1778 11.1553 5.68216C11.1553 5.18652 11.3498 4.71068 11.697 4.35699C12.0443 4.00331 12.5164 3.80004 13.012 3.7909C13.7946 3.7434 14.5597 3.53925 15.2619 3.19056Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill='#DFFF60' d="M6.58637 9.14936C6.53118 9.15791 6.47919 9.1807 6.4355 9.21548C6.39182 9.25027 6.35797 9.29584 6.33729 9.34771C6.3166 9.39958 6.30981 9.45595 6.31757 9.51124C6.32533 9.56654 6.34738 9.61886 6.38154 9.66303C7.02362 10.4817 8.49335 12.0387 9.88218 11.1501C10.0497 11.0433 10.1946 10.9046 10.3085 10.7418C10.4224 10.579 10.5032 10.3954 10.5461 10.2014C10.5891 10.0074 10.5934 9.80687 10.5589 9.61121C10.5243 9.41556 10.4516 9.22862 10.3448 9.06108C10.238 8.89353 10.0993 8.74866 9.93649 8.63474C9.77371 8.52082 9.59009 8.44007 9.3961 8.39712C9.20212 8.35416 9.00158 8.34983 8.80592 8.38437C8.61026 8.41892 8.42333 8.49166 8.25578 8.59846C7.74071 8.88953 7.17352 9.0767 6.58637 9.14936Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill='#DFFF60' d="M8.46289 9.86464C8.90727 9.77613 9.36561 9.78572 9.8059 9.89274C10.2462 9.99977 10.6578 10.2016 11.012 10.4843C11.1684 10.6085 11.3029 10.758 11.4101 10.9266" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2746_11753">
          <rect width="16.08" height="16.08" fill="white" transform="translate(0.320312 0.959961)"/>
        </clipPath>
      </defs>
    </svg>




  );
};

const { string } = PropTypes;

IconPersonalDev.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPersonalDev.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPersonalDev;
