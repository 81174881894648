import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconChevron.module.css';

const IconChevron = props => {
  const { className, rootClassName, direction } = props;
  const classes = classNames(rootClassName || css.root, className, {[css.rootUp]: direction === 'up'});

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_3561_2081"
        style={{ maskType: "alpha" }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_3561_2081)">
        <path
          fill="#26736F"
          d="M17.008 9.7L11.7 15.008 6.392 9.7l.708-.708 4.6 4.6 4.6-4.6.708.708z"
        ></path>
      </g>
    </svg>
  )
};

IconChevron.defaultProps = { className: null };

IconChevron.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconChevron;
