import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../FieldCheckbox.module.css';

const IconRelaxMethods = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2746_12161)">
        <path  d="M13.3199 10.041C14.8545 10.6253 15.8241 11.4857 15.8241 12.4488C15.8241 13.2938 15.0728 14.0605 13.849 14.6319C13.8101 14.6501 13.768 14.6604 13.7251 14.662C13.6822 14.6637 13.6394 14.6567 13.5992 14.6415L11.0951 13.688C11.0357 13.6656 10.971 13.6612 10.9092 13.6755C10.8473 13.6897 10.791 13.7221 10.7475 13.7683C10.704 13.8146 10.6752 13.8727 10.6647 13.9353C10.6543 13.9979 10.6626 14.0623 10.6887 14.1202L11.1009 15.0326C11.1213 15.0778 11.1309 15.1271 11.129 15.1767C11.1271 15.2263 11.1138 15.2747 11.09 15.3183C11.0663 15.3618 11.0327 15.3993 10.9921 15.4277C10.9514 15.4561 10.9047 15.4747 10.8556 15.482C10.0559 15.6002 9.24854 15.6594 8.44011 15.6592C4.36288 15.6592 1.05615 14.221 1.05615 12.4488C1.05615 11.4857 2.0257 10.6253 3.56028 10.041H13.3199Z" fill="#F7FFD6"/>
        <path  d="M14.8108 10.8256C14.3514 10.4983 13.8499 10.2343 13.3199 10.041H3.56025C3.03026 10.2343 2.52877 10.4983 2.06934 10.8256C3.01705 12.1682 5.50769 13.1294 8.44009 13.1294C11.3725 13.1294 13.8631 12.1682 14.8108 10.8256Z" fill="#DFFF60"/>
        <path  d="M14.6388 6.07684C13.9312 5.93478 13.2047 5.91437 12.4903 6.01648C12.5041 5.19183 12.4109 4.36888 12.213 3.56822C12.2021 3.52398 12.1819 3.48258 12.1537 3.44675C12.1256 3.41093 12.0902 3.3815 12.0498 3.36042C12.0094 3.33935 11.965 3.32711 11.9195 3.32452C11.874 3.32193 11.8285 3.32906 11.786 3.34541C11.1131 3.60511 10.4849 3.96818 9.92393 4.42154L9.91623 4.45814C9.60287 3.73724 9.19007 3.06378 8.68985 2.45741C8.65973 2.42094 8.62192 2.39158 8.57913 2.37143C8.53634 2.35127 8.48962 2.34082 8.44233 2.34082C8.39503 2.34082 8.34831 2.35127 8.30552 2.37143C8.26273 2.39158 8.22492 2.42094 8.1948 2.45741C7.69884 3.0574 7.28829 3.72313 6.97484 4.43567C6.40887 3.97507 5.77374 3.60669 5.0929 3.34413C5.05038 3.32777 5.00487 3.32065 4.95939 3.32324C4.91391 3.32583 4.8695 3.33806 4.82911 3.35914C4.78872 3.38021 4.75328 3.40964 4.72514 3.44547C4.69701 3.48129 4.67681 3.5227 4.66591 3.56693C4.46837 4.36744 4.37513 5.19014 4.38853 6.01455C3.67394 5.91396 2.94743 5.93567 2.24012 6.07876C2.15754 6.09525 2.08474 6.14353 2.03741 6.21319C1.99009 6.28285 1.97204 6.36832 1.98714 6.45117C2.13225 7.24478 2.64528 9.4131 4.09317 10.5406C5.89101 11.9333 8.44008 11.1942 8.44008 11.1942C8.44008 11.1942 10.9911 11.9333 12.7863 10.5406C14.2342 9.41631 14.7473 7.248 14.8917 6.45245C14.9078 6.36912 14.8902 6.28283 14.8428 6.21244C14.7953 6.14205 14.722 6.0933 14.6388 6.07684Z" fill="#DFFF60"/>
        <path d="M10.3994 6.68232C10.3994 9.17232 8.43913 11.191 8.43913 11.191C8.43913 11.191 6.47949 9.17232 6.47949 6.68232C6.57029 5.12405 7.17215 3.63915 8.19193 2.45741C8.22205 2.42094 8.25986 2.39158 8.30265 2.37143C8.34544 2.35127 8.39215 2.34082 8.43945 2.34082C8.48675 2.34082 8.53347 2.35127 8.57626 2.37143C8.61905 2.39158 8.65685 2.42094 8.68698 2.45741C9.70688 3.63907 10.3088 5.12402 10.3994 6.68232Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill='#DFFF60' d="M9.92123 4.42176C10.4822 3.9684 11.1104 3.60532 11.7833 3.34563C11.8258 3.32927 11.8713 3.32215 11.9168 3.32474C11.9623 3.32733 12.0067 3.33956 12.0471 3.36064C12.0874 3.38171 12.1229 3.41114 12.151 3.44697C12.1792 3.48279 12.1994 3.5242 12.2103 3.56843C12.4125 4.3858 12.8125 6.52843 12.0086 8.26784C10.9948 10.467 8.43994 11.1912 8.43994 11.1912" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.4849 6.01705C13.2001 5.91473 13.9276 5.93536 14.6358 6.07805C14.7183 6.09454 14.791 6.14273 14.8383 6.21224C14.8856 6.28176 14.9037 6.36706 14.8888 6.44982C14.745 7.24536 14.2313 9.41367 12.7834 10.538C10.9856 11.9306 8.43652 11.1916 8.43652 11.1916" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill='#DFFF60' d="M6.97517 4.43619C6.41366 3.96917 5.77743 3.60015 5.09323 3.34465C5.05071 3.32829 5.0052 3.32117 4.95972 3.32376C4.91424 3.32635 4.86983 3.33859 4.82944 3.35966C4.78905 3.38074 4.75361 3.41016 4.72547 3.44599C4.69734 3.48182 4.67714 3.52322 4.66624 3.56745C4.46399 4.38547 4.06397 6.52746 4.86786 8.26686C5.88427 10.4673 8.43977 11.1916 8.43977 11.1916" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.38902 6.01652C3.6746 5.91282 2.94756 5.93455 2.24061 6.08072C2.15802 6.09721 2.08522 6.14549 2.0379 6.21515C1.99058 6.28482 1.97252 6.37028 1.98763 6.45313C2.1321 7.24546 2.64512 9.41378 4.09302 10.5413C5.88829 11.9307 8.43992 11.1917 8.43992 11.1917C8.43992 11.1917 8.43992 11.175 8.4335 11.1423" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill='#DFFF60' d="M13.3199 10.041C14.8545 10.6253 15.8241 11.4857 15.8241 12.4488C15.8241 13.2938 15.0728 14.0605 13.849 14.6319C13.8101 14.6501 13.768 14.6604 13.7251 14.662C13.6822 14.6637 13.6394 14.6567 13.5992 14.6415L11.0951 13.688C11.0357 13.6656 10.971 13.6612 10.9092 13.6755C10.8473 13.6897 10.791 13.7221 10.7475 13.7683C10.704 13.8146 10.6752 13.8727 10.6647 13.9353C10.6543 13.9979 10.6626 14.0623 10.6887 14.1202L11.1009 15.0326C11.1213 15.0778 11.1309 15.1271 11.129 15.1767C11.1271 15.2263 11.1138 15.2747 11.09 15.3183C11.0663 15.3618 11.0327 15.3993 10.9921 15.4277C10.9514 15.4561 10.9047 15.4747 10.8556 15.482C10.0559 15.6002 9.24854 15.6594 8.44011 15.6592C4.36288 15.6592 1.05615 14.221 1.05615 12.4488C1.05615 11.4857 2.0257 10.6253 3.56028 10.041" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2746_12161">
          <rect width="16.08" height="16.08" fill="white" transform="translate(0.399902 0.959961)"/>
        </clipPath>
      </defs>
    </svg>



  );
};

const { string } = PropTypes;

IconRelaxMethods.defaultProps = {
  className: null,
  rootClassName: null,
};

IconRelaxMethods.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconRelaxMethods;
