import React, {useState} from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName, IconChevron } from '../../components';
import { propTypes } from '../../util/types';

import css from './Reviews.module.css';

const REVIEWS_COUNTER = 6

const Review = props => {
  const { review, intl } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={review.author} disableProfileLink />
      <div>
        <ReviewRating
          rating={review.attributes.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />
        <p className={css.reviewContent}>{review.attributes.content}</p>
        <p className={css.reviewInfo}>
          <UserDisplayName user={review.author} intl={intl} />
          <span className={css.separator}>•</span>
          {dateString}
          {/* <span className={css.desktopSeparator}>•</span>
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span> */}
        </p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  const [reviewsItems, setReviewsItems] = useState(reviews);
  const [visibleCount, setVisibleCount] = useState(REVIEWS_COUNTER);

  const showMore = () => {
    setVisibleCount(prevCount => prevCount + REVIEWS_COUNTER);
  };

  const showFewer = () => {
    setVisibleCount(prevCount => (prevCount - REVIEWS_COUNTER > REVIEWS_COUNTER ? prevCount - REVIEWS_COUNTER : REVIEWS_COUNTER));
  };

  return (
    <div>
      <ul className={classes}>
        {reviewsItems.slice(0, visibleCount).map(r => {
          return (
            <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
              <Review review={r} intl={intl} />
            </li>
          );
        })}
      </ul>
      <div className={css.buttonContainer}>
        {visibleCount > REVIEWS_COUNTER && (
          <a onClick={showFewer} className={css.showButton}>
            Show less
            <IconChevron direction='up' />
          </a>
        )}
        {visibleCount < reviewsItems.length && (
          <a onClick={showMore} className={css.showButton}>
            Show more
            <IconChevron />
          </a>
        )}
      </div>
    </div>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
