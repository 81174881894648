import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconFlag.module.css';

const IconFlag = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_3235_11409)">
        <path
          fill="#DFFF60"
          d="M.654 12.79a13.925 13.925 0 013.833-.638c1.916 0 4.79 1.277 6.707 1.277 1.28-.06 2.542-.329 3.736-.795a.638.638 0 00.415-.599V3.171a.639.639 0 00-.843-.605c-1.063.382-2.18.599-3.308.643-1.917 0-4.791-1.277-6.707-1.277a13.921 13.921 0 00-3.833.638v10.22z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M8 12.833V2.613a15.3 15.3 0 00-3.513-.681 13.921 13.921 0 00-3.833.638v10.22a13.902 13.902 0 013.833-.638c1.193.089 2.373.317 3.513.681z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M.654 15.345V.655M.654 12.79a13.925 13.925 0 013.833-.638c1.916 0 4.79 1.277 6.707 1.277 1.28-.06 2.542-.329 3.736-.795a.638.638 0 00.415-.599V3.171a.638.638 0 00-.843-.605c-1.064.382-2.18.598-3.308.643-1.917 0-4.791-1.277-6.707-1.277a13.921 13.921 0 00-3.833.638v10.22zM8.32 8.957V5.125"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M8.314 10.236a.158.158 0 00-.105.273c.03.03.07.046.111.047a.158.158 0 00.107-.271.162.162 0 00-.107-.049M8.32 10.236h-.004"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3235_11409">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
};

IconFlag.defaultProps = { className: null };

IconFlag.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconFlag;
