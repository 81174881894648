import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from '../FieldCheckbox.module.css';

const IconEmpowerment = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width='17' height='18' viewBox='0 0 17 18' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.54541 8.68306C3.54541 9.8751 4.01895 11.0183 4.86184 11.8612C5.70474 12.7041 6.84796 13.1776 8.03999 13.1776C9.23203 13.1776 10.3752 12.7041 11.2181 11.8612C12.061 11.0183 12.5346 9.8751 12.5346 8.68306C12.5346 7.49102 12.061 6.34781 11.2181 5.50491C10.3752 4.66201 9.23203 4.18848 8.03999 4.18848C6.84796 4.18848 5.70474 4.66201 4.86184 5.50491C4.01895 6.34781 3.54541 7.49102 3.54541 8.68306Z'
        fill='#DFFF60' />
      <path
        d='M8.03992 6.45416C9.03336 6.45509 9.9985 6.78512 10.7845 7.39268C11.5705 8.00025 12.1331 8.8511 12.3843 9.81226C12.561 9.14675 12.5824 8.44954 12.447 7.77443C12.3115 7.09932 12.0228 6.46436 11.603 5.91855C11.1832 5.37274 10.6436 4.93067 10.0259 4.62647C9.40817 4.32227 8.72881 4.16406 8.04024 4.16406C7.35167 4.16406 6.67231 4.32227 6.05458 4.62647C5.43685 4.93067 4.89728 5.37274 4.47749 5.91855C4.05771 6.46436 3.76895 7.09932 3.63349 7.77443C3.49803 8.44954 3.51949 9.14675 3.69622 9.81226C3.94739 8.8512 4.50986 8.00043 5.29572 7.39288C6.08159 6.78533 7.04659 6.45523 8.03992 6.45416Z'
        fill='#F7FFD6' />
      <path d='M5.47168 11.8896L8.04001 9.32129L10.6083 11.8896' stroke='#21262A'
            strokeWidth='0.67' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.04004 9.32129V15.1' stroke='#21262A' strokeWidth='0.67' strokeLinecap='round'
            strokeLinejoin='round' />
      <path d='M8.04004 2.90038V1.61621' stroke='#21262A' strokeWidth='0.67'
            strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.1262 4.59248L13.0341 3.68457' stroke='#21262A' strokeWidth='0.67'
            strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.9538 4.59248L3.0459 3.68457' stroke='#21262A' strokeWidth='0.67'
            strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.1262 12.7656L13.0341 13.6735' stroke='#21262A' strokeWidth='0.67'
            strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.9538 12.7656L3.0459 13.6735' stroke='#21262A' strokeWidth='0.67'
            strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.26122 8.67871H0.977051' stroke='#21262A' strokeWidth='0.67'
            strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15.103 8.67871H13.8188' stroke='#21262A' strokeWidth='0.67'
            strokeLinecap='round' strokeLinejoin='round' />
      <path d='M0.977051 16.3838H15.1029' stroke='#21262A' strokeWidth='0.67'
            strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M11.915 10.9486C12.3162 10.2659 12.5299 9.48923 12.5345 8.69734C12.5391 7.90545 12.3344 7.12641 11.9411 6.43908C11.5478 5.75175 10.9798 5.18053 10.2948 4.78326C9.60974 4.38599 8.8319 4.17676 8.03999 4.17676C7.24809 4.17676 6.47024 4.38599 5.7852 4.78326C5.10016 5.18053 4.53224 5.75175 4.13893 6.43908C3.74563 7.12641 3.5409 7.90545 3.54549 8.69734C3.55007 9.48923 3.76379 10.2659 4.16502 10.9486'
        stroke='#21262A' strokeWidth='0.67' strokeLinecap='round' strokeLinejoin='round' fill='#DFFF60' />
    </svg>


  );
};

const { string } = PropTypes;

IconEmpowerment.defaultProps = {
  className: null,
  rootClassName: null,
};

IconEmpowerment.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEmpowerment;
