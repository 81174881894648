import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../FieldCheckbox.module.css';

const IconLifestyle = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='#DFFF60' d="M13.4624 7.7062C11.8861 7.80765 11.9811 7.07375 10.6084 7.07375C9.32419 7.07375 9.32419 7.71583 8.04003 7.71583C6.75586 7.71583 6.75586 7.07375 5.47169 7.07375C4.18753 7.07375 4.18753 7.71262 2.91363 7.71583C2.83172 7.71598 2.75285 7.68481 2.69317 7.62871C2.63349 7.57261 2.59751 7.49582 2.59259 7.41405C2.54663 6.65855 2.65832 5.90175 2.92056 5.19173C3.1828 4.48171 3.58986 3.834 4.11587 3.28976C4.64189 2.74551 5.27536 2.31662 5.97603 2.03035C6.67671 1.74408 7.42927 1.60668 8.1859 1.62688C8.94252 1.64709 9.68668 1.82445 10.3711 2.1477C11.0555 2.47095 11.6652 2.93303 12.1614 3.50457C12.6576 4.07611 13.0295 4.74462 13.2535 5.46762C13.4775 6.19062 13.5486 6.9523 13.4624 7.70427" fill="#DFFF60"/>
      <path fill='#DFFF60' d="M8.03999 3.95706C9.16102 3.95758 10.2607 4.26363 11.2208 4.84229C12.181 5.42095 12.9652 6.25034 13.4893 7.24131C13.5131 6.5108 13.3898 5.78297 13.1267 5.10108C12.8635 4.41919 12.466 3.79717 11.9576 3.27201C11.4493 2.74684 10.8406 2.32925 10.1676 2.04406C9.49464 1.75887 8.7712 1.61191 8.04031 1.61191C7.30941 1.61191 6.58598 1.75887 5.91302 2.04406C5.24006 2.32925 4.63131 2.74684 4.12297 3.27201C3.61463 3.79717 3.21707 4.41919 2.95394 5.10108C2.69081 5.78297 2.56748 6.5108 2.59127 7.24131C3.11532 6.25044 3.89949 5.42111 4.8595 4.84245C5.81951 4.2638 6.91907 3.9577 8.03999 3.95706Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M13.4624 7.7062C11.8861 7.80765 11.9811 7.07375 10.6084 7.07375C9.32419 7.07375 9.32419 7.71583 8.04003 7.71583C6.75586 7.71583 6.75586 7.07375 5.47169 7.07375C4.18753 7.07375 4.18753 7.71262 2.91363 7.71583C2.83172 7.71598 2.75285 7.68481 2.69317 7.62871C2.63349 7.57261 2.59751 7.49582 2.59259 7.41405C2.54663 6.65855 2.65832 5.90175 2.92056 5.19173C3.1828 4.48171 3.58986 3.834 4.11587 3.28976C4.64189 2.74551 5.27536 2.31662 5.97603 2.03035C6.67671 1.74408 7.42927 1.60668 8.1859 1.62688C8.94253 1.64709 9.68668 1.82445 10.3711 2.1477C11.0555 2.47095 11.6652 2.93303 12.1614 3.50457C12.6576 4.07611 13.0295 4.74462 13.2535 5.46762C13.4775 6.19062 13.5486 6.9523 13.4624 7.70427" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M4.36865 5.75618C4.76109 4.91214 5.36161 4.1817 6.11383 3.63345" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M8.05993 16.3839C8.123 15.5066 7.97871 14.6268 7.63873 13.8156C7.28246 13.1192 7.09028 12.3505 7.0769 11.5683C7.09028 10.7862 7.28246 10.0175 7.63873 9.32103C7.85683 8.81026 7.99437 8.26876 8.04645 7.71582" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M5.49157 16.3839C5.55464 15.5066 5.41035 14.6268 5.07037 13.8156C4.7141 13.1192 4.52193 12.3505 4.50854 11.5683C4.52193 10.7862 4.7141 10.0175 5.07037 9.32102C5.36862 8.60984 5.51725 7.84486 5.50698 7.07373" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M10.6283 16.3839C10.6914 15.5066 10.5471 14.6268 10.2071 13.8156C9.85082 13.1192 9.65864 12.3505 9.64526 11.5683C9.65864 10.7862 9.85082 10.0175 10.2071 9.32102C10.5053 8.60984 10.654 7.84486 10.6437 7.07373" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

const { string } = PropTypes;

IconLifestyle.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLifestyle.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLifestyle;
