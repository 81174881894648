import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../FieldCheckbox.module.css';

const IconTSupport = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.52034 0.746094C8.56992 0.746094 8.61883 0.757638 8.66317 0.779811C8.70752 0.801985 8.74609 0.834179 8.77584 0.873844C9.93357 2.34241 10.6234 4.1249 10.756 5.99023C11.3392 5.40002 12.0474 4.94811 12.8284 4.66778C13.6094 4.38744 14.4433 4.28581 15.2687 4.37036C15.3485 4.37479 15.4236 4.40892 15.4794 4.46603C15.5352 4.52313 15.5676 4.59907 15.5702 4.67888C15.6207 5.76475 15.5108 9.68859 11.3966 10.786L12.9795 11.6898C13.0358 11.7219 13.0811 11.7704 13.1092 11.8289C13.1373 11.8874 13.1469 11.953 13.1368 12.0171C13.1267 12.0812 13.0973 12.1407 13.0525 12.1877C13.0077 12.2346 12.9497 12.2668 12.8862 12.28C11.3948 12.597 9.83879 12.3465 8.52226 11.5774C7.20595 12.3464 5.65015 12.597 4.15896 12.28C4.09543 12.2668 4.03742 12.2346 3.99266 12.1877C3.94789 12.1407 3.91849 12.0812 3.90836 12.0171C3.89823 11.953 3.90787 11.8874 3.93597 11.8289C3.96408 11.7704 4.00933 11.7219 4.0657 11.6898L5.64788 10.786C1.53242 9.68604 1.42191 5.76475 1.47301 4.67888C1.4762 4.59973 1.50869 4.52459 1.56417 4.46805C1.61964 4.41151 1.69415 4.3776 1.77323 4.37292C2.59848 4.28851 3.43216 4.39022 4.21292 4.67055C4.99367 4.95089 5.70164 5.40271 6.28472 5.99279C6.4175 4.12751 7.10732 2.34508 8.26484 0.876399C8.29432 0.836263 8.33278 0.803578 8.37714 0.780953C8.42151 0.758328 8.47055 0.74639 8.52034 0.746094Z" fill="#DFFF60"/>
      <path d="M15.5699 4.67865C15.5662 4.60016 15.5336 4.52581 15.4784 4.46984C15.4233 4.41386 15.3494 4.38018 15.271 4.37524C14.4455 4.29069 13.6116 4.39232 12.8306 4.67266C12.0497 4.953 11.3415 5.4049 10.7582 5.99511C10.6257 4.12979 9.93584 2.34729 8.77811 0.878727C8.74836 0.839061 8.70978 0.806867 8.66544 0.784694C8.62109 0.76252 8.57219 0.750977 8.52261 0.750977C8.47303 0.750977 8.42413 0.76252 8.37978 0.784694C8.33543 0.806867 8.29686 0.839061 8.26711 0.878727C7.10959 2.34741 6.41976 4.12984 6.28698 5.99511C5.70386 5.40423 4.99557 4.95172 4.21433 4.67093C3.43309 4.39013 2.5988 4.28822 1.77294 4.37269C1.69386 4.37738 1.61936 4.41128 1.56388 4.46782C1.5084 4.52436 1.47591 4.5995 1.47272 4.67865C1.42993 5.5927 1.51105 8.5054 3.97918 10.0633C3.59499 9.29125 3.4153 8.43353 3.45732 7.57219C3.46005 7.51574 3.48354 7.46228 3.52326 7.42208C3.56299 7.38188 3.61616 7.35777 3.67258 7.35437C4.2655 7.29354 4.86451 7.36646 5.42552 7.56776C5.98653 7.76906 6.49526 8.09362 6.91424 8.51754C7.00926 7.17774 7.50438 5.89734 8.33545 4.84217C8.35684 4.81324 8.3847 4.78974 8.41682 4.77354C8.44894 4.75734 8.4844 4.7489 8.52037 4.7489C8.55634 4.7489 8.59181 4.75734 8.62393 4.77354C8.65604 4.78974 8.68391 4.81324 8.70529 4.84217C9.53637 5.89734 10.0315 7.17774 10.1265 8.51754C10.5454 8.09354 11.0542 7.76893 11.6152 7.56763C12.1762 7.36632 12.7752 7.29345 13.3682 7.35437C13.4246 7.35777 13.4778 7.38188 13.5175 7.42208C13.5572 7.46228 13.5807 7.51574 13.5834 7.57219C13.6248 8.43348 13.4451 9.29104 13.0616 10.0633C15.5297 8.50221 15.6108 5.59015 15.5699 4.67865Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M15.1308 7.50831C15.4631 6.60348 15.6125 5.64166 15.5702 4.67865C15.5664 4.60006 15.5338 4.52563 15.4785 4.46964C15.4232 4.41364 15.3492 4.38003 15.2707 4.37524C14.4452 4.29069 13.6113 4.39232 12.8303 4.67266C12.0493 4.953 11.3411 5.4049 10.7579 5.99511C10.6253 4.12979 9.93549 2.34729 8.77776 0.878727C8.74801 0.839061 8.70944 0.806867 8.66509 0.784694C8.62074 0.76252 8.57184 0.750977 8.52226 0.750977C8.47268 0.750977 8.42378 0.76252 8.37943 0.784694C8.33508 0.806867 8.29651 0.839061 8.26676 0.878727C7.10924 2.34741 6.41941 4.12984 6.28664 5.99511C5.70358 5.40432 4.9954 4.95186 4.21428 4.67107C3.43315 4.39028 2.59899 4.28832 1.77323 4.37269C1.69415 4.37738 1.61965 4.41128 1.56417 4.46782C1.50869 4.52436 1.4762 4.5995 1.47302 4.67865C1.42192 5.76453 1.53242 9.68837 5.64597 10.7857L4.06379 11.687C4.00742 11.7191 3.96217 11.7677 3.93406 11.8261C3.90595 11.8846 3.89632 11.9503 3.90644 12.0143C3.91657 12.0784 3.94597 12.1379 3.99074 12.1849C4.03551 12.2318 4.09352 12.264 4.15704 12.2772C5.64823 12.5942 7.20404 12.3437 8.52034 11.5746" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M8.52002 15.2547V11.5762" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.0371 8.9676C15.0875 8.94361 15.1432 8.93321 15.1988 8.93744C15.2544 8.94167 15.3079 8.96038 15.354 8.99171C15.4001 9.02304 15.4373 9.06589 15.4617 9.11601C15.4861 9.16613 15.497 9.22177 15.4932 9.27739C15.3917 10.7203 14.8634 14.4685 12.1206 14.4685C11.8085 14.4737 11.4986 14.4166 11.2088 14.3008C10.9189 14.1849 10.6551 14.0125 10.4326 13.7936C10.2101 13.5748 10.0334 13.3138 9.91273 13.0259C9.7921 12.7381 9.72998 12.4291 9.72998 12.117C9.72998 11.8048 9.7921 11.4958 9.91273 11.208C10.0334 10.9201 10.2101 10.6592 10.4326 10.4403C10.6551 10.2214 10.9189 10.049 11.2088 9.93314C11.4986 9.81728 11.8085 9.76025 12.1206 9.7654C13.1371 9.70154 14.1297 9.43002 15.0371 8.9676Z" fill="#F7FFD6"/>
      <path d="M15.3546 8.9919C15.3131 8.96556 15.2662 8.94897 15.2173 8.94336C14.9433 10.6424 14.1691 13.048 11.9705 13.048C11.5144 13.0472 11.0683 12.9137 10.6867 12.6639C10.305 12.414 10.0043 12.0586 9.82116 11.6408C9.78852 11.7988 9.77204 11.9598 9.77197 12.1211C9.77265 12.7438 10.0203 13.3408 10.4606 13.7812C10.901 14.2215 11.498 14.4691 12.1207 14.4698C14.8634 14.4698 15.3917 10.7216 15.4933 9.2787C15.4973 9.22295 15.4866 9.16712 15.4623 9.11679C15.4379 9.06646 15.4008 9.0234 15.3546 8.9919Z" fill="#DFFF60"/>
      <path fill='#DFFF60' d="M15.0371 8.9676C15.0875 8.94361 15.1432 8.93321 15.1988 8.93744C15.2544 8.94167 15.3079 8.96038 15.354 8.99171C15.4001 9.02304 15.4373 9.06589 15.4617 9.11601C15.4861 9.16613 15.497 9.22177 15.4932 9.27739C15.3917 10.7203 14.8634 14.4685 12.1206 14.4685C11.8085 14.4737 11.4986 14.4166 11.2088 14.3008C10.9189 14.1849 10.6551 14.0125 10.4326 13.7936C10.2101 13.5748 10.0334 13.3138 9.91273 13.0259C9.7921 12.7381 9.72998 12.4291 9.72998 12.117C9.72998 11.8048 9.7921 11.4958 9.91273 11.208C10.0334 10.9201 10.2101 10.6592 10.4326 10.4403C10.6551 10.2214 10.9189 10.049 11.2088 9.93314C11.4986 9.81728 11.8085 9.76025 12.1206 9.7654C13.1371 9.70154 14.1297 9.43002 15.0371 8.9676Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.76904 15.2545C9.97025 14.4424 10.3586 13.6886 10.903 13.0533C11.4475 12.418 12.1329 11.9189 12.9047 11.5957" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

const { string } = PropTypes;

IconTSupport.defaultProps = {
  className: null,
  rootClassName: null,
};

IconTSupport.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconTSupport;
