import pick from 'lodash/pick';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  transactionLineItems,
  validateVoucherApi,
  checkIfFirstTransactionAPI,
  setExceptions,
  checkIsStripeConnected
} from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import { isProfileBooking, isTreatmentBooking } from '../../util/types';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { getProcess, isBookingProcessAlias, INQUIRY_PROCESS_NAME } from '../../transactions/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const FETCH_OFFERS_REQUEST = 'app/ListingPage/FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_SUCCESS = 'app/ListingPage/FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_ERROR = 'app/ListingPage/FETCH_OFFERS_ERROR';

export const FETCH_PROFILE_REQUEST = 'app/ListingPage/FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'app/ListingPage/FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_ERROR = 'app/ListingPage/FETCH_PROFILE_ERROR';

export const VALIDATE_VOUCHER_REQUEST = 'app/ListingPage/VALIDATE_VOUCHER_REQUEST';
export const VALIDATE_VOUCHER_SUCCESS = 'app/ListingPage/VALIDATE_VOUCHER_SUCCESS';
export const VALIDATE_VOUCHER_ERROR = 'app/ListingPage/VALIDATE_VOUCHER_ERROR';

export const FETCH_TRANSACTION_REQUEST = 'app/ListingPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/ListingPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/ListingPage/FETCH_TRANSACTION_ERROR';

export const FETCH_FIRST_TRANSACTION_REQUEST = 'app/ListingPage/FETCH_FIRST_TRANSACTION_REQUEST';
export const FETCH_FIRST_TRANSACTION_SUCCESS = 'app/ListingPage/FETCH_FIRST_TRANSACTION_SUCCESS';
export const FETCH_FIRST_TRANSACTION_ERROR = 'app/ListingPage/FETCH_FIRST_TRANSACTION_ERROR';

export const CHECK_STRIPE_CONNECT = 'app/ListingPage/CHECK_STRIPE_CONNECT';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquiryModalOpenForListingId: null,

  fetchOffesrInProgress: false,
  offerListingsResultIds: [],
  fetchOffesrError: null,

  fetchProfileInProgress: false,
  profileListingResultId: [],
  fetchProfileError: null,
  // Voucherify
  voucherValid: null,
  voucherError: null,
  voucherInProgress: false,
  voucherRequested: false,

  isFirstTransaction: true,
  isFirstTransactionInProgress: false,
  isFirstTransactionError: null,
  rules: [],
  redemptions: [],

  firstMarketplaceTx: false,
  firstMarketplaceTxInProgress: false,
  firstMarketplaceTxError: null,

  isStripeConnected: false
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };

    case FETCH_OFFERS_REQUEST:
      return { ...state, fetchOffesrInProgress: true, fetchOffesrError: null };
    case FETCH_OFFERS_SUCCESS:
      return {
        ...state,
        offerListingsResultIds: resultIds(payload.data),
        fetchOffesrInProgress: false,
      };
    case FETCH_OFFERS_ERROR:
      return { ...state, fetchOffesrInProgress: false, fetchOffesrError: payload };

    case FETCH_PROFILE_REQUEST:
      return { ...state, fetchProfileInProgress: true, fetchProfileError: null };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profileListingResultId: resultIds(payload.data),
        fetchProfileInProgress: false,
      };
    case FETCH_PROFILE_ERROR:
      return { ...state, fetchProfileInProgress: false, fetchProfileError: payload };

    case VALIDATE_VOUCHER_REQUEST:
      return { ...state, voucherInProgress: true, voucherError: null, voucherRequested: false };
    case VALIDATE_VOUCHER_SUCCESS:
      return {
        ...state,
        voucherInProgress: false,
        voucherValid: payload.voucher,
        rules: payload.rules,
        redemptions: payload.redemptions,
        voucherRequested: true,
      };
    case VALIDATE_VOUCHER_ERROR:
      return { ...state, voucherInProgress: false, voucherError: payload, voucherRequested: true };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, isFirstTransactionInProgress: true, isFirstTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS:
      return { ...state, isFirstTransactionInProgress: false, isFirstTransaction: payload };
    case FETCH_TRANSACTION_ERROR:
      return { ...state, isFirstTransactionInProgress: false, isFirstTransactionError: payload };

    case FETCH_FIRST_TRANSACTION_REQUEST:
      return { ...state, firstMarketplaceTxInProgress: true, firstMarketplaceTxError: null };
    case FETCH_FIRST_TRANSACTION_SUCCESS:
      return { ...state, firstMarketplaceTxInProgress: false, firstMarketplaceTx: payload };
    case FETCH_FIRST_TRANSACTION_ERROR:
      return { ...state, firstMarketplaceTxInProgress: false, firstMarketplaceTxError: payload };

    case CHECK_STRIPE_CONNECT:
      return { ...state, isStripeConnected: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

export const fetchOffesrRequest = searchParams => ({
  type: FETCH_OFFERS_REQUEST,
  payload: { searchParams },
});

export const fetchOffesrSuccess = response => ({
  type: FETCH_OFFERS_SUCCESS,
  payload: { data: response.data },
});

export const fetchOffesrError = e => ({
  type: FETCH_OFFERS_ERROR,
  error: true,
  payload: e,
});

export const fetchProfileRequest = searchParams => ({
  type: FETCH_PROFILE_REQUEST,
  payload: { searchParams },
});

export const fetchProfileSuccess = response => ({
  type: FETCH_PROFILE_SUCCESS,
  payload: { data: response.data },
});

export const fetchProfileError = e => ({
  type: FETCH_PROFILE_ERROR,
  error: true,
  payload: e,
});

export const validateVoucherRequest = () => ({ type: VALIDATE_VOUCHER_REQUEST });
export const validateVoucherSuccess = payload => ({ type: VALIDATE_VOUCHER_SUCCESS, payload });
export const validateVoucherError = error => ({
  type: VALIDATE_VOUCHER_ERROR,
  error: true,
  payload: error,
});

export const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
export const fetchTransactionSuccess = payload => ({ type: FETCH_TRANSACTION_SUCCESS, payload });
export const fetchTransactionError = error => ({
  type: FETCH_TRANSACTION_ERROR,
  error: true,
  payload: error,
});

export const fetchFirstTransactionRequest = () => ({ type: FETCH_FIRST_TRANSACTION_REQUEST });
export const fetchFirstTransactionSuccess = payload => ({
  type: FETCH_FIRST_TRANSACTION_SUCCESS,
  payload,
});
export const fetchFirstTransactionError = error => ({
  type: FETCH_FIRST_TRANSACTION_ERROR,
  error: true,
  payload: error,
});

export const checkStripeConnect = payload => ({
  type: CHECK_STRIPE_CONNECT,
  payload,
});

// ================ Thunks ================ //

const onCheckIsStripeConnected = (authorId) => async dispatch => {

  try {
    const response = await checkIsStripeConnected({userId:authorId})

    dispatch(checkStripeConnect(response));
  } catch (e) {
    console.log(e);
  }
};

export const showListing = (listingId, config, isOwn = false) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'author.stripeAccount', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      // const listingFields = config?.listing?.listingFields;
      // const sanitizeConfig = { listingFields };
      const sanitizeConfig = {};
      dispatch(addMarketplaceEntities(data, sanitizeConfig));
      dispatch(getFirstPlatformTx());
      dispatch(onCheckIsStripeConnected(data.data.data.relationships.author.data.id.uuid));

      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage', 'author.stripeAccount',],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendInquiry = (listing, listingAuthorId, bookingParams) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  // const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  // const [processName, alias] = processAlias.split('/');

  // if (!processAlias) {
  //   const error = new Error('No transaction process attached to listing');
  //   log.error(error, 'listing-process-missing', {
  //     listingId: listing?.id?.uuid,
  //   });
  //   dispatch(sendInquiryError(storableError(error)));
  //   return Promise.reject(error);
  // }

  const listingId = listing?.id;
  const { bookingStart, bookingEnd, phoneNumber } = bookingParams

  const transitions = getProcess(INQUIRY_PROCESS_NAME)?.transitions;

  const bodyParams = {
    transition: transitions.INQUIRE_WITHOUT_PAYMENT,
    processAlias: 'default-inquiry/release-1',
    params: { 
      listingId, 
      bookingStart, 
      bookingEnd,
      protectedData: {
        phoneNumber
      }
    },
  };

  return sdk.transactions
  .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id
      
      dispatch(sendInquirySuccess());
      dispatch(fetchCurrentUserHasOrdersSuccess(true));

      sdk.listings.query({ authorId: listingAuthorId, pub_listingType: 'treatment-booking' })
        .then(response => {
          const currentAuthorListings = response.data.data;
    
          return currentAuthorListings.map(l => {
            return setExceptions({
              listingId: l.id.uuid,
              start: bookingStart,
              end: bookingEnd,
            });
          });
        })
        .catch(e => console.log(e));

      const message = `Hi, this is my number ${phoneNumber}, I am looking forward to our chemistry call.`

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        return transactionId;
      });
    })    
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';
    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;

      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const fetchOffers = (params, config) => (dispatch, getState, sdk) => {
  dispatch(fetchOffesrRequest(params));

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response, {}));
      dispatch(fetchOffesrSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchOffesrError(storableError(e)));
      throw e;
    });
};

export const fetchProfile = (params, config) => (dispatch, getState, sdk) => {
  dispatch(fetchProfileRequest(params));

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response, {}));
      dispatch(fetchProfileSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchProfileError(storableError(e)));
      throw e;
    });
};

const failureTxTransitions = [
  'transition/decline',
  'transition/operator-decline',
  'transition/expire',
  'transition/cancel',
];

const isFirstTransaction = transaction => {
  if (!transaction.data.meta.totalItems) {
    return true;
  }

  const declinedTransactions = transaction.data.data.filter(({ attributes }) => {
    const lastTransition = attributes.lastTransition;
    return failureTxTransitions.includes(lastTransition);
  });

  if (declinedTransactions.length === transaction.data.data.length) {
    return true;
  }

  return false;
};

export const checkIfFirstTransaction = (customerId, providerId) => async dispatch => {
  dispatch(fetchTransactionRequest());

  try {
    const response = await checkIfFirstTransactionAPI({
      customerId,
      providerId,
    });

    const isFirst = isFirstTransaction(response);
    dispatch(fetchTransactionSuccess(isFirst));
  } catch (e) {
    dispatch(fetchTransactionError(storableError(e)));
  }
};

export const loadData = (params, search, config) => dispatch => {
  const listingId = new UUID(params.id);

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true));
  }

  return Promise.all([
    dispatch(showListing(listingId, config)),
    dispatch(fetchReviews(listingId)),
  ]).then(response => {
    const listing = response[0].data.data;
    const authorId = listing?.relationships?.author?.data?.id?.uuid;

    const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
    if (isBookingProcessAlias(transactionProcessAlias)) {
      // Fetch timeSlots.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      fetchMonthlyTimeSlots(dispatch, listing);
    }

    if (isProfileBooking(listing)) {
      const {
        aspectWidth = 1,
        aspectHeight = 1,
        variantPrefix = 'listing-card',
      } = config.layout.listingImage;
      const aspectRatio = aspectHeight / aspectWidth;

      dispatch(
        fetchOffers(
          {
            authorId,
            pub_listingType: 'treatment-booking',
            include: ['author', 'images'],
            'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'availabilityPlan'],
            'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
            'fields.image': [
              'variants.scaled-small',
              'variants.scaled-medium',
              `variants.${variantPrefix}`,
              `variants.${variantPrefix}-2x`,
            ],
            ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
            ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
          },
          config
        )
      );
    }

    if (isTreatmentBooking(listing)) {
      const {
        aspectWidth = 1,
        aspectHeight = 1,
        variantPrefix = 'listing-card',
      } = config.layout.listingImage;
      const aspectRatio = aspectHeight / aspectWidth;

      dispatch(
        fetchProfile(
          {
            authorId,
            pub_listingType: 'profile-booking',
            include: ['author', 'images'],
            'fields.listing': ['title', 'geolocation', 'description', 'price', 'publicData', 'availabilityPlan'],
            'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
            'fields.image': [
              'variants.scaled-small',
              'variants.scaled-medium',
              `variants.${variantPrefix}`,
              `variants.${variantPrefix}-2x`,
            ],
            ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
            ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
          },
          config
        )
      );
    }

    return response;
  });
};

export const validateVoucher = params => async dispatch => {
  dispatch(validateVoucherRequest());

  try {
    const response = await validateVoucherApi(params);

    dispatch(validateVoucherSuccess(response));
    return response;
  } catch (error) {
    dispatch(validateVoucherError(storableError(error)));
  }
};

const getFirstPlatformTx = () => (dispatch, getState, sdk) => {
  dispatch(fetchFirstTransactionRequest());

  const state = getState();
  const currentUser = state.user.currentUser;

  if (!currentUser) {
    dispatch(fetchFirstTransactionSuccess(true));
    return Promise.resolve();
  }

  return sdk.transactions
    .query({
      only: 'order',
    })
    .then(response => {
      const isFirst = isFirstTransaction(response);
      dispatch(fetchFirstTransactionSuccess(isFirst));
    })
    .catch(e => {
      dispatch(fetchFirstTransactionError(storableError(e)));
      throw e;
    });
};
