import React from 'react';

const ErrorNotificationIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: 'none', marginRight: 5 }}
    >
      <g clipPath="url(#clip0_3651_7423)">
        <path
          d="M0.65625 8.00187C0.65625 8.96652 0.84625 9.92171 1.2154 10.8129C1.58455 11.7041 2.12563 12.5139 2.80773 13.196C3.48984 13.8781 4.29961 14.4192 5.19083 14.7883C6.08204 15.1575 7.03723 15.3475 8.00187 15.3475C8.96652 15.3475 9.92171 15.1575 10.8129 14.7883C11.7041 14.4192 12.5139 13.8781 13.196 13.196C13.8781 12.5139 14.4192 11.7041 14.7883 10.8129C15.1575 9.92171 15.3475 8.96652 15.3475 8.00187C15.3475 7.03723 15.1575 6.08204 14.7883 5.19083C14.4192 4.29961 13.8781 3.48984 13.196 2.80773C12.5139 2.12563 11.7041 1.58455 10.8129 1.2154C9.92171 0.84625 8.96652 0.65625 8.00187 0.65625C7.03723 0.65625 6.08204 0.84625 5.19083 1.2154C4.29961 1.58455 3.48984 2.12563 2.80773 2.80773C2.12563 3.48984 1.58455 4.29961 1.2154 5.19083C0.84625 6.08204 0.65625 7.03723 0.65625 8.00187Z"
          fill="#FF5A5F"
        />
        <path
          d="M2.8039 13.1925C1.42628 11.8149 0.652344 9.94646 0.652344 7.99821C0.652344 6.04997 1.42628 4.18152 2.8039 2.8039C4.18152 1.42628 6.04997 0.652344 7.99821 0.652344C9.94646 0.652344 11.8149 1.42628 13.1925 2.8039L2.8039 13.1925Z"
          fill="#FFCECF"
        />
        <path
          d="M0.65625 8.00187C0.65625 8.96652 0.84625 9.92171 1.2154 10.8129C1.58455 11.7041 2.12563 12.5139 2.80773 13.196C3.48984 13.8781 4.29961 14.4192 5.19083 14.7883C6.08204 15.1575 7.03723 15.3475 8.00187 15.3475C8.96652 15.3475 9.92171 15.1575 10.8129 14.7883C11.7041 14.4192 12.5139 13.8781 13.196 13.196C13.8781 12.5139 14.4192 11.7041 14.7883 10.8129C15.1575 9.92171 15.3475 8.96652 15.3475 8.00187C15.3475 7.03723 15.1575 6.08204 14.7883 5.19083C14.4192 4.29961 13.8781 3.48984 13.196 2.80773C12.5139 2.12563 11.7041 1.58455 10.8129 1.2154C9.92171 0.84625 8.96652 0.65625 8.00187 0.65625C7.03723 0.65625 6.08204 0.84625 5.19083 1.2154C4.29961 1.58455 3.48984 2.12563 2.80773 2.80773C2.12563 3.48984 1.58455 4.29961 1.2154 5.19083C0.84625 6.08204 0.65625 7.03723 0.65625 8.00187Z"
          stroke="#21262A"
          strokeWidth="0.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.80469 4.80469L11.1922 11.1922"
          stroke="#21262A"
          strokeWidth="0.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1922 4.80469L4.80469 11.1922"
          stroke="#21262A"
          strokeWidth="0.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3651_7423">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ErrorNotificationIcon;
