import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

// icons
import IconEmpowerment from './icons/IconEmpowerment';
import IconEnergyHealing from './icons/IconEnergyHealing';
import IconESupport from './icons/IconESupport';
import IconNutrition from './icons/IconNutrition';
import IconPersonalDev from './icons/IconPersonalDev';
import IconRelaxMethods from './icons/IconRelaxMethods';
import IconRSupport from './icons/IconRSupport';
import IconTSupport from './icons/IconTSupport';
import IconBiohacking from './icons/IconBiohacking';
import IconCeremony from './icons/IconCeremony';
import IconFitness from './icons/IconFitness';
import IconLifestyle from './icons/IconLifestyle';
import IconMindfulness from './icons/IconMindfulness';
import IconSpiritualGrowth from './icons/IconSpiritualGrowth';
import IconStressManagement from './icons/IconStressManagement';

import css from './FieldCheckbox.module.css';

export const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg
      className={classNames(className, css.rootSvg)}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <rect width="13" height="13" x="0.5" y="0.5" fill="#fff" rx="3.5"></rect>
      <rect
        width="13"
        height="13"
        x="0.5"
        y="0.5"
        stroke="#21262A"
        rx="3.5"
      ></rect>
      <g clipPath="url(#clip0_89_18932)" className={checkedClassName || css.checked}>
        <path fill="#DFFF60" d="M4 7a3 3 0 106 0 3 3 0 00-6 0z"></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.875 4.125L5.75 9.875 4.125 8.25"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_89_18932">
          <path fill="#fff" d="M0 0H6V6H0z" transform="translate(4 4)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };


const renderIcon = (id, useSuccessColor, disabled, svgClassName) => {

  const successColorVariantMaybe = useSuccessColor
    ? {
      checkedClassName: css.checkedSuccess,
      boxClassName: css.boxSuccess,
    }
    : {};
  const disabledColorMaybe = disabled
    ? {
      checkedClassName: css.checkedDisabled,
      boxClassName: css.boxDisabled,
    }
    : {};

  switch (id) {
    case 'empowerment': {
      return (<IconEmpowerment />)
    }
    case 'relationship-support': {
      return (<IconRSupport />)
    }
    case 'emotional-support': {
      return (<IconESupport />)
    }
    case 'nutrition': {
      return (<IconNutrition />)
    }
    case 'energy-healing': {
      return (<IconEnergyHealing />)
    }
    case 'personal-development': {
      return (<IconPersonalDev />)
    }
    case 'relaxation-methods': {
      return (<IconRelaxMethods />)
    }
    case 'transition-support': {
      return (<IconTSupport />)
    }
    case 'biohacking': {
      return (<IconBiohacking />)
    }
    case 'stress-management': {
      return (<IconStressManagement />)
    }
    case 'mindfulness': {
      return (<IconMindfulness />)
    }
    case 'fitness': {
      return (<IconFitness />)
    }
    case 'spiritual-growth': {
      return (<IconSpiritualGrowth />)
    }
    case 'lifestyle': {
      return (<IconLifestyle />)
    }
    case 'ceremony': {
      return (<IconCeremony />)
    }
    default:
      return ( <IconCheckbox
        className={svgClassName}
        {...successColorVariantMaybe}
        {...disabledColorMaybe}
      />)
  }
}

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    inputClassName,
    wrapperClassName,
    labelClassName,
    id,
    label,
    useSuccessColor,
    isDropdown,
    customSelect,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const wrapperClasses = classNames(wrapperClassName || css.checkboxWrapper);
  const labelClasses = classNames(labelClassName || css.label)
  const inputClasses = classNames(inputClassName, css.input || css.input)

  const successColorVariantMaybe = useSuccessColor
    ? {
      checkedClassName: css.checkedSuccess,
      boxClassName: css.boxSuccess,
    }
    : {};

  const disabledColorMaybe = rest.disabled
    ? {
      checkedClassName: css.checkedDisabled,
      boxClassName: css.boxDisabled,
    }
    : {};

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };



  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const { input, disabled } = props;
          return (
            <input
              id={id}
              className={inputClasses}
              {...input}
              onChange={event => handleOnChange(input, event)}
              disabled={disabled}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={labelClasses}>
        <span className={wrapperClasses}>
          {customSelect ? renderIcon(props.value, useSuccessColor, rest.disabled, svgClassName) :
            (<IconCheckbox
              className={svgClassName}
              {...successColorVariantMaybe}
              {...disabledColorMaybe}
            />)
          }
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
