import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../FieldCheckbox.module.css';

const IconMindfulness = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='#DFFF60' d="M1.61914 12.8474C1.61914 13.6576 2.2618 14.4347 3.40573 15.0076C4.54966 15.5805 6.10117 15.9024 7.71893 15.9024C9.3367 15.9024 10.8882 15.5805 12.0321 15.0076C13.1761 14.4347 13.8187 13.6576 13.8187 12.8474C13.8187 12.0371 13.1761 11.2601 12.0321 10.6871C10.8882 10.1142 9.3367 9.79234 7.71893 9.79234C6.10117 9.79234 4.54966 10.1142 3.40573 10.6871C2.2618 11.2601 1.61914 12.0371 1.61914 12.8474Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M4.24072 10.3375C5.08057 11.6422 7.40748 12.5809 10.1479 12.5809C11.3207 12.5911 12.4869 12.4043 13.5978 12.0281C12.8825 10.7388 10.5222 9.79234 7.72017 9.79234C6.53824 9.78112 5.36259 9.96531 4.24072 10.3375Z" fill="#DFFF60"/>
      <path fill='#DFFF60' d="M4.1875 8.67768C4.1875 9.40176 4.72868 10.0962 5.69199 10.6082C6.65531 11.1202 7.96184 11.4078 9.32417 11.4078C10.6865 11.4078 11.993 11.1202 12.9563 10.6082C13.9197 10.0962 14.4608 9.40176 14.4608 8.67768C14.4608 7.9536 13.9197 7.25918 12.9563 6.74718C11.993 6.23518 10.6865 5.94754 9.32417 5.94754C7.96184 5.94754 6.65531 6.23518 5.69199 6.74718C4.72868 7.25918 4.1875 7.9536 4.1875 8.67768Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M5.47168 4.50542C5.47168 5.14401 5.87757 5.75645 6.60005 6.208C7.32253 6.65956 8.30243 6.91324 9.32418 6.91324C10.3459 6.91324 11.3258 6.65956 12.0483 6.208C12.7708 5.75645 13.1767 5.14401 13.1767 4.50542C13.1767 3.86683 12.7708 3.25439 12.0483 2.80284C11.3258 2.35129 10.3459 2.09761 9.32418 2.09761C8.30243 2.09761 7.32253 2.35129 6.60005 2.80284C5.87757 3.25439 5.47168 3.86683 5.47168 4.50542Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M10.0735 7.90333C11.2747 7.91921 12.4626 7.65126 13.5407 7.12128C12.2914 6.30118 10.8175 5.89111 9.32416 5.94819C8.13357 5.92782 6.95316 6.17053 5.86719 6.65898C7.10152 7.51 8.5748 7.94585 10.0735 7.90333Z" fill="#DFFF60"/>
      <path fill='#DFFF60' d="M5.47168 4.50542C5.47168 5.14401 5.87757 5.75645 6.60005 6.208C7.32253 6.65956 8.30243 6.91324 9.32418 6.91324C10.3459 6.91324 11.3258 6.65956 12.0483 6.208C12.7708 5.75645 13.1767 5.14401 13.1767 4.50542C13.1767 3.86683 12.7708 3.25439 12.0483 2.80284C11.3258 2.35129 10.3459 2.09761 9.32418 2.09761C8.30243 2.09761 7.32253 2.35129 6.60005 2.80284C5.87757 3.25439 5.47168 3.86683 5.47168 4.50542Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M5.47168 4.50542C5.47168 5.14401 5.87757 5.75645 6.60005 6.208C7.32253 6.65956 8.30243 6.91324 9.32418 6.91324C10.3459 6.91324 11.3258 6.65956 12.0483 6.208C12.7708 5.75645 13.1767 5.14401 13.1767 4.50542C13.1767 3.86683 12.7708 3.25439 12.0483 2.80284C11.3258 2.35129 10.3459 2.09761 9.32418 2.09761C8.30243 2.09761 7.32253 2.35129 6.60005 2.80284C5.87757 3.25439 5.47168 3.86683 5.47168 4.50542Z" fill="white"/>
      <path fill='#DFFF60' d="M5.47168 4.50542C5.47168 5.14401 5.87757 5.75645 6.60005 6.208C7.32253 6.65956 8.30243 6.91324 9.32418 6.91324C10.3459 6.91324 11.3258 6.65956 12.0483 6.208C12.7708 5.75645 13.1767 5.14401 13.1767 4.50542C13.1767 3.86683 12.7708 3.25439 12.0483 2.80284C11.3258 2.35129 10.3459 2.09761 9.32418 2.09761C8.30243 2.09761 7.32253 2.35129 6.60005 2.80284C5.87757 3.25439 5.47168 3.86683 5.47168 4.50542Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M5.47168 4.50542C5.47181 4.64162 5.48995 4.7772 5.52561 4.90865C6.30384 5.30462 7.16693 5.50475 8.04001 5.49166C10.1672 5.49166 11.8925 4.41488 11.8925 3.08384C11.8924 2.94786 11.8742 2.81249 11.8386 2.68126C11.0605 2.28469 10.1974 2.08433 9.32418 2.0976C7.19696 2.0976 5.47168 3.17566 5.47168 4.50542Z" fill="white"/>
      <path fill='#DFFF60' d="M13.2184 6.89976C13.5607 7.06484 13.8548 7.31521 14.0724 7.6268C14.29 7.9384 14.4238 8.30074 14.4608 8.67897C14.4608 10.1866 12.1609 11.4078 9.32417 11.4078C6.48744 11.4078 4.1875 10.1879 4.1875 8.67897C4.1875 7.66897 5.21997 6.78675 6.75583 6.31482" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M5.47168 4.50542C5.47168 5.14401 5.87757 5.75645 6.60005 6.208C7.32253 6.65956 8.30243 6.91324 9.32418 6.91324C10.3459 6.91324 11.3258 6.65956 12.0483 6.208C12.7708 5.75645 13.1767 5.14401 13.1767 4.50542C13.1767 3.86683 12.7708 3.25439 12.0483 2.80284C11.3258 2.35129 10.3459 2.09761 9.32418 2.09761C8.30243 2.09761 7.32253 2.35129 6.60005 2.80284C5.87757 3.25439 5.47168 3.86683 5.47168 4.50542Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M13.542 11.9414C13.7188 12.2127 13.8148 12.5287 13.8187 12.8525C13.8187 14.5373 11.0879 15.9024 7.71893 15.9024C4.34992 15.9024 1.61914 14.5373 1.61914 12.8525C1.61914 11.6672 2.97137 10.6392 4.9477 10.1352" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M5.47168 4.50542C5.47168 5.14401 5.87757 5.75645 6.60005 6.208C7.32253 6.65956 8.30243 6.91324 9.32418 6.91324C10.3459 6.91324 11.3258 6.65956 12.0483 6.208C12.7708 5.75645 13.1767 5.14401 13.1767 4.50542C13.1767 3.86683 12.7708 3.25439 12.0483 2.80284C11.3258 2.35129 10.3459 2.09761 9.32418 2.09761C8.30243 2.09761 7.32253 2.35129 6.60005 2.80284C5.87757 3.25439 5.47168 3.86683 5.47168 4.50542Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

const { string } = PropTypes;

IconMindfulness.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMindfulness.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMindfulness;
