import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../FieldCheckbox.module.css';

const IconFitness = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='#DFFF60' d="M8.04007 15.3771L1.74766 8.89724C1.19586 8.35592 0.830713 7.653 0.705114 6.89028C0.579515 6.12757 0.699984 5.34467 1.04907 4.655C1.31832 4.13249 1.70663 3.6806 2.18266 3.33579C2.6587 2.99098 3.20913 2.76292 3.78954 2.66999C4.36994 2.57707 4.96407 2.6219 5.52396 2.80086C6.08385 2.97981 6.59383 3.28789 7.01274 3.70022L8.04007 4.63702L9.06741 3.70022C9.48626 3.28789 9.99619 2.97981 10.556 2.80085C11.1159 2.62189 11.71 2.57706 12.2903 2.66999C12.8707 2.76291 13.4211 2.99099 13.897 3.3358C14.373 3.68061 14.7613 4.1325 15.0304 4.655C15.3795 5.34463 15.5 6.12746 15.3745 6.89015C15.2491 7.65284 14.8841 8.35579 14.3325 8.89724L8.04007 15.3771Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M10.817 8.40156C10.7546 8.40172 10.6937 8.42002 10.6416 8.45422C10.5895 8.48843 10.5485 8.53706 10.5235 8.59419L9.61049 10.6489C9.58544 10.7051 9.54474 10.753 9.49323 10.7868C9.44172 10.8206 9.38159 10.8388 9.31999 10.8394C9.25839 10.8399 9.19794 10.8227 9.14585 10.7899C9.09375 10.757 9.05222 10.7098 9.02619 10.654L7.31825 6.99412C7.29416 6.9425 7.25677 6.89823 7.20992 6.86584C7.16307 6.83345 7.10845 6.8141 7.05165 6.80979C6.99486 6.80548 6.93794 6.81635 6.88674 6.8413C6.83553 6.86624 6.79189 6.90436 6.76028 6.95174L5.88769 8.25902C5.85832 8.30292 5.81858 8.33889 5.77199 8.36376C5.7254 8.38862 5.67339 8.40161 5.62058 8.40156H1.32568C1.45123 8.57886 1.59218 8.74473 1.74689 8.89725L8.03995 15.3772L14.3324 8.89725C14.4869 8.74472 14.6276 8.57885 14.7529 8.40156H10.817Z" fill="#DFFF60"/>
      <path fill='#DFFF60' d="M8.04007 15.3771L1.74766 8.89724C1.19586 8.35592 0.830713 7.653 0.705114 6.89028C0.579515 6.12757 0.699984 5.34467 1.04907 4.655C1.31832 4.13249 1.70663 3.6806 2.18266 3.33579C2.6587 2.99098 3.20913 2.76292 3.78954 2.66999C4.36994 2.57707 4.96407 2.6219 5.52396 2.80086C6.08385 2.97981 6.59383 3.28789 7.01274 3.70022L8.04007 4.63702L9.06741 3.70022C9.48626 3.28789 9.99619 2.97981 10.556 2.80085C11.1159 2.62189 11.71 2.57706 12.2903 2.66999C12.8707 2.76291 13.4211 2.99099 13.897 3.3358C14.373 3.68061 14.7613 4.1325 15.0304 4.655C15.3795 5.34463 15.5 6.12746 15.3745 6.89015C15.2491 7.65284 14.8841 8.35579 14.3325 8.89724L8.04007 15.3771Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M1.32642 8.40156H5.62067C5.67348 8.40161 5.72549 8.38862 5.77208 8.36376C5.81867 8.33889 5.85841 8.30292 5.88778 8.25902L6.76037 6.95046C6.79198 6.90308 6.83562 6.86496 6.88683 6.84001C6.93803 6.81507 6.99495 6.8042 7.05174 6.80851C7.10854 6.81282 7.16316 6.83217 7.21001 6.86456C7.25686 6.89695 7.29425 6.94122 7.31834 6.99283L9.02628 10.6527C9.05231 10.7085 9.09384 10.7557 9.14594 10.7886C9.19803 10.8214 9.25848 10.8386 9.32008 10.8381C9.38168 10.8376 9.44181 10.8193 9.49332 10.7855C9.54483 10.7517 9.58553 10.7039 9.61057 10.6476L10.5236 8.59291C10.5485 8.53578 10.5896 8.48715 10.6417 8.45294C10.6938 8.41873 10.7547 8.40044 10.817 8.40028H14.7537" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

const { string } = PropTypes;

IconFitness.defaultProps = {
  className: null,
  rootClassName: null,
};

IconFitness.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconFitness;
