import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconClose.module.css';
const SIZE_SMALL = 'small';
const SIZE_BIG = 'big';

const IconClose = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === SIZE_BIG) {
    return (
      <svg
        onClick={onClick}
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <g clipPath="url(#clip0_2109_57984)">
          <path
            fill="#FF5A5F"
            d="M.98 12a11.02 11.02 0 1022.039 0A11.02 11.02 0 00.98 12z"
          ></path>
          <path
            fill="#F0C5C7"
            d="M4.208 19.791A11.019 11.019 0 0119.79 4.21L4.208 19.79z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M.98 12a11.02 11.02 0 1022.039 0A11.02 11.02 0 00.98 12zM7.21 7.21l9.582 9.58M16.792 7.21l-9.581 9.58"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_2109_57984">
            <path fill="#fff" d="M0 0H24V24H0z"></path>
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (size === SIZE_SMALL) {
    return (
      <svg onClick={onClick} className={classes} width="9" height="9" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.175 8.396l2.482-2.482 2.482 2.482a.889.889 0 1 0 1.258-1.257L5.914 4.657l2.482-2.483A.89.89 0 0 0 7.139.917L4.657 3.4 2.175.918A.888.888 0 1 0 .917 2.174L3.4 4.657.918 7.139a.889.889 0 1 0 1.257 1.257"
          fillRule="evenodd"
        />
      </svg>
    );
  }

  return (
    <svg
      onClick={onClick}
      className={classes}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-1 -1)" fillRule="evenodd">
        <rect transform="rotate(45 7 7)" x="-1" y="6" width="16" height="2" rx="1" />
        <rect transform="rotate(-45 7 7)" x="-1" y="6" width="16" height="2" rx="1" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconClose.defaultProps = {
  className: null,
  rootClassName: null,
};

IconClose.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconClose;
