import React, { Component } from 'react';
import { array, arrayOf, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { parseSelectFilterOptions } from '../../util/search';

import { FieldCheckbox } from '../../components';

import FilterPlain from '../../containers/SearchPage/FilterPlain/FilterPlain';
import FilterPopup from '../../containers/SearchPage/FilterPopup/FilterPopup';
import OptionsSearchForm from './OptionsSearchForm';

import css from './SelectMultipleWithInputFilter.module.css';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
const GroupOfFieldCheckboxes = props => {
  const {
    id,
    className,
    name,
    options,
    twoColums,
    label,
    handleInputChange,
    searchValue
  } = props;

  const filteredOptions = searchValue?.length > 1
    && options?.filter(o => o.option.indexOf(searchValue) !== -1) || options;

  return (
    <fieldset className={className}>

      <input
        type="text"
        className={css.optionsInput}
        id='optionString'
        name='optionString'
        placeholder={label}
        value={searchValue}
        onChange={handleInputChange}
      />

      <ul className={classNames(css.list, { [css.listTwoColums]: twoColums })}>
        {filteredOptions.map(optionConfig => {
          const { option, label } = optionConfig;
          const fieldId = `${id}.${option}`;
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox id={fieldId} name={name} label={label} value={option} />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamName, schemaType, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = schemaType === 'multi-enum' && searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;

  return { [queryParamName]: value };
};

class SelectMultipleWithInputFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;

    this.state = {
      options: this.props.options,
      hideOptions: true,
      isOpen: false,
      searchValue: ''
    };


    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }


  handleOptionsSearch = (val) => {
    const { options } = this.props;
    const optionString = val?.optionString || '';

    if (optionString !== undefined) {
      const foundOptions = options.filter(o => {
        return o?.option?.includes(optionString);
      }).sort(function (a, b) {
        if (a.option < b.option) return -1;
        if (a.option > b.option) return 1;
        return 0;
      });


      if (foundOptions?.length > 0) {
        this.setState({
          options: foundOptions,
        });
      }
    }
  };

  handleSubmit(values) {
    const { onSubmit, queryParamNames, name, schemaType, searchMode } = this.props;
    this.setState({ isOpen: false });
    const queryParamName = getQueryParamName(queryParamNames);
    const usedValue = values ? values[name] : values;
    onSubmit(format(usedValue, queryParamName, schemaType, searchMode));
  }

  handleClear() {
    const { onSubmit, onClear } = this.props;
    this.setState({ isOpen: false });

    if (onClear) {
      onClear();
    }

    onSubmit(null);
  }

  handleCancel() {
    const { onSubmit, onCancel, initialValues } = this.props;
    this.setState({ isOpen: false });

    if (onCancel) {
      onCancel();
    }

    onSubmit(initialValues);
  }

  handleInputChange(event) {
    this.setState({ searchValue: event.target.value }, () => {
      this.handleOptionsSearch();
    });
  }


  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      placeholder,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      schemaType,
      searchMode,
      intl,
      showAsPopup,
      ...rest
    } = this.props;

    const techniqueTypesInitialValues = {
      techniqueTypes: initialValues?.pub_techniqueTypes?.split(',')
    }

    const classes = classNames(rootClassName || css.root, className);

    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    const selectedOptions = hasInitialValues
      ? parseSelectFilterOptions(initialValues[queryParamName])
      : [];

    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
        { id: 'SelectMultipleFilter.labelSelected' },
        { labelText: label, count: selectedOptions.length }
      )
      : label;

    const labelSelectionForPlain = hasInitialValues
      ? intl.formatMessage(
        { id: 'SelectMultipleFilterPlainForm.labelSelected' },
        { count: selectedOptions.length }
      )
      : '';

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: selectedOptions };

    const handleSubmit = values => {
      const usedValue = values ? values[name] : values;
      onSubmit(format(usedValue, queryParamName, schemaType, searchMode));
    };

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

    if (isMobileLayout) {

      return (
        <FilterPlain
          className={className}
          rootClassName={rootClassName}
          label={label}
          labelSelection={labelSelectionForPlain}
          isSelected={hasInitialValues}
          id={`${id}.plain`}
          liveEdit
          onSubmit={handleSubmit}
          initialValues={namedInitialValues}
          onClearInputValues={() => this.setState({ searchValue: '' })}
          {...rest}
        >
          <GroupOfFieldCheckboxes
            className={css.fieldGroupPlain}
            name={name}
            id={`${id}-checkbox-group`}
            options={options}
            twoColums={options?.length > 5}
            label={label}
            handleInputChange={this.handleInputChange}
            searchValue={this.state.searchValue}
          />
        </FilterPlain>
      )
    } else {

      return (

        <FilterPlain
          className={className}
          rootClassName={rootClassName}
          label={label}
          labelSelection={labelSelectionForPlain}
          isSelected={hasInitialValues}
          id={`${id}.plain`}
          liveEdit
          onSubmit={onSubmit}
          initialValues={namedInitialValues}
          {...rest}
        >
          <OptionsSearchForm
            handleOptionsSearch={this.handleOptionsSearch}
            initialValues={techniqueTypesInitialValues}
            placeholder={placeholder}
            name={name}
            id={`${id}-checkbox-group`}
            options={this.state.options}
            intl={intl}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
            onClear={this.handleClear}
            isOpen={this.state.isOpen}
            onToggleDropdown={(state) => this.setState({ isOpen: state })}
            isMobileLayout={isMobileLayout}

            // onSubmitMobile={onSubmit}
            {...rest}
          />
        </FilterPlain>
      )
    }

  }
}

SelectMultipleWithInputFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectMultipleWithInputFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SelectMultipleWithInputFilter);
