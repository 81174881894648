import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from '../FieldCheckbox.module.css';

const IconESupport = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (

    <svg className={classes} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2746_4401)">
        <path d="M8.1598 15.346C8.09387 15.3459 8.02968 15.3249 7.97648 15.286C7.25086 14.7654 4.5598 12.7495 3.33915 10.6352C1.90005 7.62544 3.19352 5.77179 5.32247 5.49649C5.94365 5.45477 6.56086 5.6227 7.07528 5.97337C7.58971 6.32404 7.97159 6.83717 8.1598 7.43062C8.348 6.83717 8.72989 6.32404 9.24431 5.97337C9.75874 5.6227 10.3759 5.45477 10.9971 5.49649C13.1261 5.77179 14.4195 7.62544 12.9804 10.6352C11.7598 12.7495 9.06874 14.7654 8.34312 15.286C8.28992 15.3249 8.22572 15.3459 8.1598 15.346Z" fill="#DFFF60"/>
        <path d="M10.9972 5.49649C10.3761 5.45477 9.75884 5.6227 9.24442 5.97337C8.72999 6.32404 8.34811 6.83717 8.1599 7.43062C7.9717 6.83717 7.58981 6.32404 7.07539 5.97337C6.56096 5.6227 5.94375 5.45477 5.32258 5.49649C3.42932 5.74177 2.20164 7.23708 2.96495 9.68158C4.49064 10.7371 6.30475 11.2963 8.1599 11.2829C10.0151 11.2963 11.8292 10.7371 13.3549 9.68158C14.1182 7.23708 12.8905 5.74177 10.9972 5.49649Z" fill="#F7FFD6"/>
        <path d="M10.9972 5.49649C10.376 5.45477 9.75878 5.6227 9.24435 5.97337C8.72993 6.32404 8.34804 6.83717 8.15984 7.43062C7.97163 6.83717 7.58975 6.32404 7.07532 5.97337C6.5609 5.6227 5.94369 5.45477 5.32251 5.49649" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.41117 0.654297H5.60492C5.60492 0.654297 5.9243 0.654297 5.9243 0.973672V1.61242C5.9243 1.61242 5.9243 1.9318 5.60492 1.9318H2.41117C2.41117 1.9318 2.0918 1.9318 2.0918 1.61242V0.973672C2.0918 0.973672 2.0918 0.654297 2.41117 0.654297Z" fill="#DFFF60" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.9086 1.9318H10.7149C10.7149 1.9318 10.3955 1.9318 10.3955 1.61242V0.973672C10.3955 0.973672 10.3955 0.654297 10.7149 0.654297H13.9086C13.9086 0.654297 14.228 0.654297 14.228 0.973672V1.61242C14.228 1.61242 14.228 1.9318 13.9086 1.9318Z" fill="#DFFF60" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.68858 4.64633L2.82499 6.08352C2.72754 6.2785 2.70465 6.50236 2.76064 6.71302C2.81663 6.92369 2.94764 7.10665 3.12904 7.22752C3.22972 7.29452 3.34295 7.34041 3.46188 7.3624C3.5808 7.38439 3.70296 7.38203 3.82094 7.35545C3.93893 7.32888 4.0503 7.27866 4.14832 7.20781C4.24634 7.13696 4.32897 7.04697 4.39121 6.94327L5.1213 5.84973C5.43682 5.37744 5.6051 4.82213 5.60483 4.25414V1.93164H2.98596L1.44402 3.85875C1.03619 4.36857 0.814066 5.00204 0.814209 5.65491V8.95789C0.814209 9.212 0.915154 9.4557 1.09484 9.63539C1.27452 9.81507 1.51822 9.91602 1.77233 9.91602C2.02644 9.91602 2.27015 9.81507 2.44983 9.63539C2.62951 9.4557 2.73046 9.212 2.73046 8.95789V6.48273" fill="#F7FFD6"/>
        <path fill='#DFFF60' d="M3.68858 4.64633L2.82499 6.08352C2.72754 6.2785 2.70465 6.50236 2.76064 6.71302C2.81663 6.92369 2.94764 7.10665 3.12904 7.22752C3.22972 7.29452 3.34295 7.34041 3.46188 7.3624C3.5808 7.38439 3.70295 7.38203 3.82094 7.35545C3.93893 7.32888 4.0503 7.27866 4.14832 7.20781C4.24634 7.13696 4.32897 7.04697 4.39121 6.94327L5.1213 5.84973C5.43682 5.37744 5.6051 4.82213 5.60483 4.25414V1.93164H2.98596L1.44402 3.85875C1.03619 4.36857 0.814066 5.00204 0.814209 5.65491V8.95789C0.814209 9.212 0.915154 9.4557 1.09484 9.63539C1.27452 9.81507 1.51822 9.91602 1.77233 9.91602C2.02644 9.91602 2.27015 9.81507 2.44983 9.63539C2.62951 9.4557 2.73046 9.212 2.73046 8.95789V6.48273" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill='#DFFF60' d="M12.6311 4.64633L13.4947 6.08352C13.5921 6.2785 13.615 6.50236 13.559 6.71302C13.503 6.92369 13.372 7.10665 13.1906 7.22752C13.0899 7.29463 12.9767 7.3406 12.8577 7.36264C12.7387 7.38469 12.6164 7.38235 12.4984 7.35578C12.3803 7.32921 12.2689 7.27894 12.1708 7.20804C12.0727 7.13713 11.9901 7.04706 11.9278 6.94327L11.1984 5.84973C10.8829 5.37744 10.7146 4.82213 10.7148 4.25414V1.93164H13.3337L14.8757 3.85875C15.2835 4.36857 15.5056 5.00204 15.5055 5.65491V8.95789C15.5055 9.212 15.4045 9.4557 15.2248 9.63539C15.0452 9.81507 14.8015 9.91602 14.5473 9.91602C14.2932 9.91602 14.0495 9.81507 13.8698 9.63539C13.6902 9.4557 13.5892 9.212 13.5892 8.95789V6.48273" />
        <path fill='#DFFF60' d="M12.6311 4.64633L13.4947 6.08352C13.5921 6.2785 13.615 6.50236 13.559 6.71302C13.503 6.92369 13.372 7.10665 13.1906 7.22752C13.0899 7.29463 12.9767 7.3406 12.8577 7.36264C12.7387 7.38469 12.6164 7.38235 12.4984 7.35578C12.3803 7.3292 12.2689 7.27894 12.1708 7.20804C12.0727 7.13713 11.9901 7.04706 11.9278 6.94327L11.1984 5.84973C10.8829 5.37744 10.7146 4.82213 10.7148 4.25414V1.93164H13.3337L14.8757 3.85875C15.2835 4.36857 15.5056 5.00204 15.5055 5.65491V8.95789C15.5055 9.212 15.4045 9.4557 15.2248 9.63539C15.0452 9.81507 14.8015 9.91602 14.5473 9.91602C14.2932 9.91602 14.0495 9.81507 13.8698 9.63539C13.6902 9.4557 13.5892 9.212 13.5892 8.95789V6.48273" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill='#DFFF60' d="M12.9806 10.6348C11.76 12.749 9.06894 14.7649 8.34332 15.2855C8.29017 15.3245 8.22594 15.3456 8.16 15.3456C8.09406 15.3456 8.02984 15.3245 7.97668 15.2855C7.25106 14.7649 4.56001 12.749 3.33936 10.6348" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2746_4401">
          <rect width="16" height="16" fill="white" transform="translate(0.159912)"/>
        </clipPath>
      </defs>
    </svg>


  );
};

const { string } = PropTypes;

IconESupport.defaultProps = {
  className: null,
  rootClassName: null,
};

IconESupport.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconESupport;
