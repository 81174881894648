import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconFlower.module.css';

const IconFlower = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      fill="none"
      viewBox="0 0 33 33"
    >
      <path
        fill="#DFFF60"
        d="M27.37 20.178a11.199 11.199 0 00-11.2 11.2 11.199 11.199 0 0011.2-11.2z"
      ></path>
      <path
        fill="#DFFF60"
        d="M5.63 20.837a10.54 10.54 0 0110.54 10.54 10.54 10.54 0 01-10.54-10.54z"
      ></path>
      <path
        fill="#F7FFD6"
        d="M27.37 20.178a11.199 11.199 0 00-11.2 11.2l11.2-11.2z"
      ></path>
      <path
        fill="#F7FFD6"
        d="M5.63 20.837a10.54 10.54 0 0110.54 10.54L5.63 20.837z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38"
        d="M16.17 31.377V18.861M27.37 20.178a11.199 11.199 0 00-11.2 11.2 11.199 11.199 0 0011.2-11.2z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38"
        d="M5.63 20.837a10.54 10.54 0 0110.54 10.54 10.54 10.54 0 01-10.54-10.54z"
      ></path>
      <path
        fill="#F7FFD6"
        d="M25.393 9.638a9.222 9.222 0 11-18.445 0V2.392c0-.724.42-.898.932-.386l3.68 3.68 3.68-3.679a1.317 1.317 0 011.862 0l3.68 3.679 3.68-3.679c.512-.512.931-.338.931.386v7.245z"
      ></path>
      <path
        fill="#DFFF60"
        d="M16.17 13.462a9.223 9.223 0 01-9.222-9.223v5.402a9.222 9.222 0 1018.445 0V4.24a9.222 9.222 0 01-9.222 9.223z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38"
        d="M6.948 2.392c0-.724.42-.898.932-.386l3.68 3.68 3.68-3.679a1.317 1.317 0 011.862 0l3.68 3.679 3.68-3.679c.512-.512.931-.338.931.386V9.64a9.222 9.222 0 11-18.445 0V2.393"
      ></path>
    </svg>
  )
};

IconFlower.defaultProps = { className: null };

IconFlower.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconFlower;
