import AccessBars from './images/Access-Bars.png';
import ActiveMeditation from './images/Active-meditation.png';
import Acupressure from './images/Acupressure.png';
import Acupuncture from './images/Acupuncture.png';
import AkashaReading from './images/Akasha-reading.png';
import AlexanderTechnique from './images/Alexander-technique.png';
import AnimalAssistedTherapy from './images/Animal-assisted-therapy.png';
import Apitherapy from './images/Apitherapy.png';
import Aromatherapy from './images/Aromatherapy.png';
import ArtTherapy from './images/Art-therapy.png';
import AshtangaYoga from './images/Ashtanga-yoga.png';
import AssistedStretching from './images/Assisted-stretching.png';
import Astrology from './images/Astrology.png';
import Auriculotherapy from './images/Auriculotherapy.png';
import AutogenicTraining from './images/Autogenic-training.png';
import Autosuggestion from './images/Autosuggestion.png';
import Ayurveda from './images/Ayurveda.png';
import BachFlowerTherapy from './images/Bach-flower-therapy.png';
import BikramYoga from './images/Bikram-yoga.png';
import BioMagneticTherapy from './images/Bio-magnetic-therapy.png';
import BiodynamicCraniosacralTherapy from './images/Biodynamic-craniosacral-therapy.png';
import BiofieldEnergy from './images/Biofield-energy.png';
import BodyAlignment from './images/Body-alignment.png';
import BodyTalk from './images/BodyTalk.png';
import BowenTherapy from './images/Bowen-therapy.png';
import BodyworkDeArmouring from './images/Bodywork_De_Armouring.png';
import Breathwork from './images/Breathwork.png';
import CacaoCeremony from './images/Cacao-ceremony.png';
import CanineTherapy from './images/Canine-therapy.png';
import ChakraHealing from './images/Chakra-healing.png';
import ChildbirthPreparation from './images/Childbirth-preparation.jpg';
import ChildrenYoga from './images/Children-yoga.png';
import Chiropractic from './images/Chiropractic.png';
import Chromotherapy from './images/Chromotherapy.png';
import CircularBreathing from './images/Circular-breathing.png';
import Coaching from './images/Coaching.png';
import ConflictCoaching from './images/Conflict-coaching.jpg';
import ConflictMediation from './images/Conflict-mediation.jpg';
import ContactHealing from './images/Contact-healing.png';
import CoupleMediation from './images/Couple-mediation.jpg';
import CouplesTherapy from './images/Couples-therapy.png';
import CraniosacralTherapy from './images/Craniosacral-therapy.png';
import CrystalHealing from './images/Crystal-healing.png';
import CrystalSoundBowlHealing from './images/Crystal-sound-bowl-healing.png';
import CuppingTherapy from './images/Cupping-therapy.png';
import DanceTherapy from './images/Dance-therapy.png';
import Detoxification from './images/Detoxification.png';
import DogTherapy from './images/Dog-therapy.png';
import Doula from './images/Doula.png';
import EFTEmotionalFreedomTechniques from './images/EFT-emotional-freedom-techniques.png';
import EmbodiedDance from './images/Embodied-dance.png';
import EMDREyeMovementDesensitizationAndReprocessing
  from './images/EMDR-eye-movement-desensitization-and-reprocessing.png';
import EmotionCode from './images/Emotion-code.png';
import EmotionalRelease from './images/Emotional-release.png';
import EnergyHealing from './images/Energy-healing.png';
import EquineTherapyWithHorses from './images/Equine-therapy-with-horses.png';
import FamilyConstellation from './images/Family-constellation.png';
import FamilyMediation from './images/Family-mediation.jpg';
import FasciaYoga from './images/Fascia-yoga.png';
import FeldenkraisMethod from './images/Feldenkrais-method.png';
import FengShui from './images/Feng-shui.png';
import FertilityCoaching from './images/Fertility-coaching.png';
import FlowerEssences from './images/Flower-essences.png';
import FrequencyHealing from './images/Frequency-healing.png';
import FunctionalMedicine from './images/Functional-medicine.png';
import GestaltTherapy from './images/Gestalt-therapy.jpg';
import GrinbergMethod from './images/Grinberg-method.png';
import HathaYoga from './images/Hatha-yoga.png';
import HealingTouch from './images/Healing-touch.png';
import HealthCoaching from './images/Health-coaching.png';
import HeartMath from './images/HeartMath.png';
import Herbalism from './images/Herbalism.png';
import Hippotherapy from './images/Hippotherapy.png';
import HolisticVisionCare from './images/Holistic-vision-care.png';
import Homeopathy from './images/Homeopathy.png';
import HumanDesign from './images/Human-design.png';
import Hydrotherapy from './images/Hydrotherapy.png';
import HypnoBirthing from './images/HypnoBirthing.jpg';
import Hypnotherapy from './images/Hypnotherapy.png';
import ILMInnerLightMeditation from './images/ILM-inner-light-meditation.png';
import IntegrativeHealing from './images/Integrative-healing.png';
import IntuitiveReading from './images/Intuitive-reading.png';
import IyengarYoga from './images/Iyengar-yoga.png';
import Kinesiology from './images/Kinesiology.png';
import KundaliniActivation from './images/Kundalini-activation.png';
import KundaliniYoga from './images/Kundalini-yoga.png';
import LifeCoaching from './images/Life-coaching.png';
import LomiLomiMassage from './images/Lomi-lomi-massage.png';
import MagnetTherapy from './images/Magnet-therapy.png';
import Marconics from './images/Marconics.png';
import MartialArts from './images/Martial-arts.png';
import MassageTherapy from './images/Massage-therapy.png';
import MedicalIntuitive from './images/Medical-intuitive.png';
import MedicineWheel from './images/Medicine-wheel.png';
import Meditation from './images/Meditation.png';
import MenstrualCycleCoach from './images/Menstrual-cycle-coach.png';
import MetaMedicine from './images/Meta-medicine.png';
import Mindfulness from './images/Mindfulness.png';
import MusicTherapy from './images/Music-therapy.png';
import NaturopathicMedicine from './images/Naturopathic-medicine.png';
import NeuroSystemicIntegration from './images/Neuro-systemic-integration.png';
import NeuroTherapy from './images/Neuro-therapy.png';
import NLPNeuroLinguisticProgramming from './images/NLP-neuro-linguistic-programming.png';
import Nutrition from './images/Nutrition.png';
import NutritionalTherapy from './images/Nutritional-therapy.png';
import Osteomyology from './images/Osteomyology.png';
import Osteopathy from './images/Osteopathy.png';
import PantareiApproach from './images/Pantarei-approach.png';
import PastLifeRegression from './images/Past-life-regression.png';
import PhysicalTherapy from './images/Physical-therapy.png';
import Physiotherapy from './images/Physiotherapy.png';
import Pilates from './images/Pilates.png';
import PlantMedicine from './images/Plant-medicine.png';
import PolarityTherapy from './images/Polarity-therapy.png';
import PranicHealing from './images/Pranic-healing.png';
import PrenatalYoga from './images/Prenatal-yoga.png';
import PSYCHK from './images/PSYCH-K.png';
import PsychicReading from './images/Psychic-reading.png';
import Psychotherapy from './images/Psychotherapy.png';
import Qigong from './images/Qigong.png';
import QuantumHealing from './images/Quantum-healing.png';
import QuantumTouch from './images/Quantum-touch.png';
import Radionics from './images/Radionics.png';
import RapCeremony from './images/Rapé-ceremony.png';
import ReBirthing from './images/Re-birthing.png';
import Rebalancing from './images/Rebalancing.png';
import Reflexology from './images/Reflexology.png';
import Reiki from './images/Reiki.png';
import RestorativeYoga from './images/Restorative-yoga.png';
import RTTRapidTransformationalTherapy from './images/RTT-rapid-transformational-therapy.png';
import SaltTherapy from './images/Salt-therapy.png';
import SensoryHealing from './images/Sensory-healing.png';
import SexTherapyAndHealing from './images/Sex-therapy-and-healing.png';
import SexologicalBodywork from './images/Sexological-bodywork.jpg';
import SexualityCoaching from './images/Sexuality-coaching.png';
import ShadowWork from './images/Shadow-work.png';
import Shamanism from './images/Shamanism.png';
import Shiatsu from './images/Shiatsu.png';
import ShivaShaktiYoga from './images/Shiva-shakti-yoga.png';
import SiddhaMedicine from './images/Siddha-medicine.png';
import SomaticBodywork from './images/Somatic-bodywork.png';
import SomaticCoaching from './images/Somatic-coaching.png';
import SomaticMovements from './images/Somatic-movements.png';
import SomaticTherapy from './images/Somatic-therapy.png';
import Sonopuncture from './images/Sonopuncture.png';
import Sophrology from './images/Sophrology.png';
import SoundTherapy from './images/Sound-therapy.png';
import SpiritualHealing from './images/Spiritual-healing.png';
import SpiritualMindTreatment from './images/Spiritual-mind-treatment.png';
import Spirituality from './images/Spirituality.png';
import SweatLodge from './images/Sweat-lodge.png';
import SystemicCoaching from './images/Systemic-coaching.png';
import TaiChi from './images/Tai-chi.png';
import Tantra from './images/Tantra.png';
import TantricYoga from './images/Tantric-yoga.png';
import Tarot from './images/Tarot.jpg';
import ThaiBodywork from './images/Thai-bodywork.png';
import TherapeuticTouch from './images/Therapeutic-touch.png';
import ThetaHealing from './images/Theta-healing.png';
import ThoughtFieldTherapy from './images/Thought-field-therapy.png';
import TraditionalChineseMedicine from './images/Traditional-chinese-medicine.png';
import TraditionalJapaneseMedicine from './images/Traditional-japanese-medicine.png';
import TraditionalKoreanMedicine from './images/Traditional-korean-medicine.png';
import TraditionalMongolianMedicine from './images/Traditional-mongolian-medicine.png';
import TraditionalTibetanMedicine from './images/Traditional-tibetan-medicine.png';
import TranscendentalMeditation from './images/Transcendental-meditation.png';
import TransformativeCoaching from './images/Transformative-coaching.png';
import TraumaSensitiveYoga from './images/Trauma-sensitive-yoga.png';
import Tuina from './images/Tuina.png';
import VinyasaYoga from './images/Vinyasa-yoga.png';
import Vipassana from './images/Vipassana.png';
import VoiceTherapy from './images/Voice-therapy.png';
import VortexHealing from './images/Vortex-healing.png';
import WaterTherapy from './images/Water-therapy.png';
import WellnessCoaching from './images/Wellness-coaching.png';
import YinYoga from './images/Yin-yoga.png';
import YogaNidra from './images/Yoga-nidra.png';
import YogaRatna from './images/Yoga-ratna.png';
import YogaTherapy from './images/Yoga-therapy.png';
import Yoga from './images/Yoga.png';

export const treatmentsData = [
  {
    filterId: 'techniqueTypes',
    image: AccessBars,
    option: 'access-bars',
    label: 'Access Bars',
    description: 'A gentle energy healing technique involving touching 32 points on the head to release stored thoughts, emotions, and beliefs, promoting relaxation and mental clarity.',
  },
  {
    filterId: 'techniqueTypes',
    image: ActiveMeditation,
    option: 'active-meditation',
    label: 'Active Meditation',
    description: 'Combining physical movement, such as walking or dancing, with mindfulness practices to increase awareness, reduce stress, and promote mental and emotional well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: Acupressure,
    option: 'acupressure',
    label: 'Acupressure',
    description: 'Applying finger pressure to specific points on the body to relieve pain, reduce stress, and promote energy flow, enhancing overall health and wellness.',
  },
  {
    filterId: 'techniqueTypes',
    image: Acupuncture,
    option: 'acupuncture',
    label: 'Acupuncture',
    description: 'An ancient Chinese medical practice involving the insertion of fine needles into specific points on the body to balance energy flow, relieve pain, and promote overall health.',
  },
  {
    filterId: 'techniqueTypes',
    image: AkashaReading,
    option: 'akasha-reading',
    label: 'Akasha reading',
    description: 'Accessing the Akashic records, a metaphysical compendium of all universal events and experiences, for spiritual guidance, personal growth, and self-awareness.',
  },
  {
    filterId: 'techniqueTypes',
    image: AlexanderTechnique,
    option: 'alexander-technique',
    label: 'Alexander Technique',
    description: 'A method focused on improving posture, movement, and body mechanics to alleviate tension, reduce pain, and enhance overall physical functioning and well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: AnimalAssistedTherapy,
    option: 'animal-assisted-therapy',
    label: 'Animal Assisted Therapy',
    description: 'Incorporating animals into therapeutic settings to improve emotional, cognitive, and social functioning, providing comfort, reducing anxiety, and enhancing overall mental health.',
  },
  {
    filterId: 'techniqueTypes',
    image: Apitherapy,
    option: 'apitherapy',
    label: 'Apitherapy',
    description: 'Using bee products, including honey, propolis, and venom, to treat various medical conditions, boost immunity, and promote healing and overall well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: Aromatherapy,
    option: 'aromatherapy',
    label: 'Aromatherapy',
    description: 'Utilizing essential oils extracted from plants to enhance physical and emotional health, reduce stress, improve mood, and promote relaxation and healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: ArtTherapy,
    option: 'art-therapy',
    label: 'Art Therapy',
    description: 'Using the creative process of making art to improve mental, emotional, and psychological well-being, facilitating self-expression and emotional healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: AshtangaYoga,
    option: 'ashtanga-yoga',
    label: 'Ashtanga Yoga',
    description: 'A vigorous style of yoga involving a set sequence of postures synchronized with breath, designed to build strength, flexibility, and mental clarity.',
  },
  {
    filterId: 'techniqueTypes',
    image: AssistedStretching,
    option: 'assisted-stretching',
    label: 'Assisted Stretching',
    description: 'Partner-based stretching techniques to improve flexibility, increase range of motion, and enhance physical performance, often used in physical therapy and sports training.',
  },
  {
    filterId: 'techniqueTypes',
    image: Astrology,
    option: 'astrology',
    label: 'Astrology',
    description: 'Interpreting the positions and movements of celestial bodies to gain insights into personality, relationships, and future events, offering guidance and self-awareness.',
  },
  {
    filterId: 'techniqueTypes',
    image: Auriculotherapy,
    option: 'auriculotherapy',
    label: 'Auriculotherapy',
    description: 'Stimulating specific points on the ear, believed to correspond to different body parts, to treat various health conditions and promote overall wellness.',
  },
  {
    filterId: 'techniqueTypes',
    image: AutogenicTraining,
    option: 'autogenic-training',
    label: 'Autogenic Training',
    description: 'A relaxation technique involving self-suggestion and visualization to induce a state of deep relaxation, reduce stress, and enhance mental and physical health.',
  },
  {
    filterId: 'techniqueTypes',
    image: Autosuggestion,
    option: 'autosuggestion',
    label: 'Autosuggestion',
    description: 'Using positive affirmations and mental repetition to influence the subconscious mind, promote self-improvement, and achieve personal goals and well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: Ayurveda,
    option: 'ayurveda',
    label: 'Ayurveda',
    description: 'An ancient Indian holistic healing system that balances the body\'s energies (doshas) through diet, lifestyle, and natural therapies to promote health and prevent disease.',
  },
  {
    filterId: 'techniqueTypes',
    image: BachFlowerTherapy,
    option: 'bach-flower-therapy',
    label: 'Bach Flower Therapy',
    description: 'Using natural flower essences to address emotional imbalances and stress, enhancing emotional well-being and supporting mental health through gentle, natural remedies.',
  },
  {
    filterId: 'techniqueTypes',
    image: BikramYoga,
    option: 'bikram-yoga',
    label: 'Bikram Yoga',
    description: 'A series of 26 yoga postures practiced in a heated room to detoxify the body, improve flexibility, and enhance physical and mental endurance.',
  },
  {
    filterId: 'techniqueTypes',
    image: BioMagneticTherapy,
    option: 'bio-magnetic-therapy',
    label: 'Bio-magnetic Therapy',
    description: 'Applying magnets to the body to improve circulation, reduce pain, and promote healing by balancing the body\'s electromagnetic fields.',
  },
  {
    filterId: 'techniqueTypes',
    image: BiodynamicCraniosacralTherapy,
    option: 'biodynamic-craniosacral-therapy',
    label: 'Biodynamic Craniosacral Therapy',
    description: 'A gentle, hands-on approach focusing on the body\'s craniosacral system to release tension, improve fluid movement, and enhance overall health.',
  },
  {
    filterId: 'techniqueTypes',
    image: BiofieldEnergy,
    option: 'biofield-energy',
    label: 'Biofield Energy',
    description: 'Healing practices that work with the body\'s energy fields, such as Reiki or Therapeutic Touch, to promote balance, reduce stress, and enhance well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: BodyAlignment,
    option: 'body-alignment',
    label: 'Body Alignment',
    description: 'Techniques focused on correcting posture and alignment to improve bodily function, reduce pain, and enhance overall health and wellness.',
  },
  {
    filterId: 'techniqueTypes',
    image: BodyTalk,
    option: 'body-talk',
    label: 'BodyTalk',
    description: 'A holistic therapy combining Western medicine and Eastern philosophies to restore communication within the body, addressing physical, emotional, and spiritual imbalances.',
  },
  {
    filterId: 'techniqueTypes',
    image: BowenTherapy,
    option: 'bowen-therapy',
    label: 'Bowen Therapy',
    description: 'A gentle, hands-on therapy using precise rolling moves over muscles, tendons, and fascia to stimulate healing and pain relief.',
  },
  {
    filterId: 'techniqueTypes',
    image: BodyworkDeArmouring,
    option: 'bodywork-de-armouring',
    label: 'Bodywork De Armouring',
    description: 'Bodywork De-Armouring is a technique that releases physical and emotional tension from the body using deep tissue massage, breathwork, and other practices for holistic healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: Breathwork,
    option: 'breathwork',
    label: 'Breathwork',
    description: 'Conscious breathing techniques to improve mental, emotional, and physical well-being, reducing stress and enhancing relaxation.',
  },
  {
    filterId: 'techniqueTypes',
    image: CacaoCeremony,
    option: 'cacao-ceremony',
    label: 'Cacao Ceremony',
    description: 'A spiritual practice involving the consumption of cacao in a ritual setting to open the heart, enhance meditation, and promote emotional healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: CanineTherapy,
    option: 'canine-therapy',
    label: 'Canine Therapy',
    description: 'Utilizing trained dogs to support emotional and psychological well-being, reducing stress, anxiety, and depression.',
  },
  {
    filterId: 'techniqueTypes',
    image: ChakraHealing,
    option: 'chakra-healing',
    label: 'Chakra Healing',
    description: 'Balancing the body\'s energy centers (chakras) through various techniques to promote physical, emotional, and spiritual health.',
  },
  {
    filterId: 'techniqueTypes',
    image: ChildbirthPreparation,
    option: 'childbirth-preparation',
    label: 'Childbirth Preparation',
    description: 'Provides education and techniques to help expectant parents prepare physically and mentally for labor and delivery.',
  },
  {
    filterId: 'techniqueTypes',
    image: ChildrenYoga,
    option: 'children-yoga',
    label: 'Children Yoga',
    description: 'Yoga practices tailored for children to enhance physical fitness, emotional resilience, and mindfulness.',
  },
  {
    filterId: 'techniqueTypes',
    image: Chiropractic,
    option: 'chiropractic',
    label: 'Chiropractic',
    description: 'A health profession focused on diagnosing and treating musculoskeletal disorders through manual adjustments, particularly of the spine.',
  },
  {
    filterId: 'techniqueTypes',
    image: Chromotherapy,
    option: 'chromotherapy',
    label: 'Chromotherapy',
    description: 'Using colored light and visualizations to balance physical, emotional, and spiritual energies, promoting healing and well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: CircularBreathing,
    option: 'circular-breathing',
    label: 'Circular Breathing',
    description: 'A breathing technique used in meditation and music performance to maintain a continuous flow of breath, enhancing relaxation and focus.',
  },
  {
    filterId: 'techniqueTypes',
    image: Coaching,
    option: 'coaching',
    label: 'Coaching',
    description: 'Guiding individuals to achieve personal and professional goals through structured conversations and strategies, promoting self-discovery and growth.',
  },
  {
    filterId: 'techniqueTypes',
    image: ConflictCoaching,
    option: 'conflict-coaching',
    label: 'Conflict Coaching',
    description: 'Provides individuals with skills and strategies to manage and resolve personal or professional conflicts effectively.',
  },
  {
    filterId: 'techniqueTypes',
    image: ConflictMediation,
    option: 'conflict-mediation',
    label: 'Conflict Mediation',
    description: 'Guides parties through structured dialogue to resolve disputes, improve understanding, and reach mutually agreeable solutions.',
  },
  {
    filterId: 'techniqueTypes',
    image: ContactHealing,
    option: 'contact-healing',
    label: 'Contact Healing',
    description: 'A method where a healer uses touch to channel energy and promote physical and emotional healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: CoupleMediation,
    option: 'couple-mediation',
    label: 'Couple Mediation',
    description: 'Supports partners in resolving conflicts, enhancing communication, and finding mutually satisfying solutions.',
  },
  {
    filterId: 'techniqueTypes',
    image: CouplesTherapy,
    option: 'couples-therapy',
    label: 'Couples Therapy',
    description: 'Counseling for couples to improve their relationship, communication, and resolve conflicts, enhancing emotional connection and partnership.',
  },
  {
    filterId: 'techniqueTypes',
    image: CraniosacralTherapy,
    option: 'craniosacral-therapy',
    label: 'Craniosacral Therapy',
    description: 'A gentle, non-invasive bodywork technique focusing on the rhythmic movement of the craniosacral system to improve health.',
  },
  {
    filterId: 'techniqueTypes',
    image: CrystalHealing,
    option: 'crystal-healing',
    label: 'Crystal Healing',
    description: 'Using crystals and gemstones to balance energy, promote healing, and enhance well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: CrystalSoundBowlHealing,
    option: 'crystal-sound-bowl-healing',
    label: 'Crystal Sound Bowl Healing',
    description: 'Using the vibrations of crystal bowls to promote relaxation, balance energy, and support healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: CuppingTherapy,
    option: 'cupping-therapy',
    label: 'Cupping Therapy',
    description: 'An ancient technique using suction cups on the skin to increase blood flow, reduce pain, and promote healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: DanceTherapy,
    option: 'dance-therapy',
    label: 'Dance Therapy',
    description: 'Using dance and movement to support emotional, cognitive, and physical integration and well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: Detoxification,
    option: 'detoxification',
    label: 'Detoxification',
    description: 'Processes and practices aimed at eliminating toxins from the body to improve health and vitality.',
  },
  {
    filterId: 'techniqueTypes',
    image: DogTherapy,
    option: 'dog-therapy',
    label: 'Dog Therapy',
    description: 'Using trained dogs to assist in therapeutic settings, providing emotional support and improving mental health.',
  },
  {
    filterId: 'techniqueTypes',
    image: Doula,
    option: 'doula',
    label: 'Doula',
    description: 'A trained professional providing emotional and physical support to a woman before, during, and after childbirth.',
  },
  {
    filterId: 'techniqueTypes',
    image: EFTEmotionalFreedomTechniques,
    option: 'eft-emotional-freedom-techniques',
    label: 'EFT Emotional Freedom Techniques',
    description: 'Tapping on acupuncture points while focusing on specific issues to reduce emotional distress and improve well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: EmbodiedDance,
    option: 'embodied-dance',
    label: 'Embodied Dance',
    description: 'Using dance to connect mind and body, promoting self-expression, emotional release, and physical health',
  },
  {
    filterId: 'techniqueTypes',
    image: EMDREyeMovementDesensitizationAndReprocessing,
    option: 'emdr-eye-movement-desensitization-and-reprocessing',
    label: 'EMDR Eye Movement Desensitization And Reprocessing',
    description: 'A psychotherapy technique for treating trauma and PTSD through guided eye movements.',
  },
  {
    filterId: 'techniqueTypes',
    image: EmotionCode,
    option: 'emotion-code',
    label: 'Emotion Code',
    description: 'A technique for identifying and releasing trapped emotions to improve physical and emotional health.',
  },
  {
    filterId: 'techniqueTypes',
    image: EmotionalRelease,
    option: 'emotional-release',
    label: 'Emotional Release',
    description: 'Methods for releasing suppressed emotions to promote psychological and physical well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: EnergyHealing,
    option: 'energy-healing',
    label: 'Energy Healing',
    description: 'Practices such as Reiki that involve channeling energy to heal physical, emotional, and spiritual issues.',
  },
  {
    filterId: 'techniqueTypes',
    image: EquineTherapyWithHorses,
    option: 'equine-therapy-with-horses',
    label: 'Equine Therapy - Therapy with Horses',
    description: 'Using horses in therapeutic settings to enhance emotional, mental, and physical health.',
  },
  {
    filterId: 'techniqueTypes',
    image: FamilyConstellation,
    option: 'family-constellation',
    label: 'Family Constellation',
    description: 'A therapeutic approach to reveal hidden dynamics in family relationships and resolve inherited patterns affecting well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: FamilyMediation,
    option: 'family-mediation',
    label: 'Family Mediation',
    description: 'Facilitates open communication between family members to resolve conflicts, improve relationships, and reach balanced solutions.',
  },
  {
    filterId: 'techniqueTypes',
    image: FasciaYoga,
    option: 'fascia-yoga',
    label: 'Fascia Yoga',
    description: 'Combining yoga and fascia release techniques to improve flexibility, reduce pain, and enhance physical function.',
  },
  {
    filterId: 'techniqueTypes',
    image: FeldenkraisMethod,
    option: 'feldenkrais-method',
    label: 'Feldenkrais Method',
    description: 'A somatic education system using movement and awareness to improve physical function and well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: FengShui,
    option: 'feng-shui',
    label: 'Feng Shui',
    description: 'The Chinese art of arranging living spaces to promote harmony, balance, and positive energy flow.',
  },
  {
    filterId: 'techniqueTypes',
    image: FertilityCoaching,
    option: 'fertility-coaching',
    label: 'Fertility Coaching',
    description: 'Providing guidance and support to individuals and couples to enhance fertility and navigate the journey to parenthood.',
  },
  {
    filterId: 'techniqueTypes',
    image: FlowerEssences,
    option: 'flower-essences',
    label: 'Flower Essences',
    description: 'Using extracts from flowers to address emotional and psychological issues, promoting mental and emotional well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: FrequencyHealing,
    option: 'frequency-healing',
    label: 'Frequency Healing',
    description: 'Using sound frequencies to balance energy and promote healing at a cellular level.',
  },
  {
    filterId: 'techniqueTypes',
    image: FunctionalMedicine,
    option: 'functional-medicine',
    label: 'Functional Medicine',
    description: 'A holistic approach to health care focusing on identifying and addressing the root causes of diseases.',
  },
  {
    filterId: 'techniqueTypes',
    image: GestaltTherapy,
    option: 'gestalt-therapy',
    label: 'Gestalt therapy',
    description: 'Focuses on present-moment awareness and personal responsibility, helping individuals understand their thoughts, feelings, and experiences to promote self-awareness and growth.',
  },
  {
    filterId: 'techniqueTypes',
    image: GrinbergMethod,
    option: 'grinberg-method',
    label: 'Grinberg Method',
    description: 'A structured way of teaching through bodywork and techniques to increase awareness and bring about personal change and healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: HathaYoga,
    option: 'hatha-yoga',
    label: 'Hatha Yoga',
    description: 'A branch of yoga focusing on physical postures and breath control to enhance physical and mental well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: HealingTouch,
    option: 'healing-touch',
    label: 'Healing Touch',
    description: 'A biofield therapy using gentle touch to influence the energy system, promoting healing and health.',
  },
  {
    filterId: 'techniqueTypes',
    image: HealthCoaching,
    option: 'health-coaching',
    label: 'Health Coaching',
    description: 'Supporting individuals in achieving health-related goals through personalized guidance, motivation, and lifestyle changes.',
  },
  {
    filterId: 'techniqueTypes',
    image: HeartMath,
    option: 'heart-math',
    label: 'Heart Math',
    description: 'Techniques and technology for stress management and emotional self-regulation by focusing on heart-brain coherence.',
  },
  {
    filterId: 'techniqueTypes',
    image: Herbalism,
    option: 'herbalism',
    label: 'Herbalism',
    description: 'The practice of using plant-based remedies to prevent and treat illnesses, promoting overall health.',
  },
  {
    filterId: 'techniqueTypes',
    image: Hippotherapy,
    option: 'hippotherapy',
    label: 'Hippotherapy',
    description: 'Therapeutic horseback riding to improve physical, occupational, and speech outcomes for individuals with disabilities.',
  },
  {
    filterId: 'techniqueTypes',
    image: HolisticVisionCare,
    option: 'holistic-vision-care',
    label: 'Holistic Vision Care',
    description: 'Approaches to eye health focusing on natural methods and lifestyle changes to improve vision and prevent eye conditions.',
  },
  {
    filterId: 'techniqueTypes',
    image: Homeopathy,
    option: 'homeopathy',
    label: 'Homeopathy',
    description: 'A system of holistic medicine using highly diluted substances to stimulate the body’s self-healing abilities.',
  },
  {
    filterId: 'techniqueTypes',
    image: HumanDesign,
    option: 'human-design',
    label: 'Human Design',
    description: 'A system combining astrology, I Ching, Kabbalah, and the chakra system to provide insights into one\'s personality and life path.',
  },
  {
    filterId: 'techniqueTypes',
    image: Hydrotherapy,
    option: 'hydrotherapy',
    label: 'Hydrotherapy',
    description: 'The use of water in various forms to treat illnesses and promote physical health and relaxation.',
  },
  {
    filterId: 'techniqueTypes',
    image: HypnoBirthing,
    option: 'hypnoBirthing',
    label: 'Hypno Birthing',
    description: 'Uses relaxation, breathing, and self-hypnosis techniques to facilitate a calm and stress-free childbirth experience.',
  },
  {
    filterId: 'techniqueTypes',
    image: Hypnotherapy,
    option: 'hypnotherapy',
    label: 'Hypnotherapy',
    description: 'The use of hypnosis to access the subconscious mind, promoting behavior change, and treating various psychological issues.',
  },
  {
    filterId: 'techniqueTypes',
    image: ILMInnerLightMeditation,
    option: 'ilm-inner-light-meditation',
    label: 'ILM',
    description: 'This holistic approach uses gentle, rhythmic movement and touch to release physical and emotional tension, promoting healing and enhanced body awareness.',
  },
  {
    filterId: 'techniqueTypes',
    image: IntegrativeHealing,
    option: 'integrative-healing',
    label: 'Integrative Healing',
    description: 'A form of meditation focusing on visualizing inner light to achieve spiritual enlightenment and inner peace.',
  },
  {
    filterId: 'techniqueTypes',
    image: IntuitiveReading,
    option: 'intuitive-reading',
    label: 'Intuitive Reading',
    description: 'Gaining insights into one\'s life and future through intuitive perception, often involving psychic abilities.',
  },
  {
    filterId: 'techniqueTypes',
    image: IyengarYoga,
    option: 'iyengar-yoga',
    label: 'Iyengar Yoga',
    description: 'A form of yoga emphasizing precision and alignment in postures, often using props to achieve optimal body positioning.',
  },
  {
    filterId: 'techniqueTypes',
    image: Kinesiology,
    option: 'kinesiology',
    label: 'Kinesiology',
    description: 'A holistic therapy assessing movement and muscle function to identify imbalances and enhance health through corrective techniques.',
  },
  {
    filterId: 'techniqueTypes',
    image: KundaliniActivation,
    option: 'kundalini-activation',
    label: 'Kundalini Activation',
    description: 'Practices aimed at awakening Kundalini energy, believed to reside at the base of the spine, for spiritual enlightenment and healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: KundaliniYoga,
    option: 'kundalini-yoga',
    label: 'Kundalini Yoga',
    description: 'A spiritual yoga practice combining postures, breath work, chanting, and meditation to awaken Kundalini energy.',
  },
  {
    filterId: 'techniqueTypes',
    image: LifeCoaching,
    option: 'life-coaching',
    label: 'Life Coaching',
    description: 'Guiding individuals to achieve personal and professional goals through motivation, strategies, and self-discovery.',
  },
  {
    filterId: 'techniqueTypes',
    image: LomiLomiMassage,
    option: 'lomi-lomi-massage',
    label: 'Lomi Lomi Massage',
    description: 'A traditional Hawaiian massage using long, flowing strokes and rhythmic movements to promote relaxation and healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: MagnetTherapy,
    option: 'magnet-therapy',
    label: 'Magnet Therapy',
    description: 'Using static magnets on the body to improve health by influencing magnetic fields and increasing blood flow.',
  },
  {
    filterId: 'techniqueTypes',
    image: Marconics,
    option: 'marconics',
    label: 'Marconics',
    description: 'A multidimensional energy healing modality aimed at raising the vibration of the body and facilitating spiritual growth.',
  },
  {
    filterId: 'techniqueTypes',
    image: MartialArts,
    option: 'martial-arts',
    label: 'Martial Arts',
    description: 'Practicing combat and self-defense techniques to improve physical fitness, discipline, and mental focus.',
  },
  {
    filterId: 'techniqueTypes',
    image: MassageTherapy,
    option: 'massage-therapy',
    label: 'Massage Therapy',
    description: 'Manipulating muscles and soft tissues to relieve tension, reduce pain, and promote relaxation and overall health.',
  },
  {
    filterId: 'techniqueTypes',
    image: MedicalIntuitive,
    option: 'medical-intuitive',
    label: 'Medical Intuitive',
    description: 'A practitioner who uses intuitive abilities to identify health issues and provide insights into physical, emotional, and spiritual well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: MedicineWheel,
    option: 'medicine-wheel',
    label: 'Medicine Wheel',
    description: 'A Native American symbol used in healing practices to represent the interconnectedness of life and the balance of physical, emotional, and spiritual health.',
  },
  {
    filterId: 'techniqueTypes',
    image: Meditation,
    option: 'meditation',
    label: 'Meditation',
    description: 'Techniques for achieving mental clarity and emotional calmness through focused attention and mindfulness practices.',
  },
  {
    filterId: 'techniqueTypes',
    image: MenstrualCycleCoach,
    option: 'menstrual-cycle-cCoach',
    label: 'Menstrual Cycle Coach',
    description: 'A professional who provides guidance and support to individuals in understanding and optimizing their menstrual cycle for improved health, well-being, and lifestyle management.',
  },
  {
    filterId: 'techniqueTypes',
    image: MetaMedicine,
    option: 'meta-medicine',
    label: 'Meta Medicine',
    description: 'An integrative approach to understanding the root causes of disease by examining the mind-body connection and emotional conflicts.',
  },
  {
    filterId: 'techniqueTypes',
    image: Mindfulness,
    option: 'mindfulness',
    label: 'Mindfulness',
    description: 'The practice of being fully present and engaged in the current moment, enhancing mental clarity and emotional well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: MusicTherapy,
    option: 'music-therapy',
    label: 'Music Therapy',
    description: 'Using music and sound to address physical, emotional, cognitive, and social needs, promoting healing and well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: NaturopathicMedicine,
    option: 'naturopathic-medicine',
    label: 'Naturopathic Medicine',
    description: 'A holistic approach to health care using natural therapies and lifestyle changes to treat and prevent illness.',
  },
  {
    filterId: 'techniqueTypes',
    image: NeuroSystemicIntegration,
    option: 'neuro-systemic-integration',
    label: 'Neuro Systemic Integration',
    description: 'An approach integrating neuroscience and systemic therapy to address psychological and emotional issues.',
  },
  {
    filterId: 'techniqueTypes',
    image: NeuroTherapy,
    option: 'neuro-therapy',
    label: 'Neuro Therapy',
    description: 'Therapies focused on the nervous system to treat neurological conditions and improve mental and physical health.',
  },
  {
    filterId: 'techniqueTypes',
    image: NLPNeuroLinguisticProgramming,
    option: 'nlp-neuro-linguistic-programming',
    label: 'NLP Neuro Linguistic Programming',
    description: 'Techniques for understanding and changing thought patterns and behaviors to improve mental health and achieve personal goals.',
  },
  {
    filterId: 'techniqueTypes',
    image: Nutrition,
    option: 'nutrition',
    label: 'Nutrition',
    description: 'A practice of consuming a balanced diet to support health, energy, and overall well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: NutritionalTherapy,
    option: 'nutritional-therapy',
    label: 'Nutritional Therapy',
    description: 'Using diet and nutrition to improve health, prevent disease, and manage medical conditions.',
  },
  {
    filterId: 'techniqueTypes',
    image: Osteomyology,
    option: 'osteomyology',
    label: 'Osteomyology',
    description: 'A holistic approach combining elements of osteopathy and other bodywork techniques to treat musculoskeletal issues.',
  },
  {
    filterId: 'techniqueTypes',
    image: Osteopathy,
    option: 'osteopathy',
    label: 'Osteopathy',
    description: 'A medical practice emphasizing the treatment of medical disorders through the manipulation and massage of bones, joints, and muscles.',
  },
  {
    filterId: 'techniqueTypes',
    image: PantareiApproach,
    option: 'pantarei-approach',
    label: 'Pantarei Approach',
    description: 'A body-based, integrative approach to personal development and healing through touch and movement.',
  },
  {
    filterId: 'techniqueTypes',
    image: PastLifeRegression,
    option: 'past-life-regression',
    label: 'Past Life Regression',
    description: 'Hypnosis techniques to explore past life memories, providing insights and healing for current life issues.',
  },
  {
    filterId: 'techniqueTypes',
    image: PhysicalTherapy,
    option: 'physical-therapy',
    label: 'Physical Therapy',
    description: 'Treatment to improve movement, manage pain, and restore physical function through exercises and manual therapy.',
  },
  {
    filterId: 'techniqueTypes',
    image: Physiotherapy,
    option: 'physiotherapy',
    label: 'Physiotherapy',
    description: 'Treatment aimed at restoring movement and function through exercises, manual therapy, and other techniques, often after injury or surgery.',
  },
  {
    filterId: 'techniqueTypes',
    image: Pilates,
    option: 'pilates',
    label: 'Pilates',
    description: 'A physical fitness system focusing on core strength, flexibility, and overall body conditioning through controlled movements.',
  },
  {
    filterId: 'techniqueTypes',
    image: PlantMedicine,
    option: 'plant-medicine',
    label: 'Plant Medicine',
    description: 'Using medicinal plants and herbs for healing and wellness, often rooted in traditional practices.',
  },
  {
    filterId: 'techniqueTypes',
    image: PolarityTherapy,
    option: 'polarity-therapy',
    label: 'Polarity Therapy',
    description: 'A holistic practice balancing the body\'s energy through touch, diet, exercise, and self-awareness to promote health.',
  },
  {
    filterId: 'techniqueTypes',
    image: PranicHealing,
    option: 'pranic-healing',
    label: 'Pranic Healing',
    description: 'An energy healing system using prana (life energy) to balance the body\'s energy fields and promote healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: PrenatalYoga,
    option: 'prenatal-yYoga',
    label: 'Prenatal Yoga',
    description: 'Yoga practices tailored for pregnant women to enhance physical and emotional well-being, and prepare for childbirth.',
  },
  {
    filterId: 'techniqueTypes',
    image: PSYCHK,
    option: 'psychk',
    label: 'PSYCH-K',
    description: 'A method for changing subconscious beliefs to align with conscious goals, promoting psychological and behavioral changes.',
  },
  {
    filterId: 'techniqueTypes',
    image: PsychicReading,
    option: 'psychic-rReading',
    label: 'Psychic Reading',
    description: 'Gaining insights into one\'s life and future through a psychic\'s intuitive abilities and perceptions.',
  },
  {
    filterId: 'techniqueTypes',
    image: Psychotherapy,
    option: 'psychotherapy',
    label: 'Psychotherapy',
    description: 'Professional counseling to treat mental health issues and emotional difficulties through talk therapy and psychological techniques.',
  },
  {
    filterId: 'techniqueTypes',
    image: Qigong,
    option: 'qigong',
    label: 'Qigong',
    description: 'A Chinese practice involving movement, meditation, and breath control to enhance physical and mental health and spiritual growth.',
  },
  {
    filterId: 'techniqueTypes',
    image: QuantumHealing,
    option: 'quantum-healing',
    label: 'Quantum Healing',
    description: 'Techniques based on quantum physics principles to promote healing at a cellular and energetic level.',
  },
  {
    filterId: 'techniqueTypes',
    image: QuantumTouch,
    option: 'quantum-touch',
    label: 'Quantum Touch',
    description: 'A method of energy healing using light touch and body awareness techniques to facilitate healing and pain relief.',
  },
  {
    filterId: 'techniqueTypes',
    image: Radionics,
    option: 'radionics',
    label: 'Radionics',
    description: 'Using electromagnetic fields and frequencies to diagnose and treat health conditions remotely.',
  },
  {
    filterId: 'techniqueTypes',
    image: RapCeremony,
    option: 'rapé-ceremony',
    label: 'Rapé Ceremony',
    description: 'A traditional shamanic practice involving the administration of a sacred tobacco snuff to cleanse energy and promote spiritual clarity.',
  },
  {
    filterId: 'techniqueTypes',
    image: ReBirthing,
    option: 're-birthing',
    label: 'Re-birthing',
    description: 'Breathwork techniques to release emotional blockages and trauma, promoting mental and emotional healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: Rebalancing,
    option: 'rebalancing',
    label: 'Rebalancing',
    description: 'A bodywork therapy combining deep tissue massage, joint release, and energy balancing to relieve tension and promote well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: Reflexology,
    option: 'reflexology',
    label: 'Reflexology',
    description: 'Applying pressure to specific points on the feet, hands, or ears to improve health and balance through reflex points.',
  },
  {
    filterId: 'techniqueTypes',
    image: Reiki,
    option: 'reiki',
    label: 'Reiki',
    description: 'A Japanese energy healing practice where practitioners channel universal energy to promote healing and balance.',
  },
  {
    filterId: 'techniqueTypes',
    image: RestorativeYoga,
    option: 'restorative-yoga',
    label: 'Restorative Yoga',
    description: 'A gentle form of yoga using props to support the body in restful poses, enhancing relaxation and stress relief.',
  },
  {
    filterId: 'techniqueTypes',
    image: RTTRapidTransformationalTherapy,
    option: 'rtt-rapid-transformational-therapy',
    label: 'RTT Rapid Transformational Therapy',
    description: 'A hybrid therapy combining hypnosis, NLP, CBT, and psychotherapy to achieve rapid, lasting change.',
  },
  {
    filterId: 'techniqueTypes',
    image: SaltTherapy,
    option: 'salt-therapy',
    label: 'Salt Therapy',
    description: 'Breathing in salt-infused air to improve respiratory health and skin conditions, and promote relaxation.',
  },
  {
    filterId: 'techniqueTypes',
    image: SensoryHealing,
    option: 'sensory-healing',
    label: 'Sensory Healing',
    description: 'Techniques using sensory stimuli such as sound, touch, or light to promote healing and well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: SexTherapyAndHealing,
    option: 'sex-therapy-and-healing',
    label: 'Sex Therapy And Healing',
    description: 'Therapeutic practices addressing sexual issues, enhancing intimacy, and promoting sexual health and healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: SexologicalBodywork,
    option: 'sexological-bodywork',
    label: 'Sexological Bodywork',
    description: 'A somatic therapy using guided touch, breathwork, and movement to enhance sexual awareness, healing, and embodiment.',
  },
  {
    filterId: 'techniqueTypes',
    image: SexualityCoaching,
    option: 'sexuality-coaching',
    label: 'Sexuality Coaching',
    description: 'Guidance and support for individuals or couples to explore and enhance their sexual health, relationships, and intimacy.',
  },
  {
    filterId: 'techniqueTypes',
    image: ShadowWork,
    option: 'shadow-work',
    label: 'Shadow-work',
    description: 'Techniques to explore and integrate the unconscious aspects of the personality, promoting self-awareness and healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: Shamanism,
    option: 'shamanism',
    label: 'Shamanism',
    description: 'Spiritual practices involving rituals, ceremonies, and healing techniques to connect with the spirit world and promote health.',
  },
  {
    filterId: 'techniqueTypes',
    image: Shiatsu,
    option: 'shiatsu',
    label: 'Shiatsu',
    description: 'A Japanese bodywork therapy using finger pressure, stretches, and joint rotations to balance energy and improve health.',
  },
  {
    filterId: 'techniqueTypes',
    image: ShivaShaktiYoga,
    option: 'shiva-shakti-yoga',
    label: 'Shiva Shakti Yoga',
    description: 'A form of yoga focusing on balancing masculine (Shiva) and feminine (Shakti) energies through postures, breathwork, and meditation.',
  },
  {
    filterId: 'techniqueTypes',
    image: SiddhaMedicine,
    option: 'siddha-medicine',
    label: 'Siddha Medicine',
    description: 'A traditional Indian medicine system using herbs, minerals, and spiritual practices for holistic health and healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: SomaticBodywork,
    option: 'somatic-bodywork',
    label: 'Somatic Bodywork',
    description: 'Body-centered therapies focusing on the connection between body and mind to release trauma and promote healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: SomaticCoaching,
    option: 'somatic-coaching',
    label: 'Somatic Coaching',
    description: 'Integrating body awareness into coaching to enhance personal development, resolve issues, and promote well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: SomaticMovements,
    option: 'somatic-movements',
    label: 'Somatic Movements',
    description: 'Exercises and movements designed to increase body awareness, release tension, and improve physical and emotional health.',
  },
  {
    filterId: 'techniqueTypes',
    image: SomaticTherapy,
    option: 'somatic-therapy',
    label: 'Somatic Therapy',
    description: 'Therapeutic practices focusing on the body to address psychological and emotional issues, promoting overall well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: Sonopuncture,
    option: 'sonopuncture',
    label: 'Sonopuncture',
    description: 'Using sound waves and vibrations to stimulate acupuncture points, promoting healing and balance without needles.',
  },
  {
    filterId: 'techniqueTypes',
    image: Sophrology,
    option: 'sophrology',
    label: 'Sophrology',
    description: 'A relaxation technique combining breathing, visualization, and body awareness to reduce stress and enhance well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: SoundTherapy,
    option: 'sound-therapy',
    label: 'Sound Therapy',
    description: 'Using sound vibrations, music, or instruments to promote relaxation, reduce stress, and support healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: SpiritualHealing,
    option: 'spiritual-healing',
    label: 'Spiritual Healing',
    description: 'Practices focusing on restoring spiritual balance and health, often involving energy work, meditation, and prayer.',
  },
  {
    filterId: 'techniqueTypes',
    image: SpiritualMindTreatment,
    option: 'spiritual-mind-treatment',
    label: 'Spiritual Mind Treatment',
    description: 'Affirmative prayer techniques to align thought with spiritual principles, promoting healing and positive change.',
  },
  {
    filterId: 'techniqueTypes',
    image: Spirituality,
    option: 'spirituality',
    label: 'Spirituality',
    description: 'Practices and beliefs related to connecting with the divine, self-discovery, and exploring the meaning of life.',
  },
  {
    filterId: 'techniqueTypes',
    image: SweatLodge,
    option: 'sweat-lodge',
    label: 'Sweat Lodge',
    description: 'A traditional ceremony involving sweating in a heated enclosure to cleanse the body, mind, and spirit.',
  },
  {
    filterId: 'techniqueTypes',
    image: SystemicCoaching,
    option: 'systemic-coaching',
    label: 'Systemic Coaching',
    description: 'Coaching approach considering individual issues within the broader context of systems, such as family or work environments.',
  },
  {
    filterId: 'techniqueTypes',
    image: TaiChi,
    option: 'tai-chi',
    label: 'Tai Chi',
    description: 'A Chinese martial art focusing on slow, flowing movements and deep breathing to improve physical health and mental clarity.',
  },
  {
    filterId: 'techniqueTypes',
    image: Tantra,
    option: 'tantra',
    label: 'Tantra',
    description: 'Spiritual practices and rituals aimed at expanding consciousness and achieving spiritual enlightenment through energy work and meditation.',
  },
  {
    filterId: 'techniqueTypes',
    image: TantricYoga,
    option: 'tantric-yoga',
    label: 'Tantric Yoga',
    description: 'A form of yoga incorporating Tantra principles, focusing on energy, meditation, and physical postures to achieve spiritual growth.',
  },
  {
    filterId: 'techniqueTypes',
    image: Tarot,
    option: 'tarot',
    label: 'Tarot',
    description: 'A form of yoga incorporating Tantra principles, focusing on energy, meditation, and physical postures to achieve spiritual growth.',
  },
  {
    filterId: 'techniqueTypes',
    image: ThaiBodywork,
    option: 'thai-bodywork',
    label: 'Thai Bodywork',
    description: 'A traditional Thai massage combining acupressure, stretching, and assisted yoga postures to improve flexibility and energy flow.',
  },
  {
    filterId: 'techniqueTypes',
    image: TherapeuticTouch,
    option: 'therapeutic-touch',
    label: 'Therapeutic Touch',
    description: 'A biofield therapy using the hands to direct energy and promote healing and well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: ThetaHealing,
    option: 'theta-healing',
    label: 'Theta Healing',
    description: 'A meditation technique using theta brainwave states to achieve physical, emotional, and spiritual healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: ThoughtFieldTherapy,
    option: 'thought-field-therapy',
    label: 'Thought Field Therapy',
    description: 'A psychological treatment using tapping on meridian points while focusing on specific thoughts to reduce distress and improve well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: TraditionalChineseMedicine,
    option: 'traditional-chinese-medicine',
    label: 'Traditional Chinese Medicine',
    description: 'An ancient system of medicine using herbal remedies, acupuncture, and lifestyle practices to promote health and balance.',
  },
  {
    filterId: 'techniqueTypes',
    image: TraditionalJapaneseMedicine,
    option: 'traditional-japanese-medicine',
    label: 'Traditional Japanese Medicine',
    description: 'Traditional healing practices from Japan, including herbal medicine, acupuncture, and dietary therapy.',
  },
  {
    filterId: 'techniqueTypes',
    image: TraditionalKoreanMedicine,
    option: 'traditional-korean-medicine',
    label: 'Traditional Korean Medicine',
    description: 'A holistic medical system from Korea using herbal medicine, acupuncture, and physical therapies for health and healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: TraditionalMongolianMedicine,
    option: 'tTraditional-mongolian-medicine',
    label: 'Traditional Mongolian Medicine',
    description: 'Traditional healing practices from Mongolia using herbs, spiritual practices, and physical therapies.',
  },
  {
    filterId: 'techniqueTypes',
    image: TraditionalTibetanMedicine,
    option: 'traditional-tibetan-medicine',
    label: 'Traditional Tibetan Medicine',
    description: 'A holistic medical system from Tibet using herbal medicine, spiritual practices, and lifestyle changes for health.',
  },
  {
    filterId: 'techniqueTypes',
    image: TranscendentalMeditation,
    option: 'transcendental-meditation',
    label: 'Transcendental Meditation',
    description: 'A form of meditation involving silently repeating a mantra to achieve a state of deep relaxation and inner peace.',
  },
  {
    filterId: 'techniqueTypes',
    image: TransformativeCoaching,
    option: 'transformative-coaching',
    label: 'Transformative Coaching',
    description: 'Coaching aimed at achieving profound personal change and self-discovery, often focusing on inner transformation and growth.',
  },
  {
    filterId: 'techniqueTypes',
    image: TraumaSensitiveYoga,
    option: 'trauma-sensitive-yoga',
    label: 'Trauma Sensitive Yoga',
    description: 'Yoga practices tailored to support individuals with trauma, emphasizing safety, choice, and mindfulness.',
  },
  {
    filterId: 'techniqueTypes',
    image: Tuina,
    option: 'tTuina',
    label: 'Tuina',
    description: 'A form of Chinese massage therapy involving acupressure, stretching, and manipulation to improve energy flow and health.',
  },
  {
    filterId: 'techniqueTypes',
    image: VinyasaYoga,
    option: 'vinyasa-yoga',
    label: 'Vinyasa Yoga',
    description: 'A dynamic form of yoga linking breath with movement in a flowing sequence of postures.',
  },
  {
    filterId: 'techniqueTypes',
    image: Vipassana,
    option: 'vipassana',
    label: 'Vipassana',
    description: 'An ancient meditation technique focusing on self-observation and mindfulness to achieve mental clarity and emotional balance.',
  },
  {
    filterId: 'techniqueTypes',
    image: VoiceTherapy,
    option: 'voice-therapy',
    label: 'Voice Therapy',
    description: 'Using vocal exercises and techniques to improve vocal health and expression, often used in speech therapy.',
  },
  {
    filterId: 'techniqueTypes',
    image: VortexHealing,
    option: 'vortex-healing',
    label: 'Vortex Healing',
    description: 'An energy healing modality using divine energy and consciousness to release emotional blockages and promote holistic healing.',
  },
  {
    filterId: 'techniqueTypes',
    image: WaterTherapy,
    option: 'water-therapy',
    label: 'Water Therapy',
    description: 'Using water-based treatments such as hydrotherapy, swimming, and baths to improve physical and mental health.',
  },
  {
    filterId: 'techniqueTypes',
    image: WellnessCoaching,
    option: 'wellness-coaching',
    label: 'Wellness Coaching',
    description: 'Guiding individuals to achieve overall wellness through lifestyle changes, nutrition, exercise, and stress management.',
  },
  {
    filterId: 'techniqueTypes',
    image: YinYoga,
    option: 'yin-yoga',
    label: 'Yin Yoga',
    description: 'A slow-paced style of yoga with long-held postures to stretch deep connective tissues and promote relaxation.',
  },
  {
    filterId: 'techniqueTypes',
    image: YogaNidra,
    option: 'yoga-nidra',
    label: 'Yoga Nidra',
    description: 'A guided meditation practice inducing deep relaxation and a state of conscious awareness between waking and sleeping.',
  },
  {
    filterId: 'techniqueTypes',
    image: YogaRatna,
    option: 'yoga-ratna',
    label: 'Yoga Ratna',
    description: 'A form of yoga integrating various traditions to promote holistic health and spiritual growth.',
  },
  {
    filterId: 'techniqueTypes',
    image: YogaTherapy,
    option: 'yoga-therapy',
    label: 'Yoga Therapy',
    description: 'Using yoga practices tailored to individual needs to address specific health issues and promote overall well-being.',
  },
  {
    filterId: 'techniqueTypes',
    image: Yoga,
    option: 'yoga',
    label: 'Yoga',
    description: 'A practice combining physical postures, breath control, meditation, and ethical principles to achieve physical, mental, and spiritual well-being.',
  },
];
