import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import { func, bool, number } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, SecondaryButton, Modal } from '..';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { IconFlower } from '../../components';

import css from './ImageCrop.module.css';


const CROP_START_SIZE = 50;

const defaultState = {
  file: null,
  crop: {
    unit: '%',
    width: CROP_START_SIZE,
    height: CROP_START_SIZE,
    x: 25,
    y: 25,
  },
  croppedImage: null,
  imageRef: null,
  imageScale: 0,
}

const setDefaultState = (props) => {
  return {
    ...defaultState,
    crop: {
      unit: '%',
      width: props?.minWidth || CROP_START_SIZE,
      height: props?.minHeight || CROP_START_SIZE,
      x: 25,
      y: 25,
    }
  }
}

class ImageCrop extends Component {
  constructor(props) {
    super(props)

    this.state = setDefaultState(props);
    this.inputRef = React.createRef();
    this.imgRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (isOpen !== prevProps.isOpen && !!isOpen) {
      this.inputRef.current.click();
    }
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const { type, name } = e.target.files[0];
      reader.addEventListener('load', () => {
        return this.setState({ file: { src: reader.result, type, name } })
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = async (image, crop, type, name) => {

    await this.setState({ imageRef: image })
    this.makeClientCrop(crop, type, name);
  };

  onCropComplete = (crop, type, name) => {

    this.makeClientCrop(crop, type, name);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    
    this.setState({ crop: percentCrop });
  };

  makeClientCrop(crop, type, name) {
    const { imageRef, imageScale } = this.state;
    if (imageRef && crop.width && crop.height) {
      this.getCroppedImg(
        imageRef,
        crop = crop.width === CROP_START_SIZE
          ? {
            unit: 'px',
            width: imageRef?.width / 2,
            height: imageRef?.height / 2,
            x: imageRef?.width / 4,
            y: imageRef?.height / 4,
          }
          : crop,
        name,
        type,
        1 + imageScale / 10,
      ).then(croppedImage => this.setState({ croppedImage }));
    }
  }

  getCroppedImg(image, crop, fileName, type, scale = 1, rotate = 0) {
    if (typeof document !== undefined) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio || 1;

      canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
      canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = 'high';

      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;

      const rotateRads = rotate * (Math.PI / 180);
      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;

      ctx.save();
      ctx.translate(-cropX, -cropY);
      ctx.translate(centerX, centerY);
      ctx.rotate(rotateRads);
      ctx.scale(scale, scale);
      ctx.translate(-centerX, -centerY);

      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );

      ctx.restore();


      if (fileName) {
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (blob) {
              blob.name = fileName;
              resolve(blob);
            } else {
              reject(new Error('Canvas is empty'));
            }
          }, type, 0.8);
        });
      }
    }

    return Promise.resolve(null);
  }

  handleCreateFile(e) {
    if (e) {
      e.preventDefault();
    }
    const { submitFile, namePrefix } = this.props;
    const { file, croppedImage } = this.state;

    if (!file) {
      return;
    }

    const { type, name } = file;

    const newName = namePrefix ? `${namePrefix}-${name}` : name;

    const f = new File([croppedImage], newName, { type })

    submitFile(f);
    this.handleCancel();
  }

  handleCancel(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState(setDefaultState(this.props));
    this.inputRef.current.value = null;
    this.props.onClose();
  }

  handleChangeImageScale = (e) => {
    const { imageRef, crop, file } = this.state;
    const { type, name } = file || {};
    const imageScale = e.target.value;
  
    const newCrop = crop.width === CROP_START_SIZE
      ? {
          unit: 'px',
          width: imageRef?.width / 2,
          height: imageRef?.height / 2,
          x: imageRef?.width / 4,
          y: imageRef?.height / 4,
        }
      : crop;
  
    this.setState({ imageScale: e.target.value }, () => {
      this.getCroppedImg(
        imageRef,
        newCrop,
        name,
        type,
        1 + imageScale / 10
      ).then(croppedImage => this.setState({ croppedImage }));
    });
  };

  render() {
    const { crop, file, croppedImage, imageScale } = this.state;
    const { src, type, name } = file || {};
    const { onManageDisableScrolling, minWidth, minHeight, isWideScreen } = this.props;

    const customRange = (
      <div className={css.rangeContainer}>
        <span className={css.rangeIconSmall}>
          <IconFlower />
        </span>

        <input
          type="range"
          min="0"
          max="100"
          value={imageScale}
          className={css.customRange}
          onChange={this.handleChangeImageScale}
        />

        <span className={css.rangeIconBig}>
          <IconFlower />
        </span>
      </div>
    );

    return (
      <>
        <input
          ref={this.inputRef}
          type="file"
          accept="image/*"
          onChange={this.onSelectFile}
          hidden
          style={{ display: 'none' }}
        />

        <Modal
          id="ImageCrop"
          isOpen={!!file}
          onClose={() => this.handleCancel()}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >

          {src && (
            <div className={css.cropContainer}>

              <h2 className={css.cropTitle}>
                <FormattedMessage id="ImageCrop.title" />
              </h2>
              <p className={css.cropDescription}>
                <FormattedMessage id="ImageCrop.description" />
              </p>

              <ReactCrop
                crop={crop}
                onChange={this.onCropChange}
                onComplete={crop => this.onCropComplete(crop, type, name)}
                aspect={isWideScreen ? 16/9 : minWidth/minHeight}
                minWidth={minWidth}
                minHeight={minHeight}
              // onImageLoaded={this.onImageLoaded}
              // ruleOfThirds
              >
                <img
                  ref={this.imgRef}
                  alt="Crop me"
                  src={src}
                  onLoad={e => this.onImageLoaded(this.imgRef.current, crop, type, name)}
                  style={{ transform: `scale(${1 + imageScale / 10})` }}
                />
              </ReactCrop>

            </div>
          )}

          {customRange}

          <div className={css.buttons}>
            <SecondaryButton
              type="button"
              onClick={(e) => this.handleCancel()}
              className={classNames(css.btn, css.btnWhite)}
            >
              <FormattedMessage id="ImageCrop.cancel" />
            </SecondaryButton>

            <Button
              type="button"
              onClick={(e) => this.handleCreateFile(e)}
              className={classNames(css.btn, css.btnDefault)}
            >
              <FormattedMessage id="ImageCrop.save" />
            </Button>
          </div>

        </Modal>
      </>
    );
  }
}

ImageCrop.defaultProps = {
  aspect: 16 / 10
}

ImageCrop.propTypes = {
  isOpen: bool.isRequired,
  submitFile: func.isRequired,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  aspect: number
}

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default connect(null, mapDispatchToProps)(ImageCrop);