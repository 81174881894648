import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../FieldCheckbox.module.css';

const IconBiohacking = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.643799 8.03239C0.643799 9.7353 1.32028 11.3685 2.52442 12.5726C3.72856 13.7767 5.36172 14.4532 7.06463 14.4532C8.76754 14.4532 10.4007 13.7767 11.6048 12.5726C12.809 11.3685 13.4855 9.7353 13.4855 8.03239C13.4855 6.32948 12.809 4.69632 11.6048 3.49218C10.4007 2.28804 8.76754 1.61156 7.06463 1.61156C5.36172 1.61156 3.72856 2.28804 2.52442 3.49218C1.32028 4.69632 0.643799 6.32948 0.643799 8.03239Z" fill="#DFFF60"/>
      <path d="M7.06456 1.61156C5.79048 1.61216 4.54537 1.99179 3.48769 2.70214C2.43001 3.41249 1.60757 4.42146 1.12504 5.60063C0.642502 6.77981 0.521688 8.07588 0.777967 9.32393C1.03425 10.572 1.65603 11.7156 2.5642 12.6092L11.6413 3.53203C11.0449 2.92319 10.3327 2.43967 9.54678 2.10988C8.76083 1.78008 7.9169 1.61066 7.06456 1.61156Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M0.643799 8.03239C0.643799 9.7353 1.32028 11.3685 2.52442 12.5726C3.72856 13.7767 5.36172 14.4532 7.06463 14.4532C8.76754 14.4532 10.4007 13.7767 11.6048 12.5726C12.809 11.3685 13.4855 9.7353 13.4855 8.03239C13.4855 6.32948 12.809 4.69632 11.6048 3.49218C10.4007 2.28804 8.76754 1.61156 7.06463 1.61156C5.36172 1.61156 3.72856 2.28804 2.52442 3.49218C1.32028 4.69632 0.643799 6.32948 0.643799 8.03239Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.6118 12.5648L15.4361 16.3885" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.30602 6.33214C4.61129 7.15144 4.24402 8.22565 5.90059 8.94928C6.65889 10.6424 7.81272 10.0158 8.58001 9.11108C9.27281 8.29435 9.57973 6.24225 9.13477 4.91057C9.11907 4.86386 9.09283 4.82138 9.05809 4.78644C9.02334 4.7515 8.98101 4.72502 8.93439 4.70907C8.88776 4.69311 8.83809 4.6881 8.78922 4.69443C8.74034 4.70076 8.69359 4.71825 8.65256 4.74556C7.4692 5.53275 6.10285 5.39342 5.30602 6.33214Z" fill="#DFFF60" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.8418 10.9308C5.48316 9.21002 6.52228 7.66531 7.87436 6.42268" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

const { string } = PropTypes;

IconBiohacking.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBiohacking.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBiohacking;
