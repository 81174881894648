import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMoreFilters.module.css';


const IconMoreFilters = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);


  return (
    <svg className={classes} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4757 1.46822C12.538 1.38521 12.5759 1.28649 12.5852 1.18315C12.5945 1.0798 12.5749 0.9759 12.5285 0.883088C12.4821 0.790277 12.4107 0.712221 12.3225 0.657668C12.2342 0.603114 12.1325 0.574219 12.0287 0.574219H1.96061C1.85684 0.574219 1.75512 0.603114 1.66685 0.657668C1.57858 0.712221 1.50725 0.790277 1.46084 0.883088C1.41444 0.9759 1.39479 1.0798 1.40411 1.18315C1.41343 1.28649 1.45135 1.38521 1.51361 1.46822L5.87633 7.33006V12.8667C5.87633 12.9705 5.90522 13.0722 5.95977 13.1605C6.01433 13.2487 6.09238 13.3201 6.18519 13.3665C6.27801 13.4129 6.38191 13.4325 6.48525 13.4232C6.5886 13.4139 6.68731 13.376 6.77033 13.3137L7.88783 12.4756C7.95722 12.4235 8.01354 12.3561 8.05234 12.2785C8.09113 12.2009 8.11133 12.1153 8.11133 12.0286V7.32951L12.4757 1.46822Z" fill="#DFFF60"/>
      <path  d="M6.99997 0.574219H1.96061C1.85684 0.574219 1.75512 0.603114 1.66685 0.657668C1.57858 0.712221 1.50725 0.790277 1.46084 0.883088C1.41444 0.9759 1.39479 1.0798 1.40411 1.18315C1.41343 1.28649 1.45135 1.38521 1.51361 1.46822L5.87633 7.33006V12.8667C5.87633 12.9705 5.90522 13.0722 5.95977 13.1605C6.01433 13.2487 6.09238 13.3201 6.18519 13.3665C6.27801 13.4129 6.38191 13.4325 6.48525 13.4232C6.5886 13.4139 6.68731 13.376 6.77033 13.3137L6.99997 13.1422V0.574219Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M12.4757 1.46822C12.538 1.38521 12.5759 1.28649 12.5852 1.18315C12.5945 1.0798 12.5749 0.9759 12.5285 0.883088C12.4821 0.790277 12.4107 0.712221 12.3225 0.657668C12.2342 0.603114 12.1325 0.574219 12.0287 0.574219H1.96061C1.85684 0.574219 1.75512 0.603114 1.66685 0.657668C1.57858 0.712221 1.50725 0.790277 1.46084 0.883088C1.41444 0.9759 1.39479 1.0798 1.40411 1.18315C1.41343 1.28649 1.45135 1.38521 1.51361 1.46822L5.87633 7.33006V12.8667C5.87633 12.9705 5.90522 13.0722 5.95977 13.1605C6.01433 13.2487 6.09238 13.3201 6.18519 13.3665C6.27801 13.4129 6.38191 13.4325 6.48525 13.4232C6.5886 13.4139 6.68731 13.376 6.77033 13.3137L7.88783 12.4756C7.95722 12.4235 8.01354 12.3561 8.05234 12.2785C8.09113 12.2009 8.11133 12.1153 8.11133 12.0286V7.32951L12.4757 1.46822Z" stroke="#21262A" stroke-width="0.59" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

const { string } = PropTypes;

IconMoreFilters.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMoreFilters.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMoreFilters;
