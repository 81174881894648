import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconErrorTip.module.css';

const IconErrorTip = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3240_10240)">
        <path d="M0.654297 7.99992C0.654297 8.96456 0.844297 9.91976 1.21345 10.811C1.5826 11.7022 2.12368 12.512 2.80578 13.1941C3.48788 13.8762 4.29766 14.4172 5.18887 14.7864C6.08009 15.1555 7.03528 15.3455 7.99992 15.3455C8.96456 15.3455 9.91976 15.1555 10.811 14.7864C11.7022 14.4172 12.512 13.8762 13.1941 13.1941C13.8762 12.512 14.4172 11.7022 14.7864 10.811C15.1555 9.91976 15.3455 8.96456 15.3455 7.99992C15.3455 7.03528 15.1555 6.08009 14.7864 5.18887C14.4172 4.29766 13.8762 3.48788 13.1941 2.80578C12.512 2.12368 11.7022 1.5826 10.811 1.21345C9.91976 0.844297 8.96456 0.654297 7.99992 0.654297C7.03528 0.654297 6.08009 0.844297 5.18887 1.21345C4.29766 1.5826 3.48788 2.12368 2.80578 2.80578C2.12368 3.48788 1.5826 4.29766 1.21345 5.18887C0.844297 6.08009 0.654297 7.03528 0.654297 7.99992Z" fill="#FF5A5F"/>
        <path d="M2.80585 13.1942C1.42823 11.8166 0.654297 9.94817 0.654297 7.99992C0.654297 6.05168 1.42823 4.18323 2.80585 2.80561C4.18347 1.42799 6.05192 0.654053 8.00017 0.654053C9.94841 0.654053 11.8169 1.42799 13.1945 2.80561L2.80585 13.1942Z" fill="#FFCECF"/>
        <path d="M0.654297 7.99992C0.654297 8.96456 0.844297 9.91976 1.21345 10.811C1.5826 11.7022 2.12368 12.512 2.80578 13.1941C3.48788 13.8762 4.29766 14.4172 5.18887 14.7864C6.08009 15.1555 7.03528 15.3455 7.99992 15.3455C8.96456 15.3455 9.91976 15.1555 10.811 14.7864C11.7022 14.4172 12.512 13.8762 13.1941 13.1941C13.8762 12.512 14.4172 11.7022 14.7864 10.811C15.1555 9.91976 15.3455 8.96456 15.3455 7.99992C15.3455 7.03528 15.1555 6.08009 14.7864 5.18887C14.4172 4.29766 13.8762 3.48788 13.1941 2.80578C12.512 2.12368 11.7022 1.5826 10.811 1.21345C9.91976 0.844297 8.96456 0.654297 7.99992 0.654297C7.03528 0.654297 6.08009 0.844297 5.18887 1.21345C4.29766 1.5826 3.48788 2.12368 2.80578 2.80578C2.12368 3.48788 1.5826 4.29766 1.21345 5.18887C0.844297 6.08009 0.654297 7.03528 0.654297 7.99992Z" stroke="#21262A" strokeWidth="0.67" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.80664 4.80615L11.1941 11.1937" stroke="#21262A" strokeWidth="0.67" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.1941 4.80615L4.80664 11.1937" stroke="#21262A" strokeWidth="0.67" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_3240_10240">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

IconErrorTip.defaultProps = { className: null };

IconErrorTip.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconErrorTip;
