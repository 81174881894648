import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
export const ASSET_NAME = 'landing-page';

export const FETCH_ALL_LISTINGS = 'app/LandingPageClient/FETCH_ALL_LISTINGS';

const initialState = {
  allResults: [],
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {

    case FETCH_ALL_LISTINGS:
      return { ...state, allResults: state.allResults.concat(payload.data).slice(0, payload.meta.totalItems) };

    default:
      return state;
  }
};

export default listingPageReducer;

const fetchAllListings = payload => ({
  type: FETCH_ALL_LISTINGS,
  payload
});


export const loadData = (params, search) => (dispatch, getState, sdk) => {

  const getAllListings = (page = 1) => {

    const apiQueryParams = {
      page,
      per_page: 100,
      'fields.listing': [
        'title',
        'publicData.techniqueTypes',
      ],
      pub_listingType: 'treatment-booking',
    };

    return sdk.listings.query(apiQueryParams)
      .then(response => {
        const sanitizeConfig = { };

        dispatch(addMarketplaceEntities(response, sanitizeConfig));
        return response;
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  }

  const getListingsFromAllPages = async (page) => {

    let transactionsPerPage = await getAllListings(page);
    let { data, meta } = transactionsPerPage.data;

    dispatch(fetchAllListings({ data, meta }));

    if (meta.totalPages > meta.page) {
      return getListingsFromAllPages(++meta.page);
    }
    else {
      return true;
    }
  }


  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  
  return Promise.all([
    getListingsFromAllPages(),
    dispatch(fetchPageAssets(pageAsset, true)),
  ]);
};
