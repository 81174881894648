import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconEye.module.css';

const IconEye = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <mask
        id="mask0_3259_2492"
        style={{ maskType: "alpha" }}
        width="25"
        height="25"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0.5 0.5H24.5V24.5H0.5z"></path>
      </mask>
      <g mask="url(#mask0_3259_2492)">
        <path
          fill="#1C1B1F"
          d="M12.504 15.654c1.017 0 1.879-.356 2.587-1.067.709-.712 1.063-1.575 1.063-2.591 0-1.017-.356-1.879-1.067-2.587-.712-.709-1.575-1.063-2.592-1.063-1.016 0-1.878.356-2.586 1.067-.709.712-1.063 1.575-1.063 2.591 0 1.017.356 1.879 1.067 2.587.711.709 1.575 1.063 2.591 1.063zM12.5 14.7c-.75 0-1.388-.262-1.913-.787A2.604 2.604 0 019.8 12c0-.75.262-1.387.787-1.912A2.604 2.604 0 0112.5 9.3c.75 0 1.387.262 1.912.787.525.526.788 1.163.788 1.913s-.263 1.387-.788 1.912a2.604 2.604 0 01-1.912.788zm.003 3.8c-2.166 0-4.14-.59-5.922-1.768-1.782-1.18-3.117-2.756-4.004-4.732.887-1.976 2.22-3.553 4-4.732C8.359 6.09 10.332 5.5 12.498 5.5s4.14.59 5.922 1.768c1.782 1.18 3.117 2.756 4.004 4.732-.887 1.976-2.22 3.553-4.001 4.732-1.78 1.179-3.753 1.768-5.92 1.768zm-.003-1a9.544 9.544 0 005.187-1.488A9.773 9.773 0 0021.3 12a9.773 9.773 0 00-3.613-4.013A9.545 9.545 0 0012.5 6.5a9.545 9.545 0 00-5.188 1.487A9.773 9.773 0 003.7 12a9.773 9.773 0 003.612 4.012A9.544 9.544 0 0012.5 17.5z"
        ></path>
      </g>
    </svg>
  );
};

IconEye.defaultProps = { className: null };

IconEye.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEye;
