import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCreditCards.module.css';

const IconCreditCards = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  // className={classes}

  if (type === "viza") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="24"
        fill="none"
        viewBox="0 0 35 24"
      >
        <rect
          width="34"
          height="23"
          x="0.5"
          y="0.5"
          fill="#fff"
          stroke="#D9D9D9"
          rx="3.5"
        ></rect>
        <path
          fill="#172B85"
          fillRule="evenodd"
          d="M10.625 16.258h-2.12l-1.59-6.066c-.076-.279-.236-.525-.471-.642A6.902 6.902 0 004.5 8.908v-.233h3.416a.89.89 0 01.884.758l.825 4.376 2.119-5.134h2.061l-3.18 7.583zm4.359 0H12.98l1.65-7.583h2.002l-1.65 7.583zm4.24-5.482c.058-.409.412-.642.824-.642a3.732 3.732 0 011.944.35l.353-1.633A5.068 5.068 0 0020.52 8.5c-1.944 0-3.358 1.05-3.358 2.508 0 1.11 1.002 1.692 1.709 2.042.765.35 1.06.584 1 .933 0 .525-.589.759-1.177.759a5.049 5.049 0 01-2.061-.467l-.354 1.633a5.779 5.779 0 002.18.409c2.179.058 3.533-.992 3.533-2.567 0-1.983-2.769-2.1-2.769-2.974zM29 16.258l-1.59-7.583h-1.708a.886.886 0 00-.825.583l-2.944 7h2.061l.412-1.108h2.533l.235 1.108H29zm-3.003-5.54l.588 2.857h-1.649l1.06-2.858z"
          clipRule="evenodd"
        ></path>
      </svg>
    )
  }
  else if (type === "amex") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="24"
        fill="none"
        viewBox="0 0 35 24"
      >
        <rect
          width="34"
          height="23"
          x="0.5"
          y="0.5"
          fill="#1F72CD"
          stroke="#D9D9D9"
          rx="3.5"
        ></rect>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M6.681 8.5L3.5 15.747h3.808l.472-1.156h1.08l.472 1.156h4.191v-.882l.374.882h2.168l.374-.9v.9h8.718l1.06-1.126.992 1.126 4.478.01-3.191-3.613 3.19-3.644H27.28l-1.032 1.105-.962-1.105h-9.483l-.815 1.87-.833-1.87h-3.8v.852L9.93 8.5H6.68zM19.7 9.529h5.006l1.532 1.703 1.58-1.703h1.531l-2.326 2.614 2.326 2.583h-1.6l-1.531-1.722-1.589 1.722h-4.929V9.529zm1.237 2.026v-.95h3.123l1.363 1.518L24 13.649h-3.063v-1.036h2.73v-1.058h-2.73zM7.418 9.529h1.856l2.11 4.914V9.53h2.034l1.63 3.523L16.55 9.53h2.023v5.2h-1.231l-.01-4.075-1.795 4.075h-1.102l-1.805-4.075v4.075h-2.532l-.48-1.166H7.023l-.479 1.165H5.187l2.231-5.199zm.048 2.957l.855-2.077.854 2.077H7.466z"
          clipRule="evenodd"
        ></path>
      </svg>
    )
  }
  else if (type === "master") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="24"
        fill="none"
        viewBox="0 0 35 24"
      >
        <rect
          width="34"
          height="23"
          x="0.5"
          y="0.5"
          fill="#fff"
          stroke="#D9D9D9"
          rx="3.5"
        ></rect>
        <path
          fill="#ED0006"
          fillRule="evenodd"
          d="M17.75 17.157a6.75 6.75 0 01-4.426 1.649c-3.769 0-6.824-3.09-6.824-6.903C6.5 8.091 9.555 5 13.324 5a6.75 6.75 0 014.426 1.649A6.75 6.75 0 0122.176 5C25.945 5 29 8.09 29 11.903c0 3.812-3.055 6.903-6.824 6.903a6.75 6.75 0 01-4.426-1.65z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#F9A000"
          fillRule="evenodd"
          d="M17.75 17.157a6.92 6.92 0 002.398-5.254 6.92 6.92 0 00-2.398-5.254A6.75 6.75 0 0122.176 5C25.945 5 29 8.09 29 11.903c0 3.812-3.055 6.903-6.824 6.903a6.75 6.75 0 01-4.426-1.65z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#FF5E00"
          fillRule="evenodd"
          d="M17.75 6.648a6.92 6.92 0 012.397 5.254 6.92 6.92 0 01-2.398 5.254 6.92 6.92 0 01-2.397-5.254 6.92 6.92 0 012.397-5.254z"
          clipRule="evenodd"
        ></path>
      </svg>
    )
  }
  else return null

};

IconCreditCards.defaultProps = { className: null };

IconCreditCards.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCreditCards;
