import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../FieldCheckbox.module.css';

const IconSpiritualGrowth = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill='#DFFF60' d="M8.04004 12.8525V11.2376" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M15.0831 16.384C15.0225 15.8876 14.8176 15.4199 14.4939 15.0387C14.1703 14.6575 13.7419 14.3796 13.2619 14.2393C12.7819 14.099 12.2713 14.1025 11.7933 14.2494C11.3153 14.3963 10.8908 14.6801 10.5725 15.0658C10.4895 14.453 10.1871 13.8911 9.72147 13.4843C9.25582 13.0774 8.65844 12.8532 8.04008 12.8532C7.42173 12.8532 6.82435 13.0774 6.35869 13.4843C5.89303 13.8911 5.59069 14.453 5.50771 15.0658C5.18932 14.6801 4.76486 14.3963 4.28684 14.2494C3.80883 14.1025 3.29824 14.099 2.81824 14.2393C2.33825 14.3796 1.90991 14.6575 1.58622 15.0387C1.26253 15.4199 1.0577 15.8876 0.99707 16.384" fill="#DFFF60"/>
      <path fill='#DFFF60' d="M15.0831 16.384C15.0225 15.8876 14.8176 15.4199 14.4939 15.0387C14.1703 14.6575 13.7419 14.3796 13.2619 14.2393C12.7819 14.099 12.2713 14.1025 11.7933 14.2494C11.3153 14.3963 10.8908 14.6801 10.5725 15.0658C10.4895 14.453 10.1871 13.8911 9.72147 13.4843C9.25582 13.0774 8.65844 12.8532 8.04008 12.8532C7.42173 12.8532 6.82435 13.0774 6.35869 13.4843C5.89303 13.8911 5.59069 14.453 5.50771 15.0658C5.18932 14.6801 4.76486 14.3963 4.28684 14.2494C3.80883 14.1025 3.29824 14.099 2.81824 14.2393C2.33825 14.3796 1.90991 14.6575 1.58622 15.0387C1.26253 15.4199 1.0577 15.8876 0.99707 16.384" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M13.5041 6.10419C13.5041 7.55166 12.9291 8.93985 11.9056 9.96337C10.8821 10.9869 9.49386 11.5619 8.04639 11.5619C8.0491 10.1153 8.62498 8.72864 9.64791 7.70571C10.6708 6.68278 12.0575 6.1069 13.5041 6.10419Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M9.4847 7.87634C8.55778 8.8796 8.04407 10.196 8.04643 11.5619C8.04876 10.2115 7.54736 8.90881 6.64027 7.90844C6.25425 6.83367 6.18209 5.67124 6.43223 4.55698C6.68237 3.44272 7.24448 2.42267 8.05285 1.61603C8.8543 2.42043 9.41351 3.4339 9.66668 4.54082C9.91985 5.64774 9.85679 6.80353 9.4847 7.87634Z" fill="#DFFF60"/>
      <path fill='#DFFF60' d="M8.04633 11.5619C6.59917 11.5609 5.21157 10.9855 4.18828 9.96224C3.16498 8.93895 2.58964 7.55135 2.58862 6.10419C4.03568 6.10555 5.42308 6.68099 6.4463 7.70422C7.46953 8.72744 8.04497 10.1148 8.04633 11.5619Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M8.05279 3.86332C8.91518 4.72668 9.49317 5.83294 9.70936 7.03393C9.89699 6.06424 9.8441 5.06334 9.55531 4.11883C9.26651 3.17431 8.75062 2.31498 8.05279 1.61603C7.3576 2.31065 6.84335 3.16514 6.5551 4.10466C6.26685 5.04418 6.2134 6.04006 6.39942 7.00503C6.62033 5.81476 7.19679 4.71937 8.05279 3.86332Z" fill="#F7FFD6"/>
      <path fill='#DFFF60' d="M8.32633 9.85203C8.14178 10.4032 8.04725 10.9806 8.04639 11.5619C9.49386 11.5619 10.8821 10.9869 11.9056 9.96337C12.9291 8.93985 13.5041 7.55166 13.5041 6.10419C13.4104 6.10419 13.3192 6.11446 13.2267 6.11896C12.8814 7.16263 12.2286 8.07756 11.3541 8.74374C10.4797 9.40991 9.42425 9.79624 8.32633 9.85203Z" fill="#DFFF60"/>
      <path fill='#DFFF60' d="M2.86729 6.11896C2.77483 6.11446 2.68301 6.10419 2.58862 6.10419C2.58964 7.55135 3.16498 8.93895 4.18828 9.96224C5.21157 10.9855 6.59917 11.5609 8.04633 11.5619C8.04515 10.9805 7.95086 10.403 7.76702 9.85139C6.66948 9.7949 5.61455 9.40839 4.74031 8.74242C3.86607 8.07646 3.21325 7.16208 2.86729 6.11896Z" fill="#DFFF60"/>
      <path fill='#DFFF60' d="M13.5041 6.10419C13.5041 7.55166 12.9291 8.93985 11.9056 9.96337C10.8821 10.9869 9.49386 11.5619 8.04639 11.5619C8.0491 10.1153 8.62498 8.72864 9.64791 7.70571C10.6708 6.68278 12.0575 6.1069 13.5041 6.10419Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M9.4847 7.87634C8.55778 8.8796 8.04407 10.196 8.04643 11.5619C8.04876 10.2115 7.54736 8.90881 6.64027 7.90844C6.25425 6.83367 6.18209 5.67124 6.43223 4.55698C6.68237 3.44272 7.24448 2.42267 8.05285 1.61603C8.8543 2.42043 9.41351 3.4339 9.66668 4.54082C9.91985 5.64774 9.85679 6.80353 9.4847 7.87634Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill='#DFFF60' d="M8.04633 11.5619C6.59917 11.5609 5.21157 10.9855 4.18828 9.96224C3.16498 8.93895 2.58964 7.55135 2.58862 6.10419C4.03568 6.10555 5.42308 6.68099 6.4463 7.70422C7.46953 8.72744 8.04497 10.1148 8.04633 11.5619Z" stroke="#21262A" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

const { string } = PropTypes;

IconSpiritualGrowth.defaultProps = {
  className: null,
  rootClassName: null,
};

IconSpiritualGrowth.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconSpiritualGrowth;
