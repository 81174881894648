import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


import css from '../FieldCheckbox.module.css';

const IconEmpowerment = props => {
  const { className, rootClassName, size, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes}  width='17' height='18' viewBox='0 0 17 18' fill='none'
          xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_2746_12150)'>
        <path
          d='M5.98628 1.76925C5.95763 1.72222 5.91736 1.68335 5.86934 1.65637C5.82133 1.6294 5.76718 1.61523 5.71211 1.61523C5.65704 1.61523 5.60289 1.6294 5.55488 1.65637C5.50686 1.68335 5.46659 1.72222 5.43794 1.76925C4.41767 3.44123 0.896484 9.34262 0.896484 11.6015C0.896484 12.8787 1.40384 14.1035 2.30695 15.0066C3.21005 15.9097 4.43493 16.4171 5.71211 16.4171C6.98929 16.4171 8.21417 15.9097 9.11727 15.0066C10.0204 14.1035 10.5277 12.8787 10.5277 11.6015C10.5277 9.34262 7.00655 3.44059 5.98628 1.76925Z'
          fill='#F7FFD6' />
        <path
          d='M5.71211 14.4708C4.62372 14.4731 3.56677 14.1059 2.71416 13.4294C1.86154 12.7529 1.26372 11.8071 1.01848 10.7467C0.946526 11.0263 0.905614 11.3129 0.896484 11.6013C0.896484 12.8785 1.40384 14.1034 2.30695 15.0065C3.21005 15.9096 4.43493 16.417 5.71211 16.417C6.98929 16.417 8.21417 15.9096 9.11727 15.0065C10.0204 14.1034 10.5277 12.8785 10.5277 11.6013C10.5187 11.3126 10.4777 11.0258 10.4057 10.7461C10.1606 11.8066 9.56286 12.7526 8.71023 13.4292C7.8576 14.1058 6.80059 14.473 5.71211 14.4708Z'
          fill='#DFFF60' />
        <path d='M5.71211 14.4714C4.94878 14.4735 4.21566 14.1734 3.67285 13.6367'
              stroke='#21262A' stroke-width='0.67' stroke-linecap='round'
              stroke-linejoin='round' />
        <path fill='#DFFF60'
          d='M9.6057 8.48641C8.53521 6.17702 7.32655 3.93423 5.98628 1.77022C5.95763 1.72319 5.91736 1.68432 5.86934 1.65735C5.82133 1.63038 5.76718 1.61621 5.71211 1.61621C5.65704 1.61621 5.60289 1.63038 5.55488 1.65735C5.50686 1.68432 5.46659 1.72319 5.43794 1.77022C4.41767 3.44221 0.896484 9.34295 0.896484 11.6018C0.904235 12.5058 1.16589 13.3894 1.6516 14.1519C2.13731 14.9144 2.82753 15.525 3.64353 15.9141C4.45953 16.3032 5.36846 16.4552 6.26666 16.3527C7.16486 16.2501 8.01616 15.8972 8.72348 15.3342'
          stroke='#21262A' stroke-width='0.67' stroke-linecap='round' stroke-linejoin='round' />
        <path
          d='M8.60107 12.2293C8.60107 13.0005 8.90745 13.7402 9.45281 14.2855C9.99816 14.8309 10.7378 15.1373 11.5091 15.1373C12.2803 15.1373 13.02 14.8309 13.5653 14.2855C14.1107 13.7402 14.4171 13.0005 14.4171 12.2293C14.4171 11.458 14.1107 10.7184 13.5653 10.173C13.02 9.62767 12.2803 9.32129 11.5091 9.32129C10.7378 9.32129 9.99816 9.62767 9.45281 10.173C8.90745 10.7184 8.60107 11.458 8.60107 12.2293Z'
          fill='#DFFF60' />
        <path
          d='M11.5094 9.32129C10.9365 9.32085 10.3762 9.48977 9.89898 9.80681C9.42177 10.1239 9.04893 10.5749 8.8273 11.1032C8.60568 11.6315 8.54517 12.2136 8.65337 12.7762C8.76158 13.3388 9.03367 13.8569 9.43545 14.2653L13.5448 10.156C13.0025 9.62085 12.2712 9.32095 11.5094 9.32129Z'
          fill='#DFFF60' />
        <path fill='#DFFF60'
          d='M8.60107 12.2293C8.60107 13.0005 8.90745 13.7402 9.45281 14.2855C9.99816 14.8309 10.7378 15.1373 11.5091 15.1373C12.2803 15.1373 13.02 14.8309 13.5653 14.2855C14.1107 13.7402 14.4171 13.0005 14.4171 12.2293C14.4171 11.458 14.1107 10.7184 13.5653 10.173C13.02 9.62767 12.2803 9.32129 11.5091 9.32129C10.7378 9.32129 9.99816 9.62767 9.45281 10.173C8.90745 10.7184 8.60107 11.458 8.60107 12.2293Z'
          stroke='#21262A' stroke-width='0.67' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M15.6642 16.3835L13.5659 14.2852' stroke='#21262A' stroke-width='0.67'
              stroke-linecap='round' stroke-linejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_2746_12150'>
          <rect width='16.08' height='16.08' fill='white'
                transform='translate(0.240234 0.959961)' />
        </clipPath>
      </defs>
    </svg>




  );
};

const { string } = PropTypes;

IconEmpowerment.defaultProps = {
  className: null,
  rootClassName: null,
};

IconEmpowerment.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEmpowerment;
